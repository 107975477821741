<div class="modal modal--alt is-open"><!-- [ngClass]="{ 'is-open': AS.viewResults }"-->
	<div class="modal__inner">
		<div class="form form--modal form--secondary form--scroll">
			<form name="contentForm" #contentForm="ngForm" novalidate [ngClass]="{'submitted':submitted}">
				<ng-container *ngIf="!resources">
				<header class="form__head">
					<h2 *ngIf="content?.length>0">Edit Content</h2>
					<h2 *ngIf="content?.length==0">New Content</h2>
				</header><!-- /.form__head -->

				<div class="form__body">
					<p>Please review/provide the content information below:</p>
					<div class="form__row" >
						<label class="form__label">Network Page:</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1" 
			 [ngClass]="{'is-open': select_open_network, 'select_error': submitted && !content.network_id}"
								(click)="toggleSelectOpen(content,'network');" >
								<div class="select__head">
									<p>{{ content.network_id ?  current_network  : "Select Network" }}</p>
								</div>
								<div class="select__list" (mouseleave)="toggleSelectOpen(content,'network');">
									<ul>
										<li *ngFor="let ntwk of Networks" (click)="select_option('network', ntwk);">{{ntwk.network_id==6? 'Main Page' : ntwk.network_full}}</li>
									</ul>
								</div><!-- /.select__list -->


							</div><!-- /.select -->
							<p class="form__error" *ngIf="submitted && !content.network_id">Please provide the Network Page.</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					
					<div class="form__row">
						<div class="file-box file-box-assets">
							<p>Slide/Feature Artwork:
								<br><img *ngIf="content.artwork && !Files" src="{{content.artwork}}" alt="">
								<span *ngFor="let upload of S3S.uploads" ><br>New Thumb: 
									<img src="{{upload.url}}" alt="">
								</span>
							</p>
							<p class="form__error" *ngIf="submitted && !Files && !content.artwork">Please upload the content artwork (jpg or png)</p>
							
							<label for="thumb_upload" class="btn">
								<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
									<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
								</svg>{{content.artwork? 'Replace' : 'Upload'}}
							</label>
							<input id="thumb_upload" type="file" style="display:none" (change)="uploadFiles($event, 'content')"  required/>
							<div class="upload_status" *ngIf="Files?.length > 0" ><!--&& !S3S.uploadStatus=='Success!'">-->
								<div *ngFor="let file of Files">
									<div>
										<i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i>
									</div>
									<p class="form__error" *ngIf="up_inprogress && file.progressPercentage<100">Please wait until upload is complete.</p>
								</div>
<!-- upload errors-->
								<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
									We had trouble uploading the file. Please refresh the page and try again.
									<p>If the problem persists please supply us with the following details</p>

								</div>
								<div>{{Error}}</div>
								
						  </div>
						</div><!-- /.file-box -->
					</div><!-- /.form__row -->
					

					<div class="form__row" >
						<label for="headline" class="form__label">Headline:</label>

						<div class="form__controls">
							<input type="text" class="field " name="headline" id="headline" [(ngModel)]="content.headline"  required>
						</div><!-- /.form__controls -->
					    <p class="form__error" *ngIf="submitted && !content.headline">Please provide the headline.</p>
					</div><!-- /.form__row -->
					
					
					<div class="form__row" >
						<label for="subhead" class="form__label">Subhead (optional):</label>

						<div class="form__controls">
							<input type="text" class="field " name="subhead" id="subhead" [(ngModel)]="content.subhead" >
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<label for="textarea" class="form__label">Description/Copy:</label>

						<div class="form__controls">
							<textarea name="description" id="description" class="field field--textarea" [(ngModel)]="content.copy"  required></textarea>
						</div><!-- /.form__controls -->
						 <p class="form__error" *ngIf="submitted && !content.copy">Please provide the slide copy.</p>

					</div><!-- /.form__row -->
					
					
					<div class="form__row" >
						<label class="form__label">Content Type:</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1" 
			 [ngClass]="{'is-open': select_open_type, 'select_error': submitted && !content.content_type}"
								(click)="toggleSelectOpen(content,'type');" >
								<div class="select__head">
									<p>{{ content.content_type ?  'Content Type: '+content.content_type  : "Select Content Type" }}</p>
								</div>
								<div class="select__list" (mouseleave)="toggleSelectOpen(content,'type');">
									<ul>
										<li (click)="select_option('type','campaign');">Campaign</li>
										<li (click)="select_option('type','asset');">Asset</li>
										<li (click)="select_option('type','external');">External Link</li>
										<li (click)="select_option('type','modal');">Modal (HHOnly)</li>
									</ul>
								</div><!-- /.select__list -->


							</div><!-- /.select -->
							<p class="form__error" *ngIf="submitted && !content.content_type">Please provide the Content Type.</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row" *ngIf="content.content_type=='campaign' || content.content_type=='asset'">
						<label class="form__label">{{content.content_type=='campaign' || content.content_type=='asset' ? 'Select '+content.content_type : 'Add '+content.content_type + ' link' }}:</label>

						<div class="form__controls" *ngIf="content.content_type=='campaign' || content.content_type=='asset'">
							<div class="select select--gray select--size-1" 
			 [ngClass]="{'is-open': select_open_reference, 'select_error': submitted && !content.reference}"
								(click)="toggleSelectOpen(content,'reference');" >
								<div class="select__head">
									<p>{{ content.reference ? (content.content_type=='campaign' ? current_campaign: current_asset) : 'Select '+ content.content_type }}</p>
								</div>
								<div class="select__list" (mouseleave)="toggleSelectOpen(content,'type');">
									<ul>
										<ng-container *ngIf="content.content_type=='asset'">
											<li *ngFor="let asset of Assets" (click)="select_option('asset',asset);">{{asset.asset_name}}</li>
										</ng-container>
										<ng-container *ngIf="content.content_type=='campaign'">
											
											<li *ngFor="let camp of Campaigns" (click)="select_option('campaign',camp);">{{camp.campaign_name}}</li>
										</ng-container>

									</ul>
								</div><!-- /.select__list -->
							</div><!-- /.select -->
							<p class="form__error" *ngIf="submitted && !content.reference">Please select the {{content.content_type}}.</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->	
					<div class="form__row" *ngIf="content.content_type=='external' || content.content_type=='modal'">
						<label class="form__label">{{content.content_type=='campaign' || content.content_type=='asset' ? 'Select '+content.content_type : 'Add '+content.content_type + ' link' }}:</label>
						<div class="form__controls" *ngIf="content.content_type=='external' || content.content_type=='modal'">
							<input type="text" class="field " name="reference" id="referencr" [(ngModel)]="content.reference"  required>
							<p class="form__error" *ngIf="submitted && !content.reference">Please add the {{content.content_type}} link.</p>
						</div><!-- /.form__controls -->				
					</div><!-- /.form__row -->
					
	
					<div class="form__row">
						<label class="form__label">Content Starts:</label>

						<div class="form__controls">
							<input matInput [matDatepicker]="picker1" [(ngModel)]="content.starts" required type="text" class="field field--size-1" name="asset_start" id="asset_start">
							 <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
							<mat-datepicker #picker1></mat-datepicker>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					
					
					<div class="form__row">
						<label class="form__label">Content Expires</label>

						<div class="form__controls">
							<input required type="text" class="field field--size-1" name="asset_end" id="asset_end"
								   matInput [matDatepicker]="picker2" [(ngModel)]="content.expires" >
							 <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
							<mat-datepicker #picker2></mat-datepicker>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					<div class="form__row form__row--size-small" >
						<label for="slide_order" class="form__label">Slide Order:</label>

						<div class="form__controls">
							<input type="text" (keypress)="keyPressNumbers($event)" class="field " name="slide_order" id="slide_order" [(ngModel)]="content.order" >
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__terms terms__3" *ngIf="content.content_id!==6">
						<div class="checkbox">
							<input type="checkbox" id="checkbox_feature" [checked]="content.page_feature==true||content.page_feature=='1'" (click)="checkThis('page_feature');" #pagef>

							<label for="checkbox_feature">Featured Article</label>
						</div><!-- /.checkbox -->
					</div><!-- /.form__terms -->
					<div class="form__terms terms__3" *ngIf="content.content_id!==6">
						<div class="checkbox">
							<input type="checkbox" id="checkbox_video" [checked]="content.video==true||content.video=='1'" (click)="checkThis('video');" #prevvid  >

							<label for="checkbox_video">Video Preview</label>
						</div><!-- /.checkbox -->
					</div><!-- /.form__terms -->
					<div class="form__terms terms__3" >
						<div class="checkbox">
							<input type="checkbox" id="checkbox_nocta" 
								   (click)="checkThis('nocta');" #nocta [checked]="content.nocta==true||content.nocta=='1'"
								   >

							<label for="checkbox_nocta" >No CTA</label>
						</div><!-- /.checkbox -->
					</div><!-- /.form__terms -->
<!-- checkbox avail-->
					<div class="form__terms terms__3">
						<div class="checkbox">
							<input type="checkbox" id="checkbox_publish" [checked]="content.active==true||content.active=='1'" (click)="checkThis('active');" #active>

							<label for="checkbox_publish">Active</label>
						</div><!-- /.checkbox -->
					</div><!-- /.form__terms -->
					<div class="form__divider"></div><!-- /.form__divier -->
				</div><!-- /.form__body -->

				<div><p class="form__error form__error__alt" *ngIf="submitted && (contentForm.invalid || file_upload_error|| !content.network_id)">Please complete the fields above</p></div>

				<div class="form__actions">
					<a (click)="submitContent(contentForm)" class="btn form__btn"  name="submit_content">Submit</a>
				</div><!-- /.form__actions -->
				</ng-container>
				
				<ng-container *ngIf="resources">
				<header class="form__head">
					<h2 *ngIf="content?.length>0">Edit Resource</h2>
					<h2 *ngIf="content?.length==0">New Resource</h2>
				</header><!-- /.form__head -->

				<div class="form__body">
					<p>Please review/provide the Network resource information below:</p>
					<div class="form__row" *ngIf="content.asset_id" >
						<label class="form__label">Network Page:</label>
						<div class="form__controls">
							<input type="text" class="field " name="network" id="network" [(ngModel)]="content.network_short" disabled>
						</div><!-- /.form__controls -->

					</div><!-- /.form__row -->

					
					<div class="form__row" *ngIf="!content.asset_id">
						<label class="form__label">Select Network(s):</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1 select--checks"
								 [ngClass]="{'is-open': select_open_networks, 'select_error': submitted && !content.networks}"
								(click)="toggleSelectOpen(asset,'networks');" id="networks">
								<div class="select__head">
									<p>Network(s):</p>
								</div><!-- /.select__head --><!-- [ngClass]="{'is-open': asset?.select_open_tactics}" -->

								<div class="select__list" (mouseleave)="toggleSelectOpen(content,'networks');">
									<ul>
										<li *ngFor="let ntwk of Networks">
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addElement(content,ntwk,'networks');" [checked]="includedElement(content,ntwk,'networks')" id="net{{ntwk.network_id}}">

												<label for="net{{ntwk.network_id}}">{{ntwk.network_full}}</label>
											</div><!-- /.checkbox -->
										</li>
									</ul>
								</div><!-- /.select__list -->
							</div><!-- /.select -->
							<div class="cols cols--alt form__cols--size-1">
								<div class="col col--1of3" *ngFor="let net of content.networks">
									<div class="checkbox checkbox--gray" >
										<input type="checkbox"  (click)="addElement(content,net,'networks');" [checked]="includedElement(content,net,'networks')"  id="nt{{net.network_id}}">
										<label for="nt{{net.network_id}}">{{net.network_full}}</label>
									</div><!-- /.checkbox -->
								</div><!-- /.col col-/-1of3 -->

							</div><!-- /.cols -->
							<p class="form__error" *ngIf="submitted && content.networks?.length==0">Please select Network(s).</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->					

					<div class="form__row" >
						<label for="program" class="form__label">Resource Name:</label>

						<div class="form__controls">
							<input type="text" class="field " name="program" id="program" [(ngModel)]="content.program"  required>
						</div><!-- /.form__controls -->
					    <p class="form__error" *ngIf="submitted && !content.program">Please provide the resource name.</p>
					</div><!-- /.form__row -->
					

					<div class="form__row" >
						<label class="form__label">Resource Category:</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1" 
			 [ngClass]="{'is-open': select_open_type, 'select_error': submitted && !content.network_category}"
								(click)="toggleSelectOpen(content,'type');" >
								<div class="select__head">
									<p>{{ content.network_category? (content.network_category | titlecase)  : 'Select Category' }}</p>
								</div>
								<div class="select__list" (mouseleave)="toggleSelectOpen(content,'type');">
									<ul>
										<li (click)="select_option('type','brand');">Brand</li>
										<li (click)="select_option('type','fcc');">FCC</li>
										<li (click)="select_option('type','technical');">Technical</li>
										<li (click)="select_option('type','contact');">Contact</li>
									</ul>
								</div><!-- /.select__list -->


							</div><!-- /.select -->
							<p class="form__error" *ngIf="submitted && !content.network_category">Please provide the Category.</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					
					
					
				
					<div class="form__row">
						<div class="file-box file-box-assets">
							<p>Upload Resource:
								<br><a *ngIf="content.path && !Files" href="{{content.path}}" target="_blank">{{content.program}}</a>
								<span *ngFor="let upload of S3S.uploads" ><br>New Path: 
									<a *ngIf="content.path && !Files" href="{{upload.url}}" target="_blank">{{content.program}}</a>
								</span>
							</p>
							<p class="form__error" *ngIf="submitted && !Files && !content.path">Please upload the resource file</p>
							
							<label for="thumb_upload" class="btn">
								<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
									<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
								</svg>{{content.path? 'Replace' : 'Upload'}}
							</label>
							<input id="thumb_upload" type="file" style="display:none" (change)="uploadFiles($event, 'network')"  required/>
							<div class="upload_status" *ngIf="Files?.length > 0" ><!--&& !S3S.uploadStatus=='Success!'">-->
								<div *ngFor="let file of Files">
									<div>
										<i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i>
									</div>
									<p class="form__error" *ngIf="up_inprogress && file.progressPercentage<100">Please wait until upload is complete.</p>
								</div>
<!-- upload errors-->
								<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
									We had trouble uploading the file. Please refresh the page and try again.
									<p>If the problem persists please supply us with the following details</p>

								</div>
								<div>{{Error}}</div>
								
						  </div>
						</div><!-- /.file-box -->
					</div><!-- /.form__row -->

							
					<div class="form__row">
						<label class="form__label">Resource Starts:</label>

						<div class="form__controls">
							<input matInput [matDatepicker]="picker1" [(ngModel)]="content.asset_start" required type="text" class="field field--size-1" name="asset_start" id="asset_start">
							 <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
							<mat-datepicker #picker1></mat-datepicker>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					
					
					<div class="form__row">
						<label class="form__label">Resource Expires</label>

						<div class="form__controls">
							<input type="text" class="field field--size-1" name="asset_end2" id="asset_end2"
								   matInput [matDatepicker]="picker2" [(ngModel)]="content.asset_end" >
							 <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
							<mat-datepicker #picker2></mat-datepicker>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					
					<div class="form__divider"></div><!-- /.form__divier -->
				</div><!-- /.form__body -->

				<div><p class="form__error form__error__alt" *ngIf="submitted && (contentForm.invalid || file_upload_error )">Please complete the fields above</p></div>

				<div class="form__actions">
					<a (click)="submitResource(contentForm)" class="btn form__btn"  name="submit_content">Submit</a>
				</div><!-- /.form__actions -->
				</ng-container>
				
			</form>
		</div><!-- /.form -->

		<a (click)="closeResults();" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="">
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->
