import { Component, AfterViewInit, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ActivatedRoute,Router } from '@angular/router';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';
import { EmailManagementService } from '../../services/email-management.service';
import { S3UploadService } from '../../services/s3-upload-service.service';


declare const MediumEditor: any;

@Component({
  selector: 'app-email-newsletter-detail',
  templateUrl: './email-newsletter-detail.component.html',
  styleUrls: ['./email-overview.component.css']
})

export class EmailNewsletterDetailComponent implements OnInit, AfterViewInit {
	editor: any;
	editor2: any;
  	@ViewChild('editable', { static: true }) editable: ElementRef;
	@ViewChild('editable2', { static: true }) editable2: ElementRef;
	breadcrumbs: any |[];
	priorities: any | [];
	temp_id='0';
	anio: number = new Date().getFullYear();
	public AdminEmails: any | [];
	request_account=false;
	current_promo_yr=2021;
	email_status="draft";
	submitted=false;
	saved_template=false;
	select_open=false;
	alert_message='';
	showAlert=false;
	showPreview=false;
	private ngUnsubscribe = new Subject<void>();
    up_inprogress=false;
  
	
	
	Files: any | [];
  	Files_email: any | [];
	  Files_html: any | [];
	  Files_preview: any | [];
	  file_upload_error=false;
  Error: any |[];
	email_template={
	    template_id: '0',
	    url: null,	    
	    template_name: "New Template",
	    priority_id: null,
	    priority_name: null,
		email_subject:null,
	    email_headline:null,
	    greeting: 'Hello from Hallmark,',
	    email_image:null,
		email_body_1:null,
	    artwork_body_2: null,
		email_body_2: null,
		enroll_now: false,
		download_spots:false,
		submit_results:false,
	    shop_now:false,
		custom_cta:false,
		custom_cta_copy:null,
		recipient: null,
		adhoc:false,
	   test_email:false
 };
	

  constructor(
    public PS: ProfileService, 
	public AS: AdminService,
	public EMS: EmailManagementService,
	public S3S: S3UploadService,
	public route: ActivatedRoute,
	public router: Router,
	//public US : S3UploadService
  ) { }

  ngOnInit(): void {
	  this.S3S.clearAdminUploads();
	  this.email_template.template_id='0';
	  this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
		  this.temp_id=params.get('id');
		  console.log('id is',this.temp_id);
	  });
	  
	  if(this.temp_id !== '0' && this.temp_id !== null){
		  this.EMS.getEmailTemplate(this.temp_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
			 
			 this.email_template=data[0];
			 this.EMS.current_template_id=this.email_template.template_id;
			  console.log('newid', this.EMS.current_template_id);
		  });
	  }
	 
  }
  ngAfterViewInit(): void {
   
	
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
	 uploadFiles(event, el) {
	    
		var files = event.target.files;
		console.log('started', files);
		console.log('started2', files.length);
        this['Files_'+el] = files;
		console.log('started3', this['Files_'+el].length);
		var that=this;

      if (files && files.length > 0){ 
		   this['Files_'+el][0].pathway=el+'/'; //setting S3 Path
			  //fileEvent($event,'uploads')
              this.S3S.uploadSingleFile(this['Files_'+el][0], el);
      }
  };
	
	set_status(status){
		 this.email_status=status;
	 }
	
	setBody(field,html){
		if(field=="body1"){
			this.email_template.email_body_1=html;
		}else{
			this.email_template.email_body_2=html;
		}
		console.log('change',this.email_template.email_body_1);
		console.log(html);
	}
	
	reset_email_updates(template){
		this.EMS.current_template_id=null;
		this.router.navigate(['/email-overview']);
	}

	admin_send_test_email(template, form){
		 var tempurl='';
		console.log('tempurl', tempurl);
		var form_data = [];
		form_data.push(this.email_template);
		var json_data= JSON.stringify(form_data);
		
      this.EMS.adminSendTestEmail(this.email_template.template_id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(response) => {
				console.log('sentemail', response);
				var errcheck = JSON.stringify(response);
				if(errcheck.includes('Unknown error')){
					  this.alert_message="We've encountered a submission error.  Please try again or reach out to coustomer support.";
					  this.showAlert=true;
				}else{
					this.alert_message=errcheck;
					this.showAlert=true;
				}
	  });
	}
	admin_get_audience(template, form){
		this.EMS.current_template_id=this.email_template.template_id;
		console.log('clicked', this.EMS.current_template_id);
		this.router.navigate(['/admin/audience']);
	}
	admin_save_template(template, form){
		
		console.log('template', template);
		console.log('form', form);
		
        var tempurl='';
		this.email_template.email_body_1=(this.Files_html? this.S3S.uploads_html[0].url: this.email_template.email_body_1);
		 
		var form_data = [];
		form_data.push(this.email_template);
		var json_data= JSON.stringify(form_data);
		this.EMS.adminSaveEmailTemplate(json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(response) => {
				console.log('response', response);
				var errcheck = JSON.stringify(response);
				var id=errcheck.replace(/['"]+/g, '')
				//this.alert_message=response;
				if(errcheck.includes('Unknown error')){
					  this.alert_message="We've encountered a submission error.  Please try again or reach out to coustomer support.";
					  this.showAlert=true;
				}else{
					this.email_template.template_id=id;
				    this.alert_message="Saved Template #" + id;
					if(!form.untouched){this.showAlert=true;}
				    this.submitted=true;
				}
			}
		)		 
	}
 
	toggleSelectOpen(){
		this.select_open=!this.select_open;
	}


}
