import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';
import { S3UploadService } from '../../services/s3-upload-service.service';


@Component({
  selector: 'app-custom-reqs',
  templateUrl: './custom-reqs.component.html',
  styleUrls: ['./custom-reqs.component.css']
})
export class CustomReqsComponent implements OnInit {

 	private ngUnsubscribe = new Subject<void>();
	current_status="all";
	Users: any |[];
	Profile:any |[];
	role:any |[];
	select_open=false;
	propertyName="created_date";
	reverse="desc";
	user_edit: any |[];
		Customizations: any |[];

  constructor(public CS: ContentManagerService, public S3S: S3UploadService, public AS: AssetsService, public PS: ProfileService, public ADM: AdminService, public router: Router){ }

  ngOnInit(): void {	  
	  console.log('started');
	  this.PS.getRole().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.role = data; 
		  if(this.role!=='admin'){
			  //this.router.navigate(['/home']);
		  }

	  });
	  this.ADM.updateAdmin();
	    //this.Profile=this.PS.profileObj.getValue();
	   this.ADM.getUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Users = data; console.log('users', this.Users);
	  });
	  this.ADM.current_user_detail=null;
	  this.ADM.getAdminCustoms().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
	   this.Customizations=this.setUpExpand(data);
		  console.log('customs', this.Customizations); 
	  });


  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	
	
uploadFiles(event, el) {
	    
		var files = event.target.files;
		console.log('started', files);
		console.log('started2', files.length);
        this['Files_'+el] = files;
		console.log('started3', this['Files_'+el].length);
		var that=this;

      if (files && files.length > 0){ 
		   this['Files_'+el][0].pathway=el+'/'; //setting S3 Path
			  //fileEvent($event,'uploads')
              this.S3S.uploadSingleFile(this['Files_'+el][0], el);
      }
  };
	
	setUpExpand(data){
		if(data!=="No data"){
			console.log('dt', data);
			data.forEach(function(obj){
				obj.expand=false;
			});
		}else{
			data=[];
		}
		return data
	}
	toggleExpand(item){
		if(!item.expand){
			this.Customizations.forEach(function(obj){
				obj.expand=false;
			});
			item.expand=true;
		}else{
			item.expand=false;
		}
	}
	toggleSelectOpen(){
		this.select_open=!this.select_open;
	}
	sortBy(field){
	  console.log('clicked'+this.propertyName+field+ this.reverse);
	  if(this.propertyName!==field){
		  this.propertyName=field;
		  this.reverse="asc";
		  console.log('no match'+this.reverse);
	  }else if(this.reverse=="asc"){
		  this.reverse="desc";
		   console.log('matchasc'+this.reverse);
	  }else{
		  this.reverse="asc";
		  console.log('matchdesc'+this.reverse);
	  }
  }
processedUsers(){
		var arr=[];
		var that=this;
		this.Users.forEach(function(obj){
			obj.downloads=0;obj.pending=0;obj.inqueue=0;obj.removed=0;
			if(obj.assets){
				obj.assets.forEach(function(obj2){
					if(obj2.status=="PENDING"){obj.pending=obj2.assets;}
					if(obj2.status=="DOWNLOADED"){obj.downloads=obj2.assets;}
					if(obj2.status=="IN-QUEUE"){obj.inqueue=obj2.assets;}
					if(obj2.status=="REMOVED"){obj.removed=obj2.assets;}
				});
			}
		//	if(that.current_status="all"){
		//		arr.push(obj);	
		//	}else{
		//		if(obj.status==that.current_status){
					arr.push(obj);
		//		}
		//	}
		});
		return arr;
	}
	selectOption(val){
		this.current_status=val;
	}
}
