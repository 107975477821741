import { Component, OnInit, Input, OnDestroy,ViewChild} from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';
import { S3UploadService } from '../../services/s3-upload-service.service';


@Component({
  selector: 'app-submit-custom',
  templateUrl: './submit-custom.component.html',
  styleUrls: ['./submit-custom.component.css']
})
export class SubmitCustomComponent implements OnInit {
 private ngUnsubscribe = new Subject<void>();
  
  video=true;
  @Input() asset: any |[];
  Profile: any |[];
  submitted=false;
  up_inprogress=false;
asset_description='';
	asset_fileurl='';
  Files: any | [];
  Error: any |[];
 
	constructor(public S3S:S3UploadService, public PS:ProfileService,public AS: AssetsService, public CS: ContentManagerService, public router:Router) { }

  
	ngOnInit(): void {
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Profile=data;
		  console.log('Profile is', this.Profile);
	  });	
	 
	  console.log('asset', this.asset);
	 
  
	}
 
	ngOnDestroy(){
	
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
 
	uploadFiles(event) {
		var files = event.target.files;
		this.Files = files;
		console.log('started3', this.Files.length);
		var that=this;

      if (files && files.length > 0){ 
		   this.Files[0].pathway='uploads/'; //setting S3 Path
              this.S3S.uploadSingleFile(this.Files[0], 'uploads');     
      }
  };
  submitCAR(form){
	   console.log(form);
	  let submission: any = {}; // Initialize as an empty object
	  if (this.asset.CAR) {
		submission = { ...this.asset.CAR }; // Copy properties from asset.CAR
	  }else{
	  	submission.files = []; // Initialize submission.files as an empty array
	  }
	  this.submitted = true;
	  this.up_inprogress = false;
  
	  var that=this;

	  
	  
	  
	  
	  
	  
	  if(this.Files.length>0 && this.Files[0].progressPercentage<100){
		  this.up_inprogress=true;
	  }else if(form.valid && (this.S3S.uploads.length>0 || this.asset_fileurl!==null)){
		  console.log(this.S3S.uploads);
		  console.log(this.Files);
	  	  if(this.S3S.uploads.length>0){
			  this.S3S.uploads.forEach(function(obj){
			 	submission.files.push(obj.url); 
		  	});
		  }else{
			  submission.files.push(this.asset_fileurl);
		  }
		 if(this.asset?.history_id){
			 submission.history_id=this.asset.history_id;
		     submission.asset_id=this.asset.asset_id;
			 submission.description=this.asset_description;
			console.log('CAR', submission);
		 }else{
		    console.log('NOCAR', submission);
			//this.asset.CAR.history_id= null;
		  //	this.asset.CAR.asset_id=null;
	     }
		  console.log('sub',submission);
		  submission.description=this.asset_description;
		  
		  console.log(submission);
		  var json_data=[submission];
		  var id=this.Profile.profile_id;
		  this.AS.submitCustomization(id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			   if(data == "fail"){
				   this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>customer support</a>";
				   this.CS.showAlert = true;
			   }else{
				   this.CS.updateContent();
				   this.AS.updateAssets(id);
				   this.S3S.uploads=[];
				   this.closeResults();
			   }
		   });
	  } 	  
  }
	closeResults(){
		this.S3S.uploads=[];
		this.AS.viewCAR=false;
	}


}
