import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
transform(value: any[], order = '', column: string = ''): any[] {
    if (!value || order === '' || !order) { return value; } // no array
    if (value.length <= 1) { return value; } // array with only one item
    if (!column || column === '') { 
      if(order==='asc'){return value.sort()}
      else{return value.sort().reverse();}
    } // sort 1d array
  //  return orderBy(value, [column], [order]);
	return value.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      // Check if the values are numeric
      const isANumeric = !isNaN(aValue) && isFinite(aValue);
      const isBNumeric = !isNaN(bValue) && isFinite(bValue);

      // Sort numerically if both values are numeric
     // if (isANumeric && isBNumeric) {
     //   return +aValue - +bValue;
     // }
		// Sort numerically if both values are numeric
      if (isANumeric && isBNumeric) {
        return order === 'asc' ? aValue - bValue : bValue - aValue;
      }

      // Sort as text if any value is non-numeric
		
     // Sort as text if any value is non-numeric
      const result = String(aValue).localeCompare(String(bValue));
      return order === 'asc' ? result : -result;
//      return String(aValue).localeCompare(String(bValue));
    });
  }
}

