import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'categoryFilter',
  pure: false
})
export class CategoryFilterPipe implements PipeTransform {
	transform(items: any[],  values : any[]): any[] {  
		console.log('vals is',values);
      if (!items){console.log('no items'); return [];}
      else if (!values || values.length == 0){ console.log('no values');return items;}
		else{
			//return items.filter(p => values.indexOf(p[field] !==-1));
			
			console.log( items.filter((item: any) => values.includes(item.category)));
			return items.filter((item: any) => values.includes(item.category));
			
		}
	  
    }

}
