import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

//Services
import { ErrorhandlerService } from './errorhandler.service';
import { ProfileService } from './profile.service';
import { AssetsService } from './assets.service';

//Environment
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AdminService {
	private apiUrl = environment.apiUrl; 
	public assetsAdminObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public usersAdminObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public contentAdminObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public networksAdminObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public statsAdminObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public userResultsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public userCustomsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public adminResultsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public adminCustomsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public audSellinObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	accountFilter=null;
	showFilter=false;
	viewAsset=false;
	viewContent=false;
	viewUser=false;
	viewEmailPreview=false;
	network_resource=false;
	current_user_detail=null;
	detail_id=null;
	selectedAssets=[];
	selectedAsset=null;
	selectedAssetOption=false;
	assetUsers=[49,103,1237];
	audienceFilter=null;
	
	
		
	

  constructor(private http: HttpClient,
	 private location: Location,
	 private PS : ProfileService,
	 private AS : AssetsService,
	 private errorhandlerService: ErrorhandlerService) { }
	
	
	updateAudienceFilter(){
		if(this.selectedAsset){
			this.pullAdminAssetUsers(this.selectedAsset).subscribe((response) => {
				this.assetUsers=response;
			});
			//this.audienceFilter=
		}else{
			this.audienceFilter=null;
		}
			
	}
	
	updateAdmin(c?:number){
		var id= (typeof c !== 'undefined'? c:0);
		this.AS.updateAssets(c);
		this.pullUsers().subscribe((response) => {
			this.usersAdminObj.next(response);
		});	
		this.pullAdminAssets().subscribe((response) => {
			this.assetsAdminObj.next(response);
		});
		this.pullAdminContent().subscribe((response) => {
			this.contentAdminObj.next(response);
		});
		this.pullAdminNetworks().subscribe((response) => {
			this.networksAdminObj.next(response);
		});
		this.pullAdminResults().subscribe((response) => {
			this.adminResultsObj.next(response);
		});
		this.pullAdminCustoms().subscribe((response) => {
			this.adminCustomsObj.next(response);
		});
		this.pullSellin().subscribe((response) => {
			this.audSellinObj.next(response);
		});
		
	}
	updateAudience(){
		

	}
	updateUserDetail(){
		this.pullUserResults(this.detail_id).subscribe((response) => {
			this.userResultsObj.next(response);
		});
		this.pullUserCustoms(this.detail_id).subscribe((response) => {
			this.userCustomsObj.next(response);
		});
	}
	
	runStats(json_data){
		this.pullStats(json_data).subscribe((response) => {
			this.statsAdminObj.next(response);
		});	
	}
	getAdminStats(){return this.statsAdminObj;}
	getAdminAssets(){return this.assetsAdminObj;}
	getAdminContent(){return this.contentAdminObj;}
	getAdminNetworks(){return this.networksAdminObj;}
	getUserResults(){return this.userResultsObj;}
	getUserCustoms(){return this.userCustomsObj;}
	getAdminCustoms(){return this.adminCustomsObj;}
	getAdminResults(){return this.adminResultsObj;}
	getUsers(){return this.usersAdminObj;}
	getSellin(){return this.audSellinObj;}
	pullSellin():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'usersforsellin').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullUsers():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'usersforadmin').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullUserResults(id):Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'userresults/'+id).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	pullUserCustoms(id):Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'usercustoms/'+id).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	pullAdminResults():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'userresults').pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	pullAdminCustoms():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'usercustoms').pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	approveUser(id):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'acceptuser/'+id,[]).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	denyUser(id):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'denyuser/'+id,[]).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	putUserCustoms(json_data, id):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'updatecustom/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	pullAdminAssets():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'adminassets').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullAdminAssetUsers(id):Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'assetusers/'+id).pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullAdminContent():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'admincontent').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullAdminNetworks():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'networksforadmin').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullStats(json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'stats',json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	
	
}
