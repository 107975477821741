import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ageFilter',
  pure: false
})
export class AgeFilterPipe implements PipeTransform {
  transform(items: any[], range: string, dateColumn: string): any[] {
    if (!items || !range || !dateColumn) {
      return items;
    }

    if (range === 'all') {
      return items;
    }

    const currentDate = new Date();
    const filteredItems = items.filter(item => {
      const itemDate = new Date(item[dateColumn]);
      const ageInDays = Math.floor((currentDate.getTime() - itemDate.getTime()) / (1000 * 60 * 60 * 24));
      
      if (range === '<30days') {
        return ageInDays <= 30;
      } else if (range === '31-60days') {
        return ageInDays >= 31 && ageInDays <= 60;
      } else if (range === '61-120days') {
        return ageInDays >= 61 && ageInDays <= 120;
      } else if (range === '>120days') {
        return ageInDays > 120;
      }

      return false;
    });

    return filteredItems;
  }
}
