import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';


@Component({
  selector: 'app-queue-summary',
  templateUrl: './queue-summary.component.html',
  styleUrls: ['./queue-summary.component.css']
})
export class QueueSummaryComponent implements OnInit {

private ngUnsubscribe = new Subject<void>();
	Queue:any|[];
	Assets:any|[];
	Profile:any|[];
	

  constructor(public PS:ProfileService, public AS: AssetsService, public CS: ContentManagerService){ }

  ngOnInit(): void {
	  this.AS.getAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Assets=data;});	
	  this.AS.getQueue().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Queue=data;
	  });	
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	closeModal(){
		console.log('is', this.CS.summary);
		this.CS.summary=false;
		console.log('is now', this.CS.summary);
	}
	removeAsset(Q){
		var id=Q.history_id;
		var json_data=[];
		json_data.push({profile_id:this.PS.profile_id});
		this.AS.removefromQueue(id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
		   if(data !== "success"){
			   this.CS.alert_message = "This submission encountered an error.  Please try again or contact customer support";
			   this.CS.showAlert = true;
		   }else{
			   this.AS.updateAssets(0);
		   }
	   });
	}

}
