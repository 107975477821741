<app-alert></app-alert>
<section class="section-headline section-headline--alt">
	<div class="shell shell--size-3">
		<h2>Download Asset Queue</h2>

		<div class="section__inner">
			<h3>You have <strong>{{Queue?.length}}</strong> assets in your queue.</h3>

			<a routerLink="/library" class="link link--alt">Return to Asset Library</a>
		</div><!-- /.section__inner -->
	</div><!-- /.shell -->
</section><!-- /.section-headline -->

<app-asset-preview></app-asset-preview>

<section class="section-queue">
	<div class="shell shell--size-3">
		<header class="section__head">
			<a (click)="CS.back();" class="link link--alt-reverse section__link">Back</a>

			<h4 *ngIf="Queue?.length>0">Please indicate intended usage for each asset:</h4>
		</header><!-- /.section__head -->

		<div class="section__body">
			<div class="no-assets" *ngIf="Queue?.length==0">
				<img src="assets/images/svg/assets.svg" alt="">
				<p>No assets have been added to your queue yet. You can browse assets and add them to your queue in the Asset Library.</p>
				<a routerLink="/library" class="link link--alt-reverse section__link">Asset Library</a>
			</div>
			<div class="assets">
				<div class="asset asset--secondary" *ngFor="let queue of Queue">
					<a (click)="removeAsset(queue);" class="link link--delete asset__delete">Delete</a>

					<div class="asset__aside">
						<figure class="asset__image">
							<img src="{{queue.asset_info[0].thumb ? queue.asset_info[0].thumb : CS.default_img}}" alt="{{queue.asset_info[0].asset_name}}" width="195" height="110">
						</figure><!-- /.asset__image -->

						<ng-container *ngIf="queue.asset_info[0].thumb">
							<a (click)="previewModal(queue.asset_info[0])" class="btn btn--alt asset__preview-btn">Preview</a>
						</ng-container>
					</div><!-- /.asset__aside -->

					<div class="form asset__form">
						<form action="?" method="post">
							<header class="form__head">
								<h3>{{queue.asset_info[0].asset_name}}</h3>
							</header><!-- /.form__head -->

							<div class="form__body">

								<div class="form__row">
									<label class="form__label">Select Intended Usage/Tactic(s):</label>

									<div class="form__controls">
										<div class="select select--gray select--size-1 select--checks" 
											 [ngClass]="{'is-open': queue?.select_open_tactics, 'select_error': queue_submitted && queue.tactics.length==0}"
											(click)="toggleSelectOpen(queue,'tactics');" id="tactic{{queue.history_id}}">
											<div class="select__head">
												<p>Tactic Type</p>
											</div><!-- /.select__head --><!-- [ngClass]="{'is-open': queue?.select_open_tactics}" -->

											<div class="select__list" (mouseleave)="toggleSelectOpen(queue,'tactics');">
												<ul>
													<li *ngIf="queue.asset_info[0].video_preview">
														<div class="checkbox checkbox--gray">
															<input type="checkbox" (click)="addTactic(queue, 'On-Air');" [checked]="includedTactic(queue, 'On-Air')" id="on-air{{queue.history_id}}">

															<label for="on-air{{queue.history_id}}">On-Air</label>
														</div><!-- /.checkbox -->
													</li>
													<li>
														<div class="checkbox checkbox--gray">
															<input type="checkbox" (click)="addTactic(queue, 'Social Media');" [checked]="includedTactic(queue, 'Social Media')" id="Social-Media{{queue.history_id}}">

															<label for="Social-Media{{queue.history_id}}">Social Media</label>
														</div><!-- /.checkbox -->
													</li>												
													<li>
														<div class="checkbox checkbox--gray">
															<input type="checkbox" (click)="addTactic(queue, 'Newsletter/Eblast');" [checked]="includedTactic(queue, 'Newsletter/Eblast')" id="Newsletter-Eblast{{queue.history_id}}">

															<label for="Newsletter-Eblast{{queue.history_id}}">Newsletter/Eblast</label>
														</div><!-- /.checkbox -->
													</li>
													
													<li>
														<div class="checkbox checkbox--gray">
															<input type="checkbox" (click)="addTactic(queue, 'Website');" [checked]="includedTactic(queue, 'Website')" id="Website{{queue.history_id}}">

															<label for="Website{{queue.history_id}}">Website</label>
														</div><!-- /.checkbox -->
													</li>
													
													<li>
														<div class="checkbox checkbox--gray">
															<input type="checkbox" (click)="addTactic(queue, 'Print');" [checked]="includedTactic(queue, 'Print')" id="Print{{queue.history_id}}">

															<label for="Print{{queue.history_id}}">Print</label>
														</div><!-- /.checkbox -->
													</li>
													
													<li>
														<div class="checkbox checkbox--gray">
															<input type="checkbox" (click)="addTactic(queue, 'Events');" [checked]="includedTactic(queue, 'Events')" id="Events{{queue.history_id}}">

															<label for="Events{{queue.history_id}}">Events</label>
														</div><!-- /.checkbox -->
													</li>
													<li>
														<div class="checkbox checkbox--gray">
															<input type="checkbox" (click)="addTactic(queue, 'Reference/Internal');" [checked]="includedTactic(queue, 'Reference/Internal')" id="Reference{{queue.history_id}}">

															<label for="Reference{{queue.history_id}}">Reference/Internal</label>
														</div><!-- /.checkbox -->
													</li>
													<li>
														<div class="checkbox checkbox--gray">
															<input type="checkbox" (click)="addTactic(queue, 'Other');" [checked]="includedTactic(queue, 'Other')" id="OtherTactic{{queue.history_id}}">

															<label for="OtherTactic{{queue.history_id}}">Other</label>
														</div><!-- /.checkbox -->
													</li>
												</ul>
											</div><!-- /.select__list -->
											<div class="form__row" *ngIf="includedTactic(queue, 'Other')">
												

												<div class="form__controls">
													<label for="other{{queue.history_id}}">Other:</label>
													<input placeholder="Describe 'Other'" type="text" class="field field--size-3" name="other{{queue.history_id}}" id="other{{queue.history_id}}" [(ngModel)]="queue.other" 
													 #other{{queue.history_id}}="ngModel"/>
												</div><!-- /.form__controls -->
											</div><!-- /.form__row -->
	
										</div><!-- /.select -->
										<div class="cols cols--alt form__cols--size-1">
											<div class="col col--1of3" *ngFor="let tactic of queue.tactics">
												<div class="checkbox checkbox--gray" >
													<input type="checkbox" (click)="addTactic(queue, tactic);" [checked]="includedTactic(queue, tactic)" id="{{tactic}}{{queue.history_id}}">
													<label for="{{tactic}}{{queue.history_id}}">{{tactic}}</label>
												</div><!-- /.checkbox -->
											</div><!-- /.col col-/-1of3 -->

										</div><!-- /.cols -->
										<p class="form__error" *ngIf="queue_submitted && queue.tactics.length==0">Please select Tactic(s).</p>
									</div><!-- /.form__controls -->
								</div><!-- /.form__row -->

								<div class="form__row" *ngIf="Profile?.dmas.length>0">
									<label class="form__label">Select Market(s):</label>

									<div class="form__controls">
										<div class="select select--gray select--size-1 select--checks"
						 [ngClass]="{'is-open': queue?.select_open_accounts, 'select_error': queue_submitted && queue.accounts.length==0}"
											(click)="toggleSelectOpen(queue,'accounts');" >
											<div class="select__head">
												<p>Intended Markets</p>
											</div><!-- /.select__head --><!-- [ngClass]="{'is-open': queue?.select_open_tactics}" -->

											<div class="select__list" (mouseleave)="toggleSelectOpen(queue,'accounts');">
												<ul>
													<li *ngFor="let account of Profile.dmas">
														<div class="checkbox checkbox--gray">
															<input type="checkbox" (click)="addAccount(queue, account.dma);" [checked]="includedAccount(queue,  account.dma)" id="{{account.dma}}{{queue.history_id}}">

															<label for="{{account.dma}}{{queue.history_id}}">{{account.dma}}</label>
														</div><!-- /.checkbox -->
													</li>
												</ul>
											</div><!-- /.select__list -->
											
	
										</div><!-- /.select -->
										<div class="cols cols--alt form__cols--size-1">
											<div class="col col--1of3" *ngFor="let acct of queue.accounts">
												<div class="checkbox checkbox--gray" >
													<input type="checkbox" (click)="addAccount(queue, acct);" [checked]="includedAccount(queue, acct)" id="{{acct}}{{queue.history_id}}">
													<label for="{{acct}}{{queue.history_id}}">{{acct}}</label>
												</div><!-- /.checkbox -->
											</div><!-- /.col col-/-1of3 -->

										</div><!-- /.cols -->
										<p class="form__error" *ngIf="queue_submitted && queue.accounts.length==0">Please select Market(s).</p>
									</div><!-- /.form__controls -->
								</div><!-- /.form__row -->
								
								<div class="form__row">
									<label for="reach{{queue.history_id}}" class="form__label">Estimated Reach/Impressions:</label>

									<div class="form__controls">
										<input type="text" class="field field--size-1" name="reach{{queue.history_id}}" id="reach{{queue.history_id}}" [(ngModel)]="queue.reach" 
										required #reach{{queue.history_id}}="ngModel" required  [ngClass]="{'is-error':queue_submitted && queue.reach==null}"/>
										<p class="form__error" *ngIf="queue_submitted && queue.reach==null">Please enter an estimated reach.</p>
									</div><!-- /.form__controls -->
								</div><!-- /.form__row -->

							</div><!-- /.form__body -->
						</form>
					</div><!-- /.form asset__form -->
				</div><!-- /.asset -->
			</div><!-- /.assets -->
		</div><!-- /.section__body -->
	</div><!-- /.shell -->
</section><!-- /.section-queue -->

<footer class="footer-sticky">
	<div class="shell">
		<div class="footer__inner">
			<a routerLink="/home" class="logo">
				<img src="assets/images/logo--white@2x.png" alt="" width="116" height="48" />
			</a>
			
			<div class="footer__aside">
				<div class="checkbox checkbox--white" [ngClass]="{'terms_err': queue_submitted && !terms_agree}">
					<input type="checkbox" id="footer_checkbox" [checked]="terms_agree" [(ngModel)]="terms_agree">

					<label for="footer_checkbox">I agree to the <a href="https://www.hallmarkchannel.com/terms-of-use" target="_blank">terms and conditions</a></label>
				</div><!-- /.checkbox -->

				<a (click)="submitQueue();" class="btn btn--size-8">
					<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
						<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75ZM1.624,5.657l1-1.179L4.958,7.222V0H6.375V7.23L8.714,4.478l1,1.179L5.67,10.417Z" fill="#582c83"/>
					</svg>
					Download Assets
				</a>
			</div><!-- /.footer__aside -->
		</div><!-- /.footer__inner -->
	</div><!-- /.shell -->
</footer><!-- /.footer -->