import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';
import { AssetsService } from '../../services/assets.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { S3UploadService } from '../../services/s3-upload-service.service';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['../admin.component.css']
})
export class ReportsComponent implements OnInit {

private ngUnsubscribe = new Subject<void>();
	current_content: any |[];
	current_report: any |[];
	report_start=null;
	report_end=null;
	current_affiliate=true;
	current_status="active";
	resources=false;
	current_network="Hallmark Media";
	Content: any |[];
	Networks:any |[];
	Reports: any |[];
	Profile:any |[];
	stats= {
		download:null};
	role:any |[];
	network_select_open=false;
	status_select_open=false;
	propertyName="created_date";
	reverse="desc";
	 currentDate: Date = new Date();
	originalDate: Date = new Date('2022-03-01');
  constructor(public S3S:S3UploadService, public CS: ContentManagerService, public PS: ProfileService, public ADM: AdminService, public AS: AssetsService,  public router: Router){ }

  ngOnInit(): void {
	  console.log('started');
	  this.report_start=this.originalDate;
	  this.report_end=this.currentDate;
	  this.updateStats();
	  this.PS.getRole().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.role = data; 
		  if(this.role!=='admin'){
			//  this.router.navigate(['/home']);
			  console.log('bumpedrole', this.role);
		  }else{
			  console.log('role', this.role);
		  }
	  });
	  this.stats.download=0;
	  this.CS.getReports().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Reports=data;console.log('network ', this.Networks);
	  });
	  
	  console.log(this.originalDate);
	 
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	toggleSelectOpen(itm){
		this[itm+'_select_open']=!this[itm+'_select_open'];
	}
	sortBy(field){
		console.log('clicked'+field);
	  if(this.propertyName!==field){
		  this.propertyName=field;
		  this.reverse="asc";
	  }else if(this.reverse="asc"){
		  this.reverse="desc";
	  }else{
		  this.reverse="asc";
	  }
  }
	selectOption(fld,val){
		if(fld=="ntwk"){
			this.current_network=val;
		}else if(fld=="rpt"){
			this.current_report=val;
		}else{
			this.current_status=val;			
		}
	}
	editContent(content){
		this.current_content=content;
		this.clearAssetForm();
		//open modal
	}
	createContent(){
		this.current_content=[];
		this.clearAssetForm();
		console.log(this.current_content);
		//open modal new
	}
	clearAssetForm(){
		this.S3S.adminUploadsObj.next(null);
		this.S3S.uploads=[];
		this.S3S.uploads_assets=[];
		this.S3S.uploads_thumbs=[];
		this.S3S.uploads_preview=[];
		this.ADM.viewAsset=true;
	}

	viewActivity(status){
		alert('inprogress');
	}
	processedResources(){
		var arr=[];
		var that=this;
		this.Networks.forEach(function(obj){
			if(obj.network_full==that.current_network){
				obj.resources.forEach(function(obj2){
					if(obj2.asset_end!==null){
						var expires= new Date(obj2.asset_end);
						if(expires.getTime()<that.currentDate.getTime()){
							obj2.status="expired";
						}else{
							obj2.status="active";
						}
					}else{
						obj2.status="active";
					}
					obj2.network_full=that.current_network;
					arr.push(obj2);
				});
			}
		});
		console.log('resources', arr)
		return arr;
	}
	processedContent(){
		var arr=[];
		var that=this;
		this.Content.forEach(function(obj){
			//obj.downloads=0;obj.inqueue=0;obj.removed=0;
			var expires= new Date(obj.expires);
			if(obj.active==0 || obj.active==null){
				obj.status="unpublished";
			}else if(expires.getTime()<that.currentDate.getTime()){
				obj.status="expired";
			}else{
				obj.status="active";
			}
			that.Networks.forEach(function(obj2){
				if(obj2.network_id==obj.network_id){
					obj.network_full=obj2.network_full;
				}
			})
			obj.featured= (obj.page_feature>0 ? "article":"slide");
			obj.url=(obj.content_type=="external" ? obj.reference: (obj.content_type=="modal" ? '': 'https://hallmarkmediahub.com/library/'+obj.reference+'/'+obj.content_type  ));
			
			arr.push(obj);
			
		});
		return arr;
	}
	runReport(){
		this.current_report.start=this.report_start;
		this.current_report.end=this.report_end;
		this.current_report.affiliate=this.current_affiliate;
		var json_data=[this.current_report];
		console.log('json', json_data);
		//var id=this.Profile.profile_id;
		this.CS.generateReport(json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
		  if(data == "success"){
			  console.log('success');
		  }else{
			  console.log('fail);')
		  }
		});
	}
	updateStats(){
		console.log('statsstarted');
		var json_data=[{"start":this.report_start, "end": this.report_end}];
		this.ADM.runStats(json_data);
		this.ADM.getAdminStats().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.stats = data; 
		});
	}
}
