import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { EmailManagementService } from '../../services/email-management.service';
import { ContentManagerService } from '../../services/content-manager.service';



@Component({
  selector: 'app-email-table',
  templateUrl: './email-table.component.html',
  styleUrls: ['./table.component.css']
})
export class EmailTableComponent implements OnInit {
	 @Input() Emails: any | [];
	 @Input() Status: string;
	  propertyName="template_id";
	email_template: any | [];
	reverse='desc';

	

  constructor(
	public EMS: EmailManagementService,
	public CS: ContentManagerService,
	 public route: ActivatedRoute,
	public router: Router
	) { }

  ngOnInit(): void {
	  console.log("emails", this.Emails);
  }
  sortBy(field){
	  if(this.propertyName!==field){
		  this.propertyName=field;
		  this.reverse="asc";
	  }else if(this.reverse=="asc"){
		  this.reverse="desc";
	  }else{
		  this.reverse="asc";
	  }
  }
	edit_email_template(id){
		this.router.navigate(['/admin/email-detail/'+id]);
	}
	view_sent_preview(id){}
	processData(data){
		data.template_id='0';
		data.template_name='NEW-'+data.template_name;
		return data;
	}
	processMailStats(id){
		this.CS.showAlert=true;
		this.CS.alert_message="Processing, please be patient."
		this.EMS.pullMailStatsById(id).subscribe(
			(response) => {
				console.log('process',response);
				var errcheck = JSON.stringify(response);
				var id=errcheck.replace(/['"]+/g, '')
				//this.alert_message=response;
				if(errcheck.includes('success')){
					this.CS.showAlert=true;
					this.CS.alert_message=errcheck;
					this.EMS.getEmailTemplates().subscribe((data) => {
						 console.log('templateresponse', data);
					});
				}else{
					this.CS.showAlert=true;
					this.CS.alert_message="We've encountered a submission error.  Please try again or reach out to customer support.";		
				}
			}
		);
	}
	reuse_existing_template(tmpid){
		console.log('started');
		this.EMS.getEmailTemplate(tmpid).subscribe(data => { 
			 this.email_template=this.processData(data[0]);
		});
		//this.email_template.template_id=0;
	    //this.email_template.template_name='NEW-'+this.email_template.template_name;
		var form_data = [];
		form_data.push(this.email_template);
		var json_data= JSON.stringify(form_data);
		this.EMS.adminSaveEmailTemplate(json_data).subscribe(
			(response) => {
				console.log('emailtemp2',response);
				var errcheck = JSON.stringify(response);
				var id=errcheck.replace(/['"]+/g, '')
				//this.alert_message=response;
				if(errcheck.includes('Unknown error')){
					  alert("We've encountered a submission error.  Please try again or reach out to coustomer support.");
				}else{
					this.router.navigate(['/email-detail/'+id]);					
				}
			}
		);
	}
	
	
/*

  //Edit existing Template
  $scope.edit_email_template = function(template_id){
    if(template_id){
      var edit_template = EmailService.adminEditEmailTemplate(template_id);
    }
    else{
      console.log('error');
    }
  }

  //Reuse the existing template
  $scope.reuse_existing_template = function(template_id){
    if(template_id){
      var reuse_template = true;
      var edit_template = EmailService.adminEditEmailTemplate(template_id, reuse_template);
    }
    else{
      console.log('error');
    }
  }

  //View a preview of a sent template
  $scope.view_sent_preview = function(template_id){
    if(template_id){
      var preview = EmailService.adminGetSentPreview(template_id);
    }
    else{
      console.log('error');
    }
  }
	*/
	
	
	
	
	
	
}
