<header class="header" [ngClass]="{'is-open':menuOpen}">
	<div class="shell">
		<div class="header__inner">
			<a routerLink="/home" class="logo" routerLinkActive="is-highlighted is-focused">
				<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/HallmarkMedia_Logo_White.png" alt="" width="116" height="48">
			</a>
<!--			<span class="username">{{PS.profile.first_name}} {{PS.profile.last_name}}</span>-->

			<a class="nav-btn hamburger" (click)="toggleMenu();">
				<span></span>
				
				<span></span>
				
				<span></span>
			</a>

			<nav class="nav">
				<ul>
					<li class="has-dropdown has-dropdown--full-width" *ngIf="role=='admin'">
						<a routerLinkActive="is-highlighted is-focused">Admin</a>
						<div class="nav__dropdown nav__dropdown--full-width has-decoration">
							<ul>
								<li *ngIf="token?.length>0">
									<a routerLink="/admin/users" >Users</a>
								</li>
								
								<li *ngIf="token?.length>0">
									<a routerLink="/admin/assets" >Assets</a>
								</li>
								<li *ngIf="token?.length>0">
									<a routerLink="/admin/customreqs" >Customizations</a>
								</li>
								<li *ngIf="token?.length>0">
									<a routerLink="/admin/content" >Content</a>
								</li>
								<li *ngIf="token?.length>0">
									<a routerLink="/admin/reporting" >Reporting</a>
								</li>
								<li *ngIf="token?.length>0">
									<a routerLink="/admin/emails" >Email</a>
								</li>
							</ul>
						</div><!-- /.nav__dropdown -->
					</li>
					<li class="has-dropdown has-dropdown--full-width">
						<a routerLinkActive="is-highlighted is-focused">Networks</a>
						<div class="nav__dropdown nav__dropdown--full-width has-decoration">
							<ul>
								<li *ngFor="let network of Networkss | statusFilter: 'detail': '1' ;" >
									<a routerLink="/network/{{network.network_short}}"
									   >{{network.network_full}}</a>
								</li>
							</ul>
						</div><!-- /.nav__dropdown -->
					</li>
					<!--
					<li>
						<a href="http://hallmarkmediagoldrewards.com" target="_blank">Gold Rewards</a>
					</li>-->
					
					<li>
						<a routerLink="/library" routerLinkActive="is-highlighted is-focused" >
							<img src="assets/images/svg/assets.svg" alt="">
							Asset Library
						</a>
					</li>
					<!--
					<li>
						<a routerLink="/resources" routerLinkActive="is-highlighted is-focused" >
							<img src="assets/images/svg/assets.svg" alt="">
							Resources
						</a>
					</li>-->
					
					<li class="has-dropdown has-dropdown--alt has-dropdown--full-width">
						<a href="#">
							<img src="assets/images/svg/search.svg" alt="">
							Search
						</a>

						<div class="nav__dropdown nav__dropdown--full-width nav__dropdown--alt">
							<div class="search nav__search">
								<form action="?" method="get">
									<label for="search-nav" class="hidden">Search</label>

									<input type="search" name="search-nav" id="search-nav" [(ngModel)]="AS.assetSearch" placeholder="Search assets" class="search__field" autocomplete="off">

									<button routerLink="/library" type="submit" value="GO" class="search__btn">
										<img src="assets/images/svg/search--large.svg" alt="">
									</button>

									<button (click)="AS.assetSearch=null" class="search__exit">
										<img src="assets/images/svg/exit-button.svg" alt="">
									</button>
									
								</form>
							</div><!-- /.search -->
						</div><!-- /.nav__dropdown -->
					</li>
					
					<li class="has-dropdown">
						<a routerLinkActive="is-highlighted is-focused">
							<img src="assets/images/svg/user.svg" alt="">
							My Account
						</a>

						<div class="nav__dropdown nav__dropdown--small has-decoration">
							<ul>
								<li *ngIf="token?.length>0">
									<a routerLink="/my-profile" >My Profile</a>
								</li>
								
								<li *ngIf="token?.length>0">
									<a routerLink="/my-activity" >My Activity</a>
								</li>
								
								<li *ngIf="token?.length>0">
									<a (click)="PS.logout()" >Sign Out</a>
								</li>
								<li *ngIf="token?.length==0 || !token">
									<a routerLink="/register" >Create New Account</a>
								</li>
								<li *ngIf="token?.length==0 || !token">
									<a routerLink="/login" >Sign In</a>
								</li>
							</ul>
						</div><!-- /.nav__dropdown -->
					</li>
					
					<!--<li>
						<a routerLink="/asset-queue" routerLinkActive="is-highlighted is-focused" >
							<img src="assets/images/svg/queue.svg" alt="">

							<span class="nav__notification" (mouseover)="CS.summary=true"
								  *ngIf="Queue?.length>0 && Profile"
								  >{{Queue?.length}}</span>
							Queue
						</a>
					</li>-->
				</ul>
			</nav><!-- /.nav -->
		</div><!-- /.header__inner -->
	</div><!-- /.shell -->
</header><!-- /.header -->
