import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';

@Component({
  selector: 'app-asset-queue',
  templateUrl: './asset-queue.component.html',
  styleUrls: ['./asset-queue.component.css']
})
export class AssetQueueComponent implements OnInit {
	private ngUnsubscribe = new Subject<void>();
	Queue:any|[];
	Assets:any|[];
	Tactics:any|[];
	Profile:any|[];
	account_list= [];
	showAccounts=false;
	terms_agree=false;
	queue_submitted=false;
	


  constructor(public PS:ProfileService, public AS: AssetsService, public CS: ContentManagerService, public router:Router) { }

  ngOnInit(): void {
	  this.CS.summary=false;
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Profile=data;
		  console.log('Profile is', this.Profile);
	  });	
	  
	  this.AS.updateAssets(0);
	  this.AS.getAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Assets=data;});	
	  this.AS.getQueue().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Queue=this.processQueue(data);
	  });	
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	previewModal(asset){
		this.AS.previewAsset=asset.thumb;
		this.AS.previewName=asset.asset_name;
		this.AS.previewOpen=true;
	}
	processQueue(data){
		data.forEach(function(obj){
			obj.select_open_tactics=false;
			obj.select_open_accounts=false;
			obj.tactics=[];
			obj.accounts=[];
		});
		console.log(data);
		return data;
	}
   include_category(dma) {
	 const isThere = (element) => element == dma; 
      var i = this.account_list.findIndex(isThere);
      if (i > -1) {
		  this.account_list.splice(i, 1);
      } else {
		  this.account_list.push(dma);
      }
  }
  includedTactic(Q,val){
	   const isThere = (element) => element == val; 
      var i = Q.tactics.findIndex(isThere);
      if (i > -1) {
		  return true;
      }else{
		  return false;
	  }
  }
  includedAccount(Q,val){
	   const isThere = (element) => element == val; 
      var i = Q.accounts.findIndex(isThere);
      if (i > -1) {
		  return true;
      }else{
		  return false;
	  }
  }
  addTactic(Q, val){
	  console.log('clicked',val);
	  const isThere = (element) => element == val; 
      var i = Q.tactics.findIndex(isThere);
      if (i > -1) {
		  Q.tactics.splice(i, 1);
      } else {
		  Q.tactics.push(val);
      }
  }
  addAccount(Q, val){
	  const isThere = (element) => element == val; 
      var i = Q.accounts.findIndex(isThere);
      if (i > -1) {
		  Q.accounts.splice(i, 1);
      } else {
		  Q.accounts.push(val);
      }
  }
  toggleVariable(el){
	  this[el]=!this[el];
  }
  toggleSelectOpen(Q,el){
	  if(!Q['select_open_'+el]){
		  Q['select_open_'+el]=true;
	  }else{
		  Q['select_open_'+el]=false;
	  }
  }
	removeAsset(Q){
		var id=Q.history_id;
		var json_data=[];
		json_data.push({profile_id:this.PS.profile_id});
		this.AS.removefromQueue(id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			   if(data !== "success"){
				   this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>customer support</a>";
				   this.CS.showAlert = true;
			   }else{
				   this.AS.updateAssets(0);
				   this.router.navigate(['/asset-queue']);
			   }
		   });
	}
	submitQueue(){
		//validate items populated
		this.queue_submitted=true;
		var errs=0;
		var first_err=null;
		var that=this;
		console.log(that.Profile.dmas.length);
//		this.Queue.forEach(function(obj){
//			if(obj.tactics.length==0 || (obj.accounts.length==0 && that.Profile.dmas.length>0)|| obj.reach==null){
//				console.log(obj.history_id +'-'+obj.accounts.length+'-'+that.Profile.dmas.length);
//				if(first_err==null){ first_err=obj.history_id}
//				errs++;
//			}
//		});
		if(errs==0 || this.Profile.internal==1){
	//		this.Queue.forEach(function(obj){
	//			if(that.includedTactic(obj, 'Other') && obj.other){
	//				that.addTactic(obj,'Other');
	//				that.addTactic(obj,obj.other);
	//			}
	//		});
			this.queue_submitted=false;
			
			var json_data=this.Queue;
			var id=this.Profile.profile_id;
			this.AS.checkoutQueue(id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			   if(data !== "success"){
				   this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>customer support</a>";
				   this.CS.showAlert = true;
			   }else{
				   this.AS.downloadSuccess=true;
				   this.CS.updateContent();
				   this.router.navigate(['/my-activity']);
			   }
		   });
		}else{
			 this.CS.alert_message = "Please complete all fields and agree to the terms or remove unwanted items from your queue.";
			 this.CS.showAlert = true;
			 //fix to not lock  selection
			//let el = document.getElementById('tactic'+first_err);
  			 //el.scrollIntoView({   behavior: 'smooth' });
			//document.querySelector('tactic'+first_err).scrollIntoView({   behavior: 'smooth' });
		}
		
	}
	
	

}
