import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'groupBy'
})
export class GroupByPipe implements PipeTransform {

    transform(collection: any[], property: string): any[] {
        
        // prevents the application from breaking if the array of objects doesn't exist yet
        if(!collection) {
			//console.log('previouesnull');
            return null;
        }

        const groupedCollection = collection.reduce((previous, current) => {
            if (!previous[current[property]]) {
                previous[current[property]] = [current];
            } else {
                previous[current[property]].push(current);
            }
    		//console.log('previoues', previous);
            return previous;
        }, {});
    	console.log('groupobj', Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] })));
        // this will return an array of objects, each object containing a group of objects
        return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
    }

}