import { Component, OnInit, OnDestroy,ViewChild} from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { ContentManagerService } from '../../services/content-manager.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
export class ScheduleComponent implements OnInit {
 Networks:any|[];
	Guide: any|[];
	Programs: any|[];
	weekday="today";
	showtime="";
	//et_tz_offset=null;
	private ngUnsubscribe = new Subject<void>();

 constructor(public CS: ContentManagerService, public router: Router) { }

  
  ngOnInit(): void {
	  this.CS.getGuide().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Guide = data; //console.log('data is ', this.Guide); 
		  console.log('guide',this.Guide);
		  this.Programs=this.filterPrograms(data);
		  console.log('programs',this.Programs);
	  });
	  this.getCurrent();
	  this.startCarousel(3);
  }
ngOnDestroy()  {
	this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
  }
//get current program guide display time
	getCurrent(){
		var now = new Date(); //Based on users device timezone
		var now_EastTime = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
		var now_CentTime = new Date().toLocaleString("en-US", {timeZone: "America/Chicago"});

		var now_EasternTime = new Date(now_EastTime);
		var hour_EasternTime = this.hours12(now_EasternTime);

		var now_CentralTime = new Date(now_CentTime);
		var hour_CentralTime = this.hours12(now_CentralTime);

		var weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday','Thursday','Friday','Saturday'];
		this.weekday = weekdays[now_EasternTime.getDay()];
		this.showtime = hour_EasternTime+"/"+hour_CentralTime+"c";
		
		   //Get Current Hours Offset for the Eastern Timezone (Accounts for Daylight Savings) 
    	var et_tz_offset = (this.getTimezoneOffset(now, "America/New_York")/60);

    //Filter programs to just what is on air now.
    //	this.Programs = this.filterPrograms(this.Guide,now,et_tz_offset);
		
	}
	//START PROGRAM GUIDE CAROUSEL
	startCarousel(pg_count) {
		var count = 0;
		var that=this;
		var carousel = setInterval(function() {
			count += 1;
			//console.log('count'+count);
			if (count >= pg_count) {
				count = 0;
			}
			if(!that.router.url.includes('home')){
				clearInterval(carousel);
			}
			that.CS.active_ntwk=count;			   
		}, 10000); //5 SECONDS
	}
	hours12(date) { return (date.getHours() + 24) % 12 || 12; }
	getTimezoneOffset(dt, timezone) {
		let getItem = function(format) {
			format.timeZone = timezone;
			return parseInt(dt.toLocaleString(
			   'en-US', format));
		};

		let adjDate = new Date(
		   getItem({year: 'numeric'}),
		   getItem({month: 'numeric'}) - 1, // months are zero based
		   getItem({day: 'numeric'}),
		   getItem({hour: 'numeric',hour12: false}),
		   getItem({minute: 'numeric'}));
		let noSecs = new Date(dt.getTime());
		noSecs.setSeconds(0, 0);
		let diff = Math.round((adjDate.getTime() - noSecs.getTime()) / 60000);

		return dt.getTimezoneOffset() - diff;
	}
// Log to //console. Disable function in production.
	debug_program_guide(msg){
		//console.log(`${msg}`);
	}
	filterPrograms( data){
		var now = new Date(); //Based on users device timezone
		var today = now;
		var et_tz_offset = (this.getTimezoneOffset(now, "America/New_York")/60);
		var that=this;
	//	this.debug_program_guide(`today: ${today.toLocaleString("en-US", {timeZone: "America/New_York", timeZoneName: 'short' })}`);
	//	this.debug_program_guide(`Timezone Offset: ${et_tz_offset}`);

		var filtered_programs = [];
		data.forEach(function(pg,index){
			var start = new Date(`${pg.startDate}T${pg.startTime}`);
			var end = new Date(`${pg.endDate}T${pg.endTime}`);
			//var start = new Date(`${pg.startDate}T${pg.startTime}.000-0${et_tz_offset}:00`);
			//var end = new Date(`${pg.endDate}T${pg.endTime}.000-0${et_tz_offset}:00`);
			//console.log('today',today, today.getTime());
			//console.log('startdate',pg.startDate)
			//console.log('starttime',pg.startTime, start.getTime());
		//console.log(`today: ${today.toLocaleString("en-US", {timeZone: "America/New_York", timeZoneName: 'short' })}`);
		//console.log(`start: ${start.toLocaleString("en-US", {timeZone: "America/New_York", timeZoneName: 'short' })}`);
		//console.log(`end:   ${end.toLocaleString("en-US", {timeZone: "America/New_York", timeZoneName: 'short' })}`);

			//Both TODAY, START and END are set based off the user's device timezone, so they can be 
			//used for this filter.
			if(today.getTime() >= start.getTime() && today.getTime() < end.getTime()){
		//		console.log(`PROGRAM FOUND VIA FILTER`+ pg.auto_id);
				console.log('found'+pg.auto_id);
				filtered_programs.push(pg);
			}else{
		//		console.log( pg.auto_id+ 'now'+today.getTime(),'starts'+start.getTime()+'ends'+  end.getTime());
				console.log('not found'+pg.auto_id,today, today.getTime(), start.getTime(), end.getTime());
				
			}

		});
		console.log('new'+ filtered_programs);
		return filtered_programs;

	}

}

