import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';
import { S3UploadService } from '../../services/s3-upload-service.service';
import { AdminService } from '../../services/admin.service';



@Component({
  selector: 'app-my-activity',
  templateUrl: './my-activity.component.html',
  styleUrls: ['./my-activity.component.css']
})
export class MyActivityComponent implements OnInit {
	private ngUnsubscribe = new Subject<void>();
	current_tab="history";
	current_asset: any |[];
	age_range="all";
	History:any|[];
	Customizations: any |[];
	Assets:any|[];
	Profile: any |[];
	assetSearch=null;
	customSearch=null;
	direction="↓";
	current_sort='file_downloaded';
	current_custom_sort='requested_submitted';
	sort_order="desc";
	select_open_sort=false;
	select_open_custom_sort=false;
	sorts=[
		{label:'Downloaded Date', column:'file_downloaded'},
		{label:'Usage Start', column:'asset_start'},
		{label:'Usage End', column:'asset_end'}
	];
	custom_sorts=[
		{label:'Request Date', column:'request_submitted'},
		{label:'Approval Date', column:'request_approved'},
		{label:'Last Updated', column:'last_updated'},
		{label:'Request ID', column:'request_id'},
		{label:'Request Label', column:'asset_name'},
		{label:'Status', column:'request_status'}
	];
	asc=false;
	reverse="desc";
	Files: any | [];
  	Files_assets: any | [];
   file_upload_error=false;
   Error: any |[];

	
	affid_status=["No Affidavit Submitted", 
		"Affidavit Pending Approval <span class='asset__status is-pending'></span>",
		"Affidavit Approved <span class='asset__status is-success'></span>",
		"Affidavit Rejected <a href='' class='link link--alt asset__status asset__status--link'>Resubmit</a>"
	]

  constructor(public PS:ProfileService, public AS: AssetsService, public ADM: AdminService, public S3S: S3UploadService, public CS: ContentManagerService, public router:Router) { }

  ngOnInit(): void {
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Profile = data;});
	  this.AS.updateAssets(0);
	  this.AS.getAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Assets=data;});	
	  this.AS.getHistory().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.History=data; console.log('history', this.History);
	  });
	  this.AS.getCustoms().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Customizations=this.setUpExpand(data); console.log('customs', this.Customizations);
	  });
	  
	//   this.ADM.detail_id=this.Profile.profile_id;
	//  this.ADM.updateUserDetail();
	 // this.ADM.getUserCustoms().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
	//	  this.Customizations=this.setUpExpand(data); console.log('customs', this.Customizations);
	 // });
	  
	  
	  
  }
	ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }

	setUpExpand(data){
		if(data!=="No data"){
			console.log('dt', data);
			data.forEach(function(obj){
				obj.expand=false;
				obj.user_comment='';
			});
		}else{
			data=[];
		}
		return data
	}
	reDownload(H){
		var json_data=[];
		var url= H.asset_info[0].path;
		json_data.push({profile_id:this.PS.profile_id});
		this.AS.reDownloadAsset(H.history_id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			   if(data !== "success"){
				   this.CS.alert_message = "This submission encountered an error.  Please try again or contact customer support";
				   this.CS.showAlert = true;
			   }else{
				   window.open( url, "_blank");
			   }
		   });
	}
	toggleTool( H, el){
		if(!H['tool_'+el]){
			H['tool_'+el]=true;
		}else{
			H['tool_'+el]=false;
		}
	}
	openAff(asset){
		this.current_asset=asset;
		//this.current_asset.usage=[];
		
		this.AS.pullUsageById(asset.history_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		 // this.current_asset.usage.push(data); console.log('assetdata', this.current_asset);
			this.current_asset.usage=data; console.log('assetdata', this.current_asset);
					this.AS.viewResults=true;
	  	});
		

	}
	openCAR(asset){
		this.current_asset=asset;
		//this.current_asset.usage=[];
		
		this.AS.pullCARById(asset.history_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		 // this.current_asset.usage.push(data); console.log('assetdata', this.current_asset);
			this.current_asset.CAR=data; console.log('assetdata', this.current_asset);
					this.AS.viewCAR=true;
	  	});
		

	}
	openNonAssetCAR(){
		this.current_asset=[];
		//this.current_asset.usage=[];
		
		//this.AS.pullCARById(asset.history_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		 // this.current_asset.usage.push(data); console.log('assetdata', this.current_asset);
		//	this.current_asset.CAR=data; console.log('assetdata', this.current_asset);
					this.AS.viewCAR=true;
	  //	});
		

	}

	isImageFile(fileUrl: string): boolean {
		const lowerCaseUrl = fileUrl.toLowerCase();
		return lowerCaseUrl.endsWith('.png') || lowerCaseUrl.endsWith('.jpg');
	  }
	getStatus(asset){
		var status="Not Yet Submitted";
		if(asset.results_denied>asset.results_approved){
			status="Submission Denied: ";			
		}else if(asset.results_approved>'2022-10-18'){
			status="Submission Approved: ";			
		}else if(asset.results_submitted>'2022-10-18'){
			status="Submission Under Review";			
		}
		return status;
	}
	viewReqs(asset){
		this.current_tab="custom";
	
		//revise customizations to request_id
		//set custom sort?
		//switch tab		
	}
	
	updateSearch(tab){
		//this.getFilteredAssets();
	}
	getFilteredAssets(){ 
		var arr=this.History;
		console.log('start', arr);
	/*	arr=this.excludeData(arr, 'Network Assets', 'asset_type');
		arr=this.filterData(arr, this.AS.current_title, 'asset_name', false );
		arr=this.filterData(arr, this.AS.current_type, 'asset_type', false );
		arr=this.filterData(arr, this.AS.current_tag, 'tags', 'tag' );
		arr=this.filterData(arr, this.AS.current_campaign, 'campaign','campaign_name' );
		arr=this.filterData(arr, this.AS.current_network, 'networks', 'network_full' );
		this.FilteredAssets=arr;
		this.FilteredNetworks=this.processFilter('networks','network_id',arr);
		this.FilteredCampaigns=this.processFilter('campaign','campaign_name',arr);
	    this.FilteredTitles=this.processFilter(null,'asset_name',arr);
		this.FilteredTags=this.processFilter('tags','tag',arr);
		this.FilteredTypes=this.processFilter(null,'asset_type', arr);
	*/	
		console.log('end', arr);
	}
	searchScroll(){
		window.scroll(500, 300);		  
	}
	getSortLabel(itm,cust){
		var lbl=null;
		if(cust){
			this.custom_sorts.forEach(function(obj){
				if(obj.column==itm){ lbl=obj.label;}
			});
			
			
		}else{
			this.sorts.forEach(function(obj){
				if(obj.column==itm){ lbl=obj.label;}
			});
			
		}
		return lbl;
	}
	
	toggleSelectOpen(el){
		this['select_open_'+el]=!this['select_open_'+el];
		var that=this;
		//this.filters.forEach(function(obj){
		//	if(obj!==el){that['select_open_'+obj]=false;}
		//})
	}
	select_option(el,val){
 console.log('history', this.History);
		if(el=='sort'){
			if(this.current_sort!==val.column){
				this.current_sort=val.column;
				this.reverse="asc";
				console.log('newfield',val, this.reverse);
			}else if(this.reverse=="asc"){
				this.reverse="desc";
			}else{
			    this.reverse="asc";
			}
			this.direction=(this.reverse=='desc'?'↓':'↑');
		}else if(el=='custom_sort'){
			if(this.current_custom_sort!==val.column){
				this.current_custom_sort=val.column;
				this.reverse="asc";
				console.log('newfield',val, this.reverse);
			}else if(this.reverse=="asc"){
				this.reverse="desc";
			}else{
			    this.reverse="asc";
			}
			this.direction=(this.reverse=='desc'?'↓':'↑');
		}
		 console.log('history', this.History);
	}

	toggleExpand(item){
		if(!item.expand){
			this.Customizations.forEach(function(obj){
				obj.expand=false;
			});
			item.expand=true;
		}else{
			item.expand=false;
		}
	}
	
	uploadFiles(event, el) {    
		var files = event.target.files;
		console.log('started', files);
		console.log('started2', files.length);
        this['Files_'+el] = files;
		console.log('started3', this['Files_'+el].length);
		var that=this;
      if (files && files.length > 0){ 
		   this['Files_'+el][0].pathway=el+'/'; //setting S3 Path
			  //fileEvent($event,'uploads')
              this.S3S.uploadSingleFile(this['Files_'+el][0], el);
      }
  };
	submitComment(item, approved){
		console.log('here', item);
		//if(item.user_comments=="Add response and/or upload file and click submit below"){
		//	item.user_comments=null;
		//}
		if(this.Files_assets){
			item.support_file=this.S3S.uploads_assets[0].url
		}
		var json_data=[item];
		this.ADM.putUserCustoms(json_data, this.Profile.profile_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
		   var strcheck = JSON.stringify(data);if(strcheck.includes('uccess') ){
				console.log('succeeded');
				this.CS.alert_message = "Success";
				this.CS.showAlert = true;
			   this.ngOnDestroy();
			   this.ngOnInit();

		   }else{
				console.log('issue');
				this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>technical support</a>";
				this.CS.showAlert = true;
		   }
	   });	
	}
	

	
}
