import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-network-asset-detail',
  templateUrl: './network-asset-detail.component.html',
  styleUrls: ['./network-asset-detail.component.css']
})
export class NetworkAssetDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
