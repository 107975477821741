import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRecips'
})
export class FilterRecipsPipe implements PipeTransform {
  transform(items: any[], field: string, values: any[], include: boolean): any[] {
    if (!items) return [];

    if (!values || values.length === 0) return items;

    if (include) {
      return items.filter(item => values.includes(item[field]));
    } else {
      return items.filter(item => !values.includes(item[field]));
    }
  }
}




