import { Component, OnInit,Input,OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AdminService } from '../../services/admin.service';
import { AssetsService } from '../../services/assets.service';


@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
	@Input() filter_assets: any | [];
	//Assets:any|[];
	 private ngUnsubscribe = new Subject<void>();

	//filter={
	//	'asset': null,
	//}
	
	
  constructor(
	public ADMS: AdminService, public AS: AssetsService
	
	) { }

  ngOnInit(): void {

  }
	ngOnDestroy(){
	this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
}
	clearFilters(){
		this.ADMS.selectedAsset=null;
		this.ADMS.assetUsers=null;
		this.ADMS.selectedAssetOption=false;
		//this.ADMS.showFilter=false;
	}
	applyFilters(){
		this.ADMS.pullAdminAssetUsers(this.ADMS.selectedAsset).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.ADMS.assetUsers=data;
			console.log(this.ADMS.assetUsers);
	    });
		
		//this.ADMS.selectedAssetOption="exclude";
		//this.ADMS.showFilter=false;
	}
	/*
	processAccounts(data){
		var arr_year=[];
		var arr_tier=[];
		var arr_status=[];
		var arr_mvpd=[];
		var tier_up=0;
		data.forEach(function(value,key){
			if(arr_year.indexOf(value.promo_yr)==-1){ 
				console.log('year', value.promo_yr);
				arr_year.push(value.promo_yr);
			}
			arr_tier.forEach(function(value1,key1){
				if(value1.level ==value.tier.substr(0, 1)){
					tier_up++;
				}
			});
			if(tier_up<=0){
				arr_tier.push({level: value.tier.substr(0, 1), selected: false});
			}
			tier_up=0;
			//if(arr_tier.indexOf(value.tier.substr(0, 1))==-1){ arr_tier.push(value.tier.substr(0, 1));}
			if(arr_status.indexOf(value.status)==-1){ arr_status.push(value.status);}
			if(arr_mvpd.indexOf(value.mvpd)==-1){ arr_mvpd.push(value.mvpd);}
		});
		this.years=arr_year.sort();
		this.tiers=arr_tier.sort(function (a, b) {return a.level - b.level;});
		this.statuses=arr_status.sort();
		this.mvpds=arr_mvpd.sort();
		console.log('years',this.years);
	}
  	toggleSelectOpen(id){
		console.log('clicked '+ id);
		this['select_open_'+id]=!this['select_open_'+id];
		//this.select_open_3=!this.select_open_3;
	}
	//
	closeAll(){
		for(var i = 1; i <= 5; i++){
			 this['select_open_'+i]=false;
		}
	}
	setFilter(field,val){
		this[field]=val;
		this.closeAll();
		this.executeFilter();
	}
	setTierFilter(tier){
		var tierval=tier.level;
		tier.selected=true;
		var filtr=[];
//this.AS.tierFilter;
		if(filtr){
			var f_index=filtr.indexOf(tierval.substr(0, 1));
			if(f_index==-1){ 
				filtr.push(tierval.substr(0, 1));
			}else{
				filtr.splice(f_index, 1);
			}
		}else{
			filtr.push(tierval.substr(0, 1));
		}
		this.executeFilter();
	}
	setPriorityFilter(priority){
		//make exclude input visible
//		this.AS.priorityFilter=priority;
//		this.AS.priorityFilterEnrolled=true;
		this.executeFilter();
	}
	clearPriorityFilter(){
//		this.AS.priorityFilter=null;
//		this.AS.priorityFilterEnrolled=false;
	}
	clearTierFilter(){
//		this.AS.tierFilter=[];
		this.tiers.forEach(function(value,key){
			value.selected=false;
		});
	}
	clearFilter(field){
		this[field]=null;
		this.closeAll();
		this.executeFilter();
	}
	clearAllFilters(){
		this.current_year=null;
		this.current_status=null;
		this.current_mvpd=null;
		//this.current_priority=null;
		//this.current_tier=null;
		this.current_product=null;
		this.clearPriorityFilter();
		this.clearTierFilter();
	}
	executeFilter(){
		var arr=[];
		if(this.current_year!=null){arr['promo_yr']= this.current_year;}
		if(this.current_status!=null){arr['status']= this.current_status;}
		if(this.current_mvpd!=null){arr['mvpd']= this.current_mvpd;}
		//if(this.current_priority!=null){arr['priority_name']= this.current_priority;}
		//if(this.current_tier!=null){arr['tier']= this.current_tier;}
		if(this.current_product!=null){arr['product_name']= this.current_product;}
//		this.AS.accountFilter=arr;
		this.clearSelects();
		console.log(arr);
	}
	clearSelects(){
		this.filter_accounts.forEach(function(value, key){
			value.receive_email=false;
		});
		console.log('acctsf',this.filter_accounts);
	//	console.log('accts', this.AS.adminAccountsObj.getValue());
	}
	*/
}
