<div class="main__body">
	<section class="section section__audience">
		<div class="section__head section__head__audience">
			<div class="title">
				<h1>Email Management</h1>
			</div><!-- /.title -->
		</div><!-- /.section__head -->
		<!--<app-filter-promo_yr></app-filter-promo_yr>-->
	</section>
  
  <section class="section section__audience" *ngIf="!request_account">
    <div class="section__body">
	
      <div class="manage-overview-cta">
    	   <a (click)="create_new_email_template(true)"  class="btn btn--purple">Create New Ad Hoc Email</a>
		   <a (click)="create_new_email_template(false)"  class="btn btn--purple">Upload New Newsletter</a>
		 <!-- <a *ngIf="email_status=='sent'" (click)="EMS.processMailStats()"  class="btn btn--purple">Refresh Stats</a>-->
      </div>
      <div class="tabs">
		  <div class="tabs__head">
			<nav class="tabs__nav">
			  <ul>
				<li [ngClass]="{ 'current--alt': email_status == 'draft' }">
				  <a (click)="set_status('draft')">
					Draft
				  </a>
				</li>

				<li [ngClass]="{ 'current--alt': email_status == 'sent' }">
				  <a (click)="set_status('sent')">
					Sent
				  </a>
				</li>
				</ul>
			</nav><!-- /.tabs__nav -->
		  </div><!-- /.tabs__head -->

		  <div class="tabs__body">
			<div class="tab section__body" [ngClass]="{ 'current': email_status == 'accepted' }" id="tab1">
				<div class="box">
					<header class="box__head" style="background-image: url(assets/images/temp/background-img-violet.png);">

					  <h3>{{email_status | uppercase}} EMAILS</h3>
					</header><!-- /.box__head -->
					<app-email-table [Emails]="AdminEmails" [Status]="email_status"></app-email-table>
				</div>
			</div><!-- /.tab -->

		  </div><!-- /.tabs__body -->
		</div>
    </div><!-- /.tabs -->
</section><!-- /.section-faq -->

</div>
