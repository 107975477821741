import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '../../services/admin.service';


@Component({
  selector: 'app-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.css']
})
export class EmailPreviewComponent implements OnInit {
	@Input() email_template: any |[];
  
  constructor(public AS:AdminService) { }

  ngOnInit(): void {
	  console.log('template', this.email_template);
  }
	closeResults(){
		this.AS.viewEmailPreview=false;
	}

}
