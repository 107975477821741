<div class="container">	
  <div class="row">
    <div
      class="tile"
      *ngFor="let resource of Resources; let i = index"
         [style.order]="i === expandedIndex ? tileOrder.expanded : tileOrder.default"
  [class.expanded]="i === expandedIndex"

      (click)="expandTile(i)"
    >
      <div class="tile-content">
		<h3>{{ resource.category }}</h3>
         <figure class="asset__image" >
			<img src="{{resource.image ? resource.image : CS.default_img}}" alt="{{resource.category}}" >
		</figure><!-- /.asset__image -->

      </div>
      <div class="expanded-content" *ngIf="isTileExpanded(i)">
        <div class="account" *ngFor="let cat of resource.resource_type">
			<div class="account__head">
				<h4>{{cat.description}}</h4>
			</div><!-- /.account__head -->
			<div class="account__body">
				<div class="flxbx">

					<div class="account__content">
						<div class="asset__aside">
							<figure class="asset__image">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/hallmark_media_fallback.jpg" alt="" width="195" height="110">
							</figure><!-- /.asset__image -->
								<div class="asset__entry"><h3>Resource name</h3></div>


							<a *ngIf="cat.category!=='Sizzles'" href="#" target="_blank" class="btn btn--alt asset__preview-btn">Download</a>
							<a *ngIf="cat.category=='Sizzles'" (click)="previewModal(1)" class="btn btn--alt asset__preview-btn">Preview</a>
						</div><!-- /.asset__aside -->

					</div><!-- /.account__content -->
					<div class="account__content">
						<div class="asset__aside">
							<figure class="asset__image">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/hallmark_media_fallback.jpg" alt="" width="195" height="110">
							</figure><!-- /.asset__image -->
								<div class="asset__entry"><h3>Resource name</h3></div>


							<a *ngIf="!vid" href="#" target="_blank" class="btn btn--alt asset__preview-btn">Download</a>
							<a *ngIf="vid" (click)="previewModal(1)" class="btn btn--alt asset__preview-btn">Preview</a>

						</div><!-- /.asset__aside -->

					</div><!-- /.account__content -->
					<div class="account__content">
						<div class="asset__aside">
							<figure class="asset__image">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/hallmark_media_fallback.jpg" alt="" width="195" height="110">
							</figure><!-- /.asset__image -->
								<div class="asset__entry"><h3>Resource name</h3></div>

							<a *ngIf="!vid" href="#" target="_blank" class="btn btn--alt asset__preview-btn">Download</a>
							<a *ngIf="vid" (click)="previewModal(1)" class="btn btn--alt asset__preview-btn">Preview</a>
						</div><!-- /.asset__aside -->

					</div><!-- /.account__content -->
					<div class="account__content">
						<div class="asset__aside">
							<figure class="asset__image">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/hallmark_media_fallback.jpg" alt="" width="195" height="110">
							</figure><!-- /.asset__image -->
								<div class="asset__entry"><h3>Resource name</h3></div>

							<a *ngIf="!vid" href="#" target="_blank" class="btn btn--alt asset__preview-btn">Download</a>
							<a *ngIf="vid" (click)="previewModal(1)" class="btn btn--alt asset__preview-btn">Preview</a>
						</div><!-- /.asset__aside -->

					</div><!-- /.account__content -->
					<div class="account__content">
						<div class="asset__aside">
							<figure class="asset__image">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/hallmark_media_fallback.jpg" alt="" width="195" height="110">
							</figure><!-- /.asset__image -->
							<div class="asset__entry"><h3>Resource name</h3></div>
							<a *ngIf="!vid" href="#" target="_blank" class="btn btn--alt asset__preview-btn">Download</a>
							<a *ngIf="vid" (click)="previewModal(1)" class="btn btn--alt asset__preview-btn">Preview</a>
						</div><!-- /.asset__aside -->
					</div><!-- /.account__content -->



				</div>


			</div><!-- /.account__body -->

		</div><!-- /.account -->

      </div>
    </div>
  </div>
	
  	<div class="row">
		<div class="tile">
			<div class="expanded-content">
				<div class="account">
					<div class="account__head">
						<h4>Social Icons</h4>
					</div><!-- /.account__head -->
					<div class="account__body">
						<div class="flxbx">
							<div class="account__content_social_icon">
							<a href="https://twitter.com/hallmarkchannel" target="_blank">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/social_icon_x.png" width="40" style="width:100%;max-width:40px;color:#333333;font-size:12px;line-height: normal;text-align: center;font-family:Arial, Helvetica, sans-serif;padding:10px 0;" border="0" alt="Twitter" title="Twitter"/>
							</a>
						</div>
						<div class="account__content_social_icon">
							<a href="https://www.facebook.com/hallmarkchannel" target="_blank">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/social_icon_fb.png" width="40" style="width:100%;max-width:40px;color:#333333;font-size:12px;line-height: normal;text-align: center;font-family:Arial, Helvetica, sans-serif;padding:10px 0;" border="0" alt="Facebook" title="Facebook"/>
							</a>
						</div>
						<div class="account__content_social_icon">
							<a href="https://www.instagram.com/hallmarkchannel/" target="_blank">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/social_icon_insta.png" width="40" style="width:100%;max-width:40px;color:#333333;font-size:12px;line-height: normal;text-align: center;font-family:Arial, Helvetica, sans-serif;padding:10px 0;" border="0" alt="Instagram" title="Instagram"/>
							</a>
						</div>
						<div class="account__content_social_icon">
							<a href="https://www.pinterest.com/hallmarkchannel" target="_blank">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/social_icon_pin.png" width="40" style="width:100%;max-width:40px;color:#333333;font-size:12px;line-height: normal;text-align: center;font-family:Arial, Helvetica, sans-serif;padding:10px 0;" border="0" alt="Pinterest" title="Pinterest"/>
							</a>
						</div>
						<div class="account__content_social_icon">
							<a href="https://www.youtube.com/hallmarkchannelusa" target="_blank">
									<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/social_icon_yt.png" width="40" style="width:100%;max-width:40px;color:#333333;font-size:12px;line-height: normal;text-align: center;font-family:Arial, Helvetica, sans-serif;padding:10px 0;" border="0" alt="YouTube" title="YouTube"/>
								</a>

						</div>
						<div class="account__content_social_icon">
							<a href="https://www.threads.net/@hallmarkchannel" target="_blank">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/social_icon_threads.png" width="40" style="width:100%;max-width:40px;color:#333333;font-size:12px;line-height: normal;text-align: center;font-family:Arial, Helvetica, sans-serif;padding:10px 0;" border="0" alt="Threads" title="Threads"/>
							</a>
						</div><!-- /.account__content -->
						<div class="account__divider"></div><!-- /.account__divider -->
						</div>
					</div>
				</div>
			</div>		
		</div>
		<div class="tile">
			<div class="expanded-content">
				<div class="account">
					<div class="account__head">
						<h4>Facebook Feed</h4>
					</div><!-- /.account__head -->
					<div class="account__body">
						<div class="fb-page" data-href="https://www.facebook.com/hallmarkchannel/" data-tabs="timeline" data-width="380" data-height="300" data-small-header="false" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/hallmarkchannel/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/hallmarkchannel/">Hallmark Channel</a></blockquote></div>
					</div>
				</div>
			</div>		
		</div>
	</div>

		
	
</div>

