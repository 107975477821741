import { Component } from '@angular/core';
import { Credentials, S3 } from 'aws-sdk';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams  } from '@angular/common/http';
import { ProfileService } from './profile.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
//Environment
import { environment } from '../../environments/environment';

 

@Injectable({
  providedIn: 'root'
})

export class S3UploadService {
	private apiUrl = 'https://c825djw1sg.execute-api.us-east-1.amazonaws.com/prod'; // Set your API Gateway base URL here
  	private envaccessKeyId = environment.accessKeyId; 
	private envsecretAccessKey = environment.secretAccessKey; 
  // Replace the values with your own
  private readonly _awsConfig = {
	accessKeyId: this.envaccessKeyId,
    secretAccessKey: this.envsecretAccessKey,
    
	
	//accessKeyId: "AKIAQTPKZAQU4SCBCJCO",
    //secretAccessKey: "BbpnydIthX5Rs09mJ0orytsB4eE6AOvgnJAX4RYJ",
    s3BucketRegion: "us-east-1", // example: "us-west-2"
    s3BucketName: "assets.hallmarkaffiliates/mediahub"    // example: "mycompany.testbucket"
  }
 
  private _awsCredentials: Credentials;
  private _s3ClientConfig: S3.ClientConfiguration;
  private _s3: S3;
  public adminUploadsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	
  uploading=false;
 // public uploads: any |[];
  uploads=[];uploads_assets=[];uploads_preview=[];uploads_thumbs=[];
  uploads_email=[];uploads_html=[];
  uploadCTA="Upload A File";
  progressPercentage=null;
  progessStatus="null";
  uploadStatus: string =  "(no upload yet)";
  expectEndpoint: any;
  actualEndpoint: any;
 
  constructor(private PS : ProfileService, private http: HttpClient) {
    // Create an AWS S3 client
    this._awsCredentials = new Credentials(this._awsConfig.accessKeyId, this._awsConfig.secretAccessKey);
    this._s3ClientConfig = {
      credentials: this._awsCredentials,
      region: this._awsConfig.s3BucketRegion,
      sslEnabled: true
    };
    this._s3 = new S3(this._s3ClientConfig);
	  

    // Set the expected and actual endpoints
    var isRegionUSEast :boolean = (this._awsConfig.s3BucketRegion).toLowerCase() == "us-east-1";
    var endpointHost :string = isRegionUSEast ? "s3" : "s3-" + this._awsConfig.s3BucketRegion
    this.expectEndpoint = endpointHost + ".amazonaws.com";
    this.actualEndpoint = this._s3.config.endpoint;
  }

  // Event triggered when a file has been specified 
	getUploadsAdmin(){
		return this.adminUploadsObj;
	}
	clearAdminUploads() {
		this.adminUploadsObj.next([]);
	}

	getRefDate(){
		var dd:any;
		var mm:any;
		var today = new Date();
		dd = today.getDate();
		mm = today.getMonth()+1; 
		var yyyy = today.getFullYear();
		if(dd<10) 
		{
			var xd='0'+String(dd);
			dd=xd;
		} 

		if(mm<10) 
		{
			var xm='0'+String(mm);
			mm=xm;
		}
		return yyyy+mm+dd
	}
	secureDownloadold(asset) {
		//var secure_bucket = new AWS.S3();
		var asset=asset.replace('https://s3.amazonaws.com/assets.hallmarkaffiliates/','');
		//asset=asset.replace('https://s3.amazonaws.com/'+S3_SECURE_BUCKET+'/','');
		

		this._s3.getSignedUrl('getObject',{ Bucket: 'assets.hallmarkaffiliates', Key: asset }, function(error,data){
		  if (error != null) {
			  alert("Failed to retrieve an object: " + error);
		  } else {
			
			  console.log('data is', data);
			  //alert("Loaded " + data.ContentLength + " bytes");
			window.open(data);
		  }
		});		
	  };
	
	secureDownload(asset: string) {
		const key = asset.replace('https://s3.amazonaws.com/assets.hallmarkaffiliates/', '');
console.log('key is',key);
			
		this.getPresignedDownUrl(key).subscribe((response: any) => {
			const presignedUrl = response.url;
			console.log(response.url);
			window.open(presignedUrl);
		}, (error) => {
			console.error('Error getting presigned URL:', error);
			alert("Failed to retrieve an object: " + error.message);
		});
	  }
 
    uploadEvent(fileInput: any, destination){
		fileInput=fileInput.target.files[0];
		this.uploadSingleFile(fileInput,destination);
	}


  
//new
getPresignedDownUrl(key: string): Observable<any> {
    const apiUrl = `${this.apiUrl}/presignedURLhallassets`;
    const params = { key: key };
    const options = {
      params: new HttpParams({ fromObject: params })
    };

    return this.http.get(apiUrl, options);
  }

uploadSingleFile(file: File, destination: string) {
    const folder = (destination == "html" ? "email" : destination);
    const ref_id = this.getRefDate();
    const fname = file.name.replace(' ', '_');
    const fileName = `${ref_id}_${fname}`;
    const that = this;

    // Request pre-signed URL from the backend
    this.getPresignedUrl(fileName, file.type, destination).subscribe((response: any) => {
        const presignedUrl = response.url;
        
        // Upload the file using the pre-signed URL
		this.uploadFileToS3(file, presignedUrl, destination, fileName);
    }, (error) => {
        console.error('Error getting presigned URL:', error);
        this.uploadStatus = `Error getting presigned URL: ${error.message}`;
    });
}

getPresignedUrl(fileName: string, fileType: string, destination: string): Observable<any> {
    const apiUrl = `${this.apiUrl}/presignedURLhallassets`;
    const params = {
        fileName: fileName,
        contentType: fileType,
        destination: destination // Include destination in the request
    };

    return this.http.post(apiUrl, params);
}

uploadFileToS3(file, presignedUrl: string, destination: string, fileName: string) {
    this.uploading = true;
    this.uploadStatus = "starting upload...";
    const xhr = new XMLHttpRequest();
    const that = this;

    xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
            const progressPercentage = Math.round((event.loaded * 100) / event.total);
            file.progressPercentage = progressPercentage;
            that.progressPercentage = progressPercentage;
            that.uploadStatus = progressPercentage === 100 ? "Uploaded" : `Uploading... ${progressPercentage}%`;
            console.log("Uploading... :: " + progressPercentage);
        }
    });



	xhr.addEventListener("load", () => {
        if (xhr.status === 200) {
            that.uploadStatus = "Success!";
            const url = presignedUrl.split('?')[0];
            
            // Reset the uploads array before adding the new file
            that.uploads = [{ filename: file.name, url: url, fileprogress: 100 }];
            that.uploadCTA = "Upload Another";
            
            if (destination === 'email' && that.uploads_email?.length > 0) {
                that.uploads_email = [{ filename: file.name, url: url, fileprogress: 100 }];
            } else {
                that['uploads_' + destination] = [{ filename: file.name, url: url, fileprogress: 100 }];
            }
            console.log('1',destination,that['uploads_' + destination]);
            console.log('2asset',that['uploads_assets']);
            console.log('3thumbs',that['uploads_thumbs']);
            console.log('4prev',that['uploads_preview']);
            console.log('5email',that['uploads_email']);
            
            that.adminUploadsObj.next(that.uploads);
            console.log('Upload successful:', url);
        } else {
            that.uploadStatus = `Upload failed: ${xhr.statusText}`;
            console.error('Upload failed:', xhr.statusText);
        }
    });



    xhr.addEventListener("error", () => {
        that.uploadStatus = `Upload failed: ${xhr.statusText}`;
        console.error('Upload failed:', xhr.statusText);
    });

    xhr.open("PUT", presignedUrl, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
}


}
