import { Component, AfterViewInit, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ActivatedRoute,Router } from '@angular/router';
//import { AngularEditorConfig } from '@kolkov/angular-editor';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';
import { EmailManagementService } from '../../services/email-management.service';
//import { ContentManagementService } from '../../../services/content-management.service';
//import { S3UploadService } from '../../services/s3-upload-service.service';

//import { environment } from '../../environments/environment';

declare const MediumEditor: any;

@Component({
  selector: 'app-email-detail',
  templateUrl: './email-detail.component.html',
  styleUrls: ['./email-overview.component.css']
})

export class EmailDetailComponent implements OnInit, AfterViewInit {
	editor: any;
	editor2: any;
  	@ViewChild('editable', { static: true }) editable: ElementRef;
	@ViewChild('editable2', { static: true }) editable2: ElementRef;
	breadcrumbs: any |[];
	priorities: any | [];
	temp_id='0';
	anio: number = new Date().getFullYear();
	public AdminEmails: any | [];
	request_account=false;
	current_promo_yr=2021;
	email_status="draft";
	submitted=false;
	saved_template=false;
	select_open=false;
	alert_message='';
	showAlert=false;
	showPreview=false;
	spot_link=null;
	private ngUnsubscribe = new Subject<void>();

    email_template={
	    template_id: '0',
	    url: null,	    
	    template_name: "New Template",
	    priority_id: null,
	    priority_name: null,
		email_subject:null,
	    email_headline:null,
	    greeting: 'Hello from Hallmark,',
	    email_image:null,
		email_body_1:null,
	    artwork_body_2: null,
		email_body_2: null,
		enroll_now: false,
		download_spots:false,
		submit_results:false,
	    shop_now:false,
		custom_cta:false,
		custom_cta_copy:null,
		recipient: null,
		adhoc:true,
	   test_email:false
 };
	
	

  constructor(
    public PS: ProfileService, 
	public AS: AdminService,
	public EMS: EmailManagementService,
	//public CMS: ContentManagementService,
	public route: ActivatedRoute,
	public router: Router,
	//public US : S3UploadService
  ) { }

  ngOnInit(): void {
	  
	  //this.PS.adminView=true;
	  //this.breadcrumbs=this.route.snapshot.data
	  this.email_template.template_id='0';
	  
	  //this.CMS.getPrioritiesAdmin().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {this.priorities=data;});
	  // assets or campaigns
	  this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
		  this.temp_id=params.get('id');
	  });
	  
	  if(this.temp_id !== '0' && this.temp_id !== null){
		  this.EMS.getEmailTemplate(this.temp_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
			 this.email_template=data[0];
			 this.EMS.current_template_id=this.email_template.template_id;
		  });
	  }
	  this.EMS.getEmailTemplates().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.AdminEmails=data;
	  });
  }
  ngAfterViewInit(): void {
    this.editor = new MediumEditor(this.editable.nativeElement, 
		{
		paste: {
			cleanPastedHtml:true,
			cleanAttrs: ['style', 'class', 'name'],
			cleanTags: ['meta', 'script']
		},
		toolbar: {
			sticky: true,
			buttons: ['bold', 'italic', 'underline','anchor', 'superscript', 'subscript','html']
		}
	});
	
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
	
	set_status(status){
		 this.email_status=status;
	 }
	
	setBody(field,html){
		if(field=="body1"){
			this.email_template.email_body_1=html;
		}else{
			this.email_template.email_body_2=html;
		}
		console.log('change',this.email_template.email_body_1);
		console.log(html);
	}
	
	reset_email_updates(template){
		this.EMS.current_template_id=null;
		this.router.navigate(['/email-overview']);
	}

	admin_send_test_email(template, form){
		//if email valid
		 var tempurl='';
	//	if(this.US.uploads.length>0){
	//		this.US.uploads.forEach(function(value, key){
	//			tempurl=value.url;
	//		});
	//	}
		console.log('tempurl', tempurl);
		//this.email_template.artwork_body_2=(tempurl!='' ? tempurl: this.email_template.artwork_body_2);
		//may have to adjust CTAs
		var form_data = [];
		form_data.push(this.email_template);
		var json_data= JSON.stringify(form_data);
		
      this.EMS.adminSendTestEmail(this.email_template.template_id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(response) => {
				console.log('sentemail', response);
				var errcheck = JSON.stringify(response);
				if(errcheck.includes('Unknown error')){
					  this.alert_message="We've encountered a submission error.  Please try again or reach out to coustomer support.";
					  this.showAlert=true;
				}else{
					this.alert_message=errcheck;
					this.showAlert=true;
				}
	  });
	}
	admin_get_audience(template, form){
		this.EMS.current_template_id=this.email_template.template_id;
		console.log('clicked', this.EMS.current_template_id);
		this.router.navigate(['/admin/audience']);
	}
	admin_save_template(template, form){
		//if id =0, set insert else update
		//post email function
		console.log('template', template);
		console.log('form', form);
		
        var tempurl='';
	//	if(this.US.uploads.length>0){
	//		this.US.uploads.forEach(function(value, key){
	//			tempurl=value.url;
	//		});
	//	}
	//	this.email_template.artwork_body_2=(tempurl!='' ? tempurl: this.email_template.artwork_body_2);
		//may have to adjust CTAs
		var form_data = [];
		form_data.push(this.email_template);
		var json_data= JSON.stringify(form_data);
		this.EMS.adminSaveEmailTemplate(json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(response) => {
				console.log('response', response);
				var errcheck = JSON.stringify(response);
				var id=errcheck.replace(/['"]+/g, '')
				//this.alert_message=response;
				if(errcheck.includes('Unknown error')){
					  this.alert_message="We've encountered a submission error.  Please try again or reach out to coustomer support.";
					  this.showAlert=true;
				}else{
					this.email_template.template_id=id;
				    this.alert_message="Saved Template #" + id;
					if(!form.untouched){this.showAlert=true;}
				    this.submitted=true;
	//			    this.US.uploads=[];
	//				this.US.uploading=false;
	//				this.US.uploadStatus =  "(no upload yet)";
				}
			}
		)		  //this.router.navigate(['/overview']);
	}
 
	toggleSelectOpen(){
		this.select_open=!this.select_open;
	}


}
