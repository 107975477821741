<div class="tabs__body-actions">
	<div >
		<div class="form__row form__row--small">
		<label for="items">Assets:</label>
		<select id="items" [(ngModel)]="ADMS.selectedAsset" >
		  <option value="">Select Assets</option>
		  <option *ngFor="let item of filter_assets" [value]="item.asset_id">{{item.asset_name}}</option>
		</select>
		</div>
		<div class="form__row form__row--small">
		<label for="options">Options:</label>
		<select id="options" [(ngModel)]="ADMS.selectedAssetOption">
		  <option value="">Select Option</option>
		  <option value="true">Has Downloaded Selected Assets</option>
		  <option value="false">Has Not Downloaded Selected Assets</option>
		</select>
		</div>
		<p>Selected Items: {{ADMS.selectedAsset}}</p>
		<p>Selected Option: {{ADMS.selectedAssetOption}}</p>
	</div>
	<div><button class="btn" (click)="ADMS.updateAudienceFilter()">Apply Filters</button></div>
	<div><button class="btn" (click)="clearFilters()">Clear Filters</button></div>

</div>
    
   
