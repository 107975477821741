import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';



@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
	private ngUnsubscribe = new Subject<void>();
	Queue:any|[];
	Assets:any|[];
	Profile: any |[];
	
  constructor(public PS:ProfileService, public AS: AssetsService, public CS: ContentManagerService, public router:Router) { }

  ngOnInit(): void {
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Profile = data;});
	  
  }
	ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	
	

}
