import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';



@Component({
  selector: 'app-infopage',
  templateUrl: './infopage.component.html',
  styleUrls: ['./infopage.component.css']
})
export class InfopageComponent implements OnInit {
	Resources:any|[];
	vid=true;
  constructor(public PS:ProfileService, public AS: AssetsService, public CS: ContentManagerService, public router:Router) { }
private ngUnsubscribe = new Subject<void>();
	

  ngOnInit(): void {
	  this.CS.getResources().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Resources=data;});
	  console.log('resources',this.Resources);
  }
	
	 ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	previewModal(asset){
		console.log(asset);
		this.AS.previewAsset='';
		this.AS.previewName='';//asset.asset_name;
		this.AS.previewOpen=true;
	}
	
}
