import { Pipe, PipeTransform } from '@angular/core';
import { AssetsService } from '../services/assets.service';


@Pipe({
  name: 'searchFilter',
  pure: false
})
export class SearchFilterPipe implements PipeTransform {
 constructor( private AS : AssetsService) { }
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    let searchWords = searchText.split(/ +(?=(?:(?:[^"]*"){2})*[^"]*$)/); // Split search text into individual words, preserving words in quotes
    searchWords = searchWords.map(word => word.replace(/"/g, '')); // Remove quotes from search words

    let x= items.filter(item => {
      let itemText = JSON.stringify(item).toLowerCase(); // Convert item to a string and make it lowercase for case-insensitive search

      // Check if all search words appear in the item text, in any order
      let allWordsMatch = searchWords.every(word => {
        return itemText.indexOf(word.toLowerCase()) !== -1;
      });
	  return allWordsMatch;
		 
     
    });
	  this.AS.filteredCount=x.length;
	  return x;
  }
}
