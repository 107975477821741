import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	reminderfail=false;
	unregistered=false;
	showPassword=false;
	loginSubmitted=false;
	login={
		email:null,
		password:null
	};
	private ngUnsubscribe = new Subject<void>();

  constructor(public PS: ProfileService, public AS: AssetsService, public CS:ContentManagerService) { }

  ngOnInit(): void {
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
	checkEmail(){
		var json_data = [];
		json_data.push({email: this.login.email});
			
		this.PS.checkRegister(json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			if(data=="registered"){
				this.unregistered=false;
			}else{
				this.unregistered=true;
			}
		});
	}

	submit_login_form(loginForm){
		this.loginSubmitted = true;
		if(this.login.email !=="" && this.login.password !==""){//} && !this.unregistered){
			var json_data = [];
			json_data.push({email: this.login.email, password: this.login.password});
			
			this.PS.postLogin(json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
				if(data['response']=="true"){
					this.PS.loginToken( data['token']);
					this.PS.loadProfile(data['profile_id']);
					this.AS.updateAssets(data['profile_id']);
					console.log('success');
					this.CS.loginEnd();
					console.log('tk2',localStorage.getItem('jwt'));
				}else if(data['response']=="pending"){
					this.CS.loginState="pending";
				}else if(data['response']=="false"){
					this.CS.loginState="fail";
				}else{
					this.CS.loginState="error";
				}
			})

		}else{
			console.log('fields empty');
		}
		
	}	

  	reset_password(form){
		this.reminderfail=false;
		
		if(this.login.email!==''){
			this.loginSubmitted=false;
			var json_data = [];
			json_data.push({email: this.login.email});
			
			console.log(json_data);
			this.PS.passwordReminder(json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
				var strcheck = JSON.stringify(response);
				if(strcheck.includes('We cannot find a login') ){
					this.reminderfail=true;
					this.CS.loginState = 'fail';
				}if(strcheck.includes('under review') ){
					this.CS.loginState = 'pending';
				}else if(strcheck.includes('has been sent') ){
					this.CS.loginState = 'begin';
				}else{ 
					this.CS.loginState = 'fail';
				}
			});
		}else{
			this.loginSubmitted=true;
		}
	}

	
	
	
	
}
