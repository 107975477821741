
     <!-- <section class="section-headline section-headline--tertiary">
            <div class="shell shell--size-3">
                <h2>Users Overview</h2>
            </div><!-- /.shell shell-/-size-3 -->
      <!--  </section><!-- /.section-headline -->
<!--<app-edit-profile [Profile]="this.user_edit"></app-edit-profile>-->


        <section class="section-filters section-filters--bottom-offset">
            <div class="shell">
                <h3>Filters:</h3>

                <div class="cols cols--alt cols--bottom-offset">
                    <div class="col col--1of5">
                        <div class="select" [ngClass]="{'is-open':select_open}"  (click)="toggleSelectOpen();">
                            <div class="select__head">
                                <p>{{current_status ? 'Status: '+ current_status: 'By Status'}}</p>
                            </div><!-- /.select__head -->

                            <div class="select__list" (mouseleave)="toggleSelectOpen();">
                                <ul>
                                   
									<li (click)="selectOption('all');">All</li>
                                    <li (click)="selectOption('open');">Open</li>
									<li (click)="selectOption('unreviewed');">Unreviewed</li>								
                                    <li (click)="selectOption('closed');">Closed</li>
                                </ul>
                            </div><!-- /.select__list -->
                        </div><!-- /.select -->
                    </div><!-- /.col-/-1of5 -->
                </div><!-- /.cols -->
            </div><!-- /.shell -->
        </section><!-- /.section-filters -->
        

        <section class="section-created-assets">
            <div class="shell shell--size-4">
                <div class="section__inner">
                    <div class="section__head">
                        <h4>Manage Users</h4>

                        <a routerLink="/register" class="btn section__btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <rect data-name="icons/add background" width="20" height="20" fill="none"></rect>
                              <rect data-name="Box 24pt" width="20" height="20" fill="none"></rect>
                              <path data-name="Icon Style" d="M7.792,7.5h6.375V9.167H7.792v7.5H6.375v-7.5H0V7.5H6.375V0H7.792Z" transform="translate(1.417 1.667)" fill="currentColor"></path>
                            </svg>

                            <span>
                                Add New User
                            </span>
                        </a>
                    </div><!-- /.section__head -->

  									<div class="section__body" >
				<header class="form__head form__head__alt">
					<h3>Customization Requests</h3>
				</header><!-- /.form__head -->
				<div class="table table-custom">
					<table>
						<thead>
							<tr>
								<th >DETAIL</th>
								<th class="w1" (click)="sortBy('request_id')"><u>REQ.ID</u></th>
								<th class="w1"  (click)="sortBy('last_updated')"><u>UPDATED</u></th>	
								<th class="w2"  (click)="sortBy('request_status')"><u>STATUS</u></th>		
								<th  class="w3" (click)="sortBy('asset_name')"><u>ASSET NAME</u></th>	
								<th (click)="sortBy('first_name')"><u>FIRST NAME</u></th>	
								<th (click)="sortBy('last_name')"><u>LAST NAME</u></th>	
								<th (click)="sortBy('partner_full')"><u>PARTNER</u></th>	
								<th (click)="sortBy('request_submitted')"><u>SUBMITTED</u></th>	
								<th (click)="sortBy('request_approved')"><u>APPROVED</u></th>	
						
							</tr>
						</thead>
						<tbody>
							<ng-container *ngFor="let item of Customizations | statusFilter: 'review_status': current_status | sortBy: reverse : propertyName">
								<tr >
									<td ><a  (click)="toggleExpand(item)" class="btn btn--light">{{item.expand? 'HIDE':'VIEW'}}</a></td>

											<td class="w1">{{item.request_id}}</td>
											<td class="w1">{{item.last_updated | date: 'shortDate'}}</td>
											<td class="w2">{{item.request_status}}</td>
											<td class="w3">{{item.asset_name}}</td>
											<td>{{item.first_name}}</td>
											<td>{{item.last_name}}</td>
											<td>{{item.partner_full}}</td>								
											<td>{{item.request_submitted | date: 'shortDate'}}</td>
											<td>{{item.request_approved | date: 'shortDate'}}</td>
								
								</tr>
								<div class="accordion" >
									<div class="accordion__section  "  [ngClass]="{'is-open': item.expand}">
										<div class="accordion__body">
											<div class="accordion__body-head" >
												<h3><b>ACTIVITY</b>:{{item.request_approved>'2022-01-01'? "APPROVED":''}} {{item.customs_detail[0]?.activity[0]?.descr}}</h3>
											</div><!-- /.accordion__body-head -->
											<div class="message-container">

												<ng-container *ngFor="let a of item.customs_detail">
													<ng-container *ngFor="let m of a.activity">

														<div class="message-bubble" [ngClass]="{'outgoing':m.reviewer_comment}" >

															<p>{{m.reviewer_comment? "Reviewer: ":"User: "}}{{m.descr}} {{m.user_comment}} {{m.reviewer_comment}}</p>
															<!--<a *ngIf="m.support" href="{{m.support}}" TARGET="_blank">DOWNLOAD</a>-->
															<a *ngIf="m.support" (click)="S3S.secureDownload(m.support)" target="_blank">DOWNLOAD</a>
															<span class="message-time">{{a.created_date | date: 'shortDate'}}</span>
														</div>
													</ng-container>

												</ng-container>
											</div>


											<div class="accordion__body-content" *ngIf="!item.request_approved>'2022-01-01' ||item.request_approved==null">

												<div class="newcomment">
													<div class="form__row">
														<textarea [(ngModel)]="item.reviewer_comment" placeholder="Add response and/or upload file and click submit below"></textarea>
													</div>
													<div class="form__row">
														<div class="file-box file-box-assets" style="margin-bottom:1.5rem;">
															<p style="margin-bottom:0;">
																<a *ngIf="item.url && !Files_assets" href="{{item.url}}" target="_blank">Attachment</a>
																<span *ngFor="let upload of S3S.uploads_assets" ><br>New Attachment: 
																	<a href="upload.Location" target="_blank">{{upload.filename}}  - {{upload.fileprogress}}% - Uploaded</a>
																</span>						
															</p>


															<label for="itemurl" class="btn btn--small">
																<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
																	<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
																</svg>

																Browse
															</label>
															<input id="itemurl" #itemurl type="file" style="display:none" (change)="uploadFiles($event,'assets')"  required/>
															<div class="upload_status" *ngIf="Files_assets?.length > 0 && S3S.uploads_assets?.length==0">
																<div *ngFor="let file of Files_assets">
																	<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>
																</div>
								<!-- upload errors-->
																<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
																	We had trouble uploading the file. Please refresh the page and try again.
																	<p>If the problem persists please supply us with the following details</p>

																</div>
																<div>{{Error}}</div>
														  </div>
														<div><input (click)="submitComment(item, false)" class="btn form__btn" value="Submit" name="submitcomment"></div>	
														</div><!-- /.file-box -->
													</div><!-- /.form__row -->
													<div class="form__actions" >
						
						<input (click)="clearComment(item)" class="btn form__btn" value="Clear" name="clearcomment">
						<input (click)="submitComment(item, true)" class="btn form__btn" value="Approve Customization" name="approve">
						
					</div><!-- /.form__actions -->	
				

												</div>
											</div><!-- /.accordion__body-content -->
										</div><!-- /.accordion__body -->



									</div><!-- /.accordion__section -->

								</div><!-- /.accordion js-accordion -->
							</ng-container>
							

						</tbody>
					</table>
			 </div>
			</div>

                </div><!-- /.section__inner -->
            </div><!-- /.shell shell--size-3 -->
        </section><!-- /.section-created-assets -->
<app-alert></app-alert>
