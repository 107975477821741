import { Component, OnInit, Input, OnDestroy,ViewChild} from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';

import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';
import { S3UploadService } from '../../services/s3-upload-service.service';

@Component({
  selector: 'app-content-detail',
  templateUrl: './content-detail.component.html',
  styleUrls: ['../../admin/admin.component.css']
})
export class ContentDetailComponent implements OnInit {

   private ngUnsubscribe = new Subject<void>();
  @Input() content: any |[];
  @Input() resources: any |[];
 
  Profile: any |[];
  Networks: any |[];
  Campaigns: any |[];
  Assets: any |[];
  Tags: any |[];
  select_open_campaign=false;
  select_open_network=false;
  select_open_type=false;
  select_open_reference=false;
  current_network=null;
  current_campaign=null;
  current_asset=null;
  current_reference=null;
  
  	
  submitted=false;
  up_inprogress=false;
  Files: any | [];
  file_upload_error=false;
  Error: any |[];
	

  constructor(public S3S:S3UploadService, public PS:ProfileService,  public ADM: AdminService, public AS: AssetsService, public CS: ContentManagerService, public router:Router) { }

  ngOnInit(): void {
	  
	    this.content.nocta=(this.content.cta=='none'?true:false);
	  console.log('nocta',this.content.nocta );
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Profile=data;
		  console.log('Profile is', this.Profile);
	  });
	  this.CS.getNetworks().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Networks=data;
		  console.log('Networks is', this.Networks);
		  this.getCurrent('network', 'network_id', 'network_full');
	  });
	  this.AS.getAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Assets=data;
		  this.getCurrent('asset', 'reference', 'asset_name' );
	  });
	  this.CS.getTags().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Tags=data;
		  console.log('Tags is', this.Tags);
	  });
	  this.CS.getCampaigns().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Campaigns=data;
		  this.getCurrent('campaign','reference', 'campaign_name');
		  console.log('Campaigns is', this.Campaigns);
	  });
	 
	  console.log('asset', this.content);
	  
	  if(!this.resources){
		  this.content.starts = new Date(this.content.starts);
	  	  this.content.expires = new Date(this.content.expires);
		  if(!this.content.content_id ){
		  	this.content={ page_feature: false, active:false, subhead:null};
		  }
	  } else{
		  this.content.asset_start = new Date(this.content.asset_start);
	  	  this.content.asset_end = new Date(this.content.asset_end);
		  if(!this.content.asset_id ){
			   this.content={};
		  	this.content.networks=[];
		  }
	  }
	  
	  console.log('asset', this.content);

	 
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
   includedElement(Q,val,el){
	  const isThere = (element) => element == val; 
       var i = (Q[el] ? Q[el].findIndex(isThere): -1);
     if (i > -1) {
		  return true;
      }else{
		  return false;
	  }
  }
  addElement(Q, val, el){
	  console.log('clicked',val);
	  const isThere = (element) => element == val; 
      var i = (Q[el] ? Q[el].findIndex(isThere): -1);
	  Q[el]=(Q[el] ? Q[el] : []);
	  console.log('Q is',Q);
      //var i = Q[el].findIndex(isThere);
      if (i > -1) {
		  Q[el].splice(i, 1);
      } else {
		  Q[el].push(val);
      }
  }
	keyPressNumbers(event) {
		var charCode = (event.which) ? event.which : event.keyCode;
		// Only Numbers 0-9
		if ((charCode < 48 || charCode > 57)) {
		  event.preventDefault();
		  return false;
		} else {
		  return true;
		}
	  }	

  toggleVariable(el){
	  this[el]=!this[el];
  }
  toggleSelectOpen(Q,el){  
	  if(!this['select_open_'+el]){
		 this['select_open_'+el]=true;
	  }else{
		 this['select_open_'+el]=false;
	  }
  }
  select_option(el,val){
		this['select_open_'+el]=!this['select_open_'+el];
		if(el=="network"){
			this['current_'+el]=val.network_full;
			this.content.network_id=val.network_id;		
		}else if(el=="campaign"){
			this['current_'+el]=val.campaign_name;
			this.content.reference=val.campaign_id;		
		}else if(el=="asset"){
			this['current_'+el]=val.asset_name;
			this.content.reference=val.asset_id;		
		}else{
			this['current_'+el]=val;
			if(el=="type"){
				this.content.content_type=val;
				this.content.network_category=val;
			}
		}
	}
  uploadFiles(event, el) {
		var files = event.target.files;
		console.log('started', files);
		console.log('started2', files.length);
        this.Files = files;
		console.log('started3', this.Files.length);
		var that=this;

      if (files && files.length > 0){ 
		   this.Files[0].pathway=el+'/'; //setting S3 Path
			  //fileEvent($event,'uploads')
              this.S3S.uploadSingleFile(this.Files[0], el);
      }
  };
 getCurrent(el, fld, goal){
	 var data=el.charAt(0).toUpperCase() + el.slice(1)+'s';
	 var that=this;
	 this[data].forEach(function(obj){
		 console.log(fld+that.content[fld]);
		 if(obj[el+'_id']==that.content[fld]){
			 that['current_'+el]=obj[goal];
			 console.log('score');
		 }	 
	 });
	 console.log('current', this.current_campaign, this.current_asset);
	 console.log('current'+data, this['current_'+el]);
 }
	
	
 checkFiles(){
	 var asst=this.content;
	 if(!asst.artwork && !this.Files){
		 console.log(asst.artwork,this.Files);
		 return true;
	 }else{
		 return false;
	 }
 }

 checkInProgress(){
	 if(this.S3S.uploads[0] && this.S3S.uploads[0].fileprogress<100){
		 console.log('thumbs',this.S3S.uploads);
		 return true;
	 }else{
		 console.log('clear');
		 return false;
	 }
	
 }
	checkThis(fld){
		this.content[fld]=!this.content[fld];
		console.log(this.content[fld]);
	}
 submitContent(form){
	 this.submitted=true;
	 this.file_upload_error=this.checkFiles();
	 var asst=this.content;
	 console.log(this.content);
	 console.log(Array.of(this.content));
	 if(form.valid && this.content.network_id && !this.file_upload_error){
		 
		 this.content.cta=(this.content.nocta?'none':null);
		 asst.artwork=(this.Files? this.S3S.uploads[0].url: asst.artwork);
		 console.log('asset',this.content);
		 console.log('type', typeof(this.content));
		// var json_data=(asst.asset_id>0? [this.content] : [this.content[0]]);
		 var json_data=[this.content];
		 console.log('json', json_data);
		 var id=this.Profile.profile_id;
		 this.CS.submitContent(id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			   if(data == "fail"){
				   this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>customer support</a>";
				   this.CS.showAlert = true;
			   }else{
				   this.CS.updateContent();
				   this.AS.updateAssets(id);
				   this.ADM.updateAdmin();
				   this.S3S.uploads=[];
				   this.closeResults();
			   }
		   });
	  }
	 else{
		 console.log('asset', this.content);
		 console.log('form', form);
	 }
 }
	
 submitResource(form){
	 this.submitted=true;
	 this.file_upload_error=this.checkFiles();
	 var asst=this.content;
	 console.log(this.content);
	 console.log(Array.of(this.content));
	 if(form.valid && (asst.path || this.Files)){
		 asst.path=(this.Files? this.S3S.uploads[0].url: asst.path);
		 console.log('asst',asst);
		 console.log('content',this.content);
		 var json_data=[asst];
		 console.log('json', json_data);
		 var id=this.Profile.profile_id;
		 this.CS.submitResource(id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			   if(data == "success"){
				  this.CS.updateContent();
				   this.AS.updateAssets(id);
				   this.ADM.updateAdmin();
				   this.S3S.uploads=[];
				   this.closeResults();
			   }else{
				   
				    this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>customer support</a>";
				   this.CS.showAlert = true;
			   }
		   });
	  }
	 else{
		 console.log('asset', this.content);
		 console.log('form', form);
	 }
 }
  
	
closeResults(){
	 this.ADM.viewAsset=false;
 }
	/*
 select_option(el, val){
	 if(val=='other'){
		 this['other_'+el]=true;
		 this.select_open_type=false;
 
	 }else{
		 this.content[el]=val;
	 }
 }*/
 
}



