import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SwiperModule } from 'swiper/angular';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatNativeDateModule} from '@angular/material/core';
//import { AutocompleteLibModule } from 'angular-ng-autocomplete';

//import { NgSelectModule } from "@ng-select/ng-select";
 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//pages
import { LandingComponent } from './components/landing/landing.component';
import { NetworkComponent } from './components/network/network.component';
import { AssetLibraryComponent } from './components/asset-library/asset-library.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { MyActivityComponent } from './components/my-activity/my-activity.component';
import { AssetQueueComponent } from './components/asset-queue/asset-queue.component';
import { RegisterComponent } from './components/register/register.component';

//page children
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderFullComponent } from './components/header-full/header-full.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { LoginComponent } from './components/login/login.component';
import { PreviewComponent } from './components/preview/preview.component';
import { QueueFooterComponent } from './components/queue-footer/queue-footer.component';

//modals
import { AlertComponent } from './modals/alert/alert.component';
import { WelcomeComponent } from './modals/welcome/welcome.component';
import { EditProfileComponent } from './modals/edit-profile/edit-profile.component';
import { QueueSummaryComponent } from './modals/queue-summary/queue-summary.component';
import { SubmitResultsComponent } from './modals/submit-results/submit-results.component';
import { SubmitCustomComponent } from './modals/submit-custom/submit-custom.component';
import { DownloadSuccessComponent } from './modals/download-success/download-success.component';
import { HomePreviewComponent } from './modals/preview/preview.component';

//admin section
import { UsersComponent } from './admin/users/users.component';
import { AssetsComponent } from './admin/assets/assets.component';
import { ContentComponent } from './admin/content/content.component';
import { AssetDetailComponent } from './modals/asset-detail/asset-detail.component';
import { ContentDetailComponent } from './modals/content-detail/content-detail.component';
import { UserDetailComponent } from './admin/user-detail/user-detail.component';
import { NetworkAssetDetailComponent } from './modals/network-asset-detail/network-asset-detail.component';
import { ReportsComponent } from './admin/reporting/reports.component';
import { AudienceOverviewComponent } from './admin/communication/audience-overview.component';
import { EmailDetailComponent } from './admin/communication/email-detail.component';
import { EmailNewsletterDetailComponent } from './admin/communication/email-newsletter-detail.component';
import { EmailOverviewComponent } from './admin/communication/email-overview.component';
import { AudienceTableComponent } from './admin/communication/audience-table.component';
import { EmailTableComponent } from './admin/communication/email-table.component';
import { EmailPreviewComponent } from './modals/email-preview/email-preview.component';
import { CustomReqsComponent } from './admin/custom-reqs/custom-reqs.component';


//filters
import { SearchComponent } from './modals/search/search.component';
import { FilterComponent } from './admin/filters/filter.component';
import { GroupByPipe } from './pipes/groupby.pipe';
import { SortByPipe } from './pipes/sortby.pipe';
import { SortByDatePipe } from './pipes/sortbydate.pipe';

import { StatusFilterPipe } from './pipes/status-filter.pipe';
import { PreciseFilterPipe } from './pipes/precise-filter.pipe';
import { AssetFilterPipe } from './pipes/asset-filter.pipe';
import { SubCatFilterPipe } from './pipes/subcat-filter.pipe';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { AgeFilterPipe } from './pipes/age-filter.pipe';
import { CategoryFilterPipe } from './pipes/category-filter.pipe';
import { FilterRecipsPipe } from './pipes/filter-recips.pipe';


//Services etc
import { ProfileService } from './services/profile.service';
import { ContentManagerService } from './services/content-manager.service';
import { AssetsService } from './services/assets.service';
import { EmailManagementService } from './services/email-management.service';
import { AdminService } from './services/admin.service';
import { AuthService } from './services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SupportComponent } from './components/support/support.component';
import { CountdownComponent } from './modals/countdown/countdown.component';
import { InfopageComponent } from './components/infopage/infopage.component';
import { ResourcesComponent} from './components/infopage/resources.component';

@NgModule({
  declarations: [
    AppComponent,
	HeaderComponent,
	FooterComponent,
    LandingComponent,
    LoginComponent,
    NetworkComponent,
    AssetLibraryComponent,
    MyProfileComponent,
    MyActivityComponent,
    HeaderComponent,
    FooterComponent,
    AssetQueueComponent,
    RegisterComponent,
    HeaderFullComponent,
    QueueFooterComponent,
    QueueSummaryComponent,
	PreviewComponent,
    HomePreviewComponent,
    AlertComponent,
    SearchComponent,
    FilterComponent,
	GroupByPipe,
	SortByPipe,
	SortByDatePipe,
	StatusFilterPipe,
	PreciseFilterPipe,
	AssetFilterPipe,
	SubCatFilterPipe,
	SearchFilterPipe,
	AgeFilterPipe,
	CategoryFilterPipe,
	FilterRecipsPipe,
 	SubmitResultsComponent,
 	SubmitCustomComponent,
 	ScheduleComponent,
 	DownloadSuccessComponent,
	WelcomeComponent,
	EditProfileComponent,
 	ContactusComponent,
 	UsersComponent,
 	AssetsComponent,
 	ContentComponent,
 	AssetDetailComponent,
 	ContentDetailComponent,
 	UserDetailComponent,
 	NetworkAssetDetailComponent,
	ReportsComponent,
   	SupportComponent,
	AudienceOverviewComponent,
	EmailOverviewComponent,
	EmailDetailComponent,
	EmailNewsletterDetailComponent,
	EmailTableComponent ,
	AudienceTableComponent,
    EmailPreviewComponent,
    CountdownComponent,
    InfopageComponent,
    ResourcesComponent,
    CustomReqsComponent
  ],
  imports: [
  //  AutocompleteLibModule,
    BrowserModule,
    AppRoutingModule,
	HttpClientModule,
	SwiperModule,
	FormsModule,
	BrowserAnimationsModule,
	MatDatepickerModule,
	MatInputModule,
	MatNativeDateModule
  ],
  providers: [CookieService, AuthService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

