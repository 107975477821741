import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	private ngUnsubscribe = new Subject<void>();
    Queue: any | [];
	Networkss: any | [];
 	Profile: any|[];
	token: any|[];
	role: any|[];
	menuOpen=false;


  constructor(public CS: ContentManagerService,public PS: ProfileService, public AS: AssetsService, public router: Router) { }

  ngOnInit(): void {
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Profile=data;  
	  });
	  this.PS.getToken().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.token = data; console.log(this.token)});
	  this.PS.getRole().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.role = data; console.log(this.role)});
	  this.CS.getNetworks().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Networkss=data;  
	  });
	  this.AS.getQueue().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Queue=data;  
	  });
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	
  toggleMenu(){
	if(!this.menuOpen){
		this.menuOpen=true;
		document.body.classList.add('nav-visible');
	}else{
		this.menuOpen=false;
		document.body.classList.remove('nav-visible');
	}
};

/*

$('.nav').on('click', '.has-dropdown', function(event) {
	event.preventDefault();

	const $this = $(this);
	let mediaQuery = window.matchMedia('(max-width: 1023px)');

	if(mediaQuery.matches) {
		$this.children('.nav__dropdown').slideToggle('normal', function() {
			$(this).css('display', '').toggleClass('is-visible');
		});
	}
});



$window.on('resize orientationchange', function(event) {
	if($window.width() > 1023) {
		$header.find('.nav__dropdown').removeClass('is-visible');
	}
});
*/
}
