<div class="countdown">
  	<div class="clock">
		<div class="number">
		<span>{{ days }}</span>
		<span>{{ hours | number: '2.0-0'}}</span>
		<span>{{ minutes | number: '2.0-0' }}</span>
		</div>
	</div>
</div>

