<section class="section-profile">
	<div class="section__head">
		<div class="shell shell--size-3">
			<h2>Info & Resources</h2>
		</div><!-- /.shell -->
	</div><!-- /.section__head -->

	<app-asset-preview></app-asset-preview>
	<div class="section__body">
		<div class="shell shell--size-3"  *ngFor="let topic of Resources">
			<h3>{{topic.category}}</h3>
			<div class="account" *ngFor="let cat of topic.resource_type">
				<div class="account__head">
					<h4>{{cat.description}}</h4>
				</div><!-- /.account__head -->

				<div class="account__body">
					<div class="account__content">
						<div class="asset__aside">
							<figure class="asset__image">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/hallmark_media_fallback.jpg" alt="" width="195" height="110">
							</figure><!-- /.asset__image -->
								<div class="asset__entry"><h3>Resource name</h3></div>

							
							<a *ngIf="cat.category!=='Sizzles'" href="#" target="_blank" class="btn btn--alt asset__preview-btn">Download</a>
							<a *ngIf="cat.category=='Sizzles'" (click)="previewModal(1)" class="btn btn--alt asset__preview-btn">Preview</a>
						</div><!-- /.asset__aside -->
									
					</div><!-- /.account__content -->
					<div class="account__content">
						<div class="asset__aside">
							<figure class="asset__image">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/hallmark_media_fallback.jpg" alt="" width="195" height="110">
							</figure><!-- /.asset__image -->
								<div class="asset__entry"><h3>Resource name</h3></div>
							
							
							<a *ngIf="!vid" href="#" target="_blank" class="btn btn--alt asset__preview-btn">Download</a>
							<a *ngIf="vid" (click)="previewModal(1)" class="btn btn--alt asset__preview-btn">Preview</a>
							
						</div><!-- /.asset__aside -->
									
					</div><!-- /.account__content -->
					<div class="account__content">
						<div class="asset__aside">
							<figure class="asset__image">
								<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/hallmark_media_fallback.jpg" alt="" width="195" height="110">
							</figure><!-- /.asset__image -->
								<div class="asset__entry"><h3>Resource name</h3></div>

							<a *ngIf="!vid" href="#" target="_blank" class="btn btn--alt asset__preview-btn">Download</a>
							<a *ngIf="vid" (click)="previewModal(1)" class="btn btn--alt asset__preview-btn">Preview</a>
						</div><!-- /.asset__aside -->
									
					</div><!-- /.account__content -->
					<div class="account__divider"></div><!-- /.account__divider -->

				</div><!-- /.account__body -->
			</div><!-- /.account -->
		</div><!-- /.shell -->
	</div><!-- /.section__body -->
</section><!-- /.section-profile -->
