import { Component, OnInit } from '@angular/core';
import { ContentManagerService } from '../../services/content-manager.service';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  constructor(public CS: ContentManagerService) { }

  ngOnInit(): void {
  }

}
