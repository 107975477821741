<app-edit-profile></app-edit-profile>
<section class="section-profile">
	<div class="section__head">
		<div class="shell shell--size-3">
			<h2>My Profile</h2>
		</div><!-- /.shell -->
	</div><!-- /.section__head -->

	<div class="section__body">
		<div class="shell shell--size-3">
			<h3>Your Account</h3>

			<div class="account">
				<div class="account__head">
					<h4>Your Personal Information</h4>
				</div><!-- /.account__head -->

				<div class="account__body">
					<div class="account__content">
						<div class="account__content-inner">
							<h6>{{Profile.first_name + ' '+ Profile.last_name}}</h6>
							<h6>{{Profile.phone}}</h6>
						    <h6 *ngIf="Profile.partner_full">{{Profile.partner_full}}</h6>
							<h6 *ngIf="Profile.account_type">{{Profile.account_type}}</h6>
							<h6 *ngIf="Profile.title">{{Profile.title}}</h6>
							
						</div><!-- /.account__content-inner -->

						<div class="account__aside">
							<a (click)="PS.editProfile('addr');" class="btn btn--size-7">
								<img src="assets/images/svg/ico-pen.svg" alt="">
								Edit
							</a>
						</div><!-- /.account__aside -->
					</div><!-- /.account__content -->

					<div class="account__divider"></div><!-- /.account__divider -->

					<div class="account__content account__content--align-bottom">
						<div class="account__content-inner">
							<h6>Email</h6>

							<p>{{Profile.email}}</p>
						</div><!-- /.account__content-inner -->

						<div class="account__aside">

							<a (click)="PS.editProfile('eml')" class="link link--alt">
								Change Email
							</a>
						</div><!-- /.accoint__aside -->
					</div><!-- /.account__content -->	

					<div class="account__divider"></div><!-- /.account__divider -->

					<div class="account__content account__content--align-bottom">

						<div class="account__content-inner">
							<h6>Password</h6>

							<p>*********************</p>
						</div><!-- /.account__content-inner -->

						<div class="account__aside">
							<a (click)="PS.editProfile('pw')" class="link link--alt">
								Change Password
							</a>
						</div><!-- /.accoint__aside -->
					</div><!-- /.account__content -->							
				</div><!-- /.account__body -->
			</div><!-- /.account -->
		</div><!-- /.shell -->
	</div><!-- /.section__body -->
</section><!-- /.section-profile -->
