import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByDate'
})
export class SortByDatePipe implements PipeTransform {

  transform(array: any[], field: string, order: 'asc' | 'desc' = 'asc'): any[] {
    if (!array || !field) {
      return array;
    }

    const sortedArray = array.sort((a: any, b: any) => {
      const dateA = new Date(a[field]).getTime();
      const dateB = new Date(b[field]).getTime();
      return dateA > dateB ? 1 : dateA === dateB ? 0 : -1;
    });

    return order === 'asc' ? sortedArray : sortedArray.reverse();
  }
}
