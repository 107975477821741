<section class="section section--cards section--spacing-1 padding-11" style="padding-top:3rem;" >
		<div class="shell">
			<div class="section__head">
				<h3>Support</h3>
			</div><!-- /.section__head -->


			<div class="cards-secondary cards-secondary--spacing-1">
				<div class="grid">
					<div class="grid__col grid__col--1of3">
						<div class="card-secondary">
							<div class="card__content">
								<div class="card__info">
									<span>How-To Guide</span>
								</div><!-- /.card__info -->

								<ul class="card__links">
										<li >
											<a  href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/HallmarkMediaHub_WebsiteUserSteps.pdf" target="_blank" class="link link--alt ">
												<img src="assets/images/svg/ico-document.svg" alt="">
												Downloadable PDF
											</a>
										</li>
										
								</ul><!-- /.card__links -->
							</div><!-- /.card__content -->
						</div><!-- /.card-secondary -->
					</div><!-- /.grid__col -->
	
					<div class="grid__col grid__col--1of3" >
						<div class="card-secondary">
							<div class="card__content">
								<div class="card__info">
									<span>Contact Us</span>
								</div><!-- /.card__info -->
								<ul class="card__links">
										<li  >
											<a href="alert('Rep Contact Sheet coming soon')"
											   
											   target="_blank" class="link ">
												
												For promotional & partnership opportunities, please contact your rep!
											</a>
										</li>
									<li  >For technical operations issues, email <a class="link" href="mailto:tops@hallmarkmedia.com">tops@hallmarkmedia.com</a></li>
								<li  >For Hallmark Media Hub questions or issues, email <a class="link" href="mailto:info@hallmarkmediahub.com">info@hallmarkmediahub.com</a></li>
								
<li  >Please note that access to this website is exclusively for our affiliate partners. If you have press inquiries, please visit <a class="link" href="crownmediapress.com">crownmediapress.com</a></li>

									</ul><!-- /.card__links -->
							</div><!-- /.card__content -->
						</div><!-- /.card-secondary -->
					</div><!-- /.grid__col -->

					</div><!-- /.grid -->
			</div><!-- /.cards-secondary -->

			</div><!-- /.shell -->
	</section><!-- /.section -->
