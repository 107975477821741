import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, { SwiperOptions, Swiper, Navigation } from "swiper";


//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';

// install Swiper modules
SwiperCore.use([Navigation]);


@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.css']
})
export class NetworkComponent implements OnInit {
  private ngUnsubscribe = new Subject<void>();


	 config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 31,
	loop: true,
    navigation: true,
    centeredSlides: true,
    initialSlide:1,
    hashNavigation: true,
    pagination: false,
    scrollbar: false,
    breakpoints: {
		767: {
		  slidesPerView: 'auto',
		  spaceBetween: 20,
		},
	}
  };
	Networks: any | [];
	Assets: any | [];
	NtwkAssets: any | [];
	Content: any | [];
	ntwk=null

	
  constructor(public PS:ProfileService, public AS: AssetsService, public CS: ContentManagerService, public route: ActivatedRoute, private router: Router,private location: Location) { }

  ngOnInit(): void {
	  this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
		  this.ntwk=params.get('ntwk'); 
	  });
	  this.AS.updateAssets(0);
	  this.CS.getNetworks().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Networks=data;console.log('network ', this.Networks);
	  });
	  this.AS.getAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		 // this.Assets=this.getNetworkAssets(data);
		  //console.log('assets ', this.Assets);
		  this.Assets=data;
		  //this.getFilteredAssets();
		  
	  });
	  this.CS.getContent().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Content=data; console.log('content', this.Content);
	  });
  }
	
  ngOnDestroy()  {
	this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
  }
	launchVid(id){
		this.CS.homePreviewId=id;
		this.CS.homePreviewOpen=true;
	}
	getNtwkId(){
		var id=null;
		var that=this;
		this.Networks.forEach(function(obj){
			if(obj.network_short==that.ntwk){
				id=obj.network_id;
			}
		});
		return id;
	}
	getNetworkAssets(data){
		var id=this.getNtwkId();
		var arr=[];
		var intarr=[];
		data.forEach(function(obj){
			obj.networks.forEach(function(obj2){
				if(obj2.network_id==id && obj.Status==null){
					if(obj.asset_type=="Network Assets"){
						intarr.push(obj);
					}else{
						obj.asset_name=obj.asset_name.replace('-','<br>');
						arr.push(obj);						
					}
				}
			});
		});
		return arr;
	}
	

}
