import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';
import { AssetsService } from '../../services/assets.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { S3UploadService } from '../../services/s3-upload-service.service';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['../admin.component.css']
})
export class AssetsComponent implements OnInit {
private ngUnsubscribe = new Subject<void>();
	current_status="active";
	current_asset: any |[];
	Assets: any |[];
	Profile:any |[];
	role:any |[];
	select_open=false;
	propertyName="created_date";
	reverse="desc";
	 currentDate: Date = new Date(); 
  constructor(public S3S:S3UploadService, public CS: ContentManagerService, public PS: ProfileService, public ADM: AdminService, public AS: AssetsService,  public router: Router){ }

  ngOnInit(): void {
	  console.log('started');
	  this.PS.getRole().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.role = data; 
		  if(this.role!=='admin'){
			  this.router.navigate(['/home']);
		  }

	  });
	  this.ADM.updateAdmin();
	    //this.Profile=this.PS.profileObj.getValue();
	   this.ADM.getAdminAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Assets = data; console.log('assets', this.Assets);
	  });
	 
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	toggleSelectOpen(){
		this.select_open=!this.select_open;
	}
	sortBy(field){
		console.log('clicked'+field);
	  if(this.propertyName!==field){
		  this.propertyName=field;
		  this.reverse="asc";
	  }else if(this.reverse=="asc"){
		  this.reverse="desc";
	  }else{
		  this.reverse="asc";
	  }
  }
	selectOption(val){
		this.current_status=val;
	}
	editAsset(asset){
		this.current_asset=asset;
		this.clearAssetForm();
		//open modal
	}
	createAsset(){
		
		this.current_asset=[];
		this.clearAssetForm();
		console.log(this.current_asset);
		//open modal new
	}
	clearAssetForm(){
		this.S3S.adminUploadsObj.next(null);
		this.S3S.uploads=[];
		this.S3S.uploads_assets=[];
		this.S3S.uploads_thumbs=[];
		this.S3S.uploads_preview=[];
		this.ADM.viewAsset=true;
	}
	viewActivity(status){
		alert('inprogress');
	}
	processedAssets(){
		var arr=[];
		var that=this;
		this.Assets.forEach(function(obj){
			obj.downloads=0;obj.inqueue=0;obj.removed=0;obj.pending=0;
			var enddate= new Date(obj.asset_end);
			if(obj.path==null || obj.active==0 || obj.active==null){
				obj.status="unpublished";
			}else if(enddate.getTime()<that.currentDate.getTime()){
				obj.status="expired";
			}else{
				obj.status="active";
			}
			obj.status=obj.library_status;
			obj.activity.forEach(function(obj2){
				if(obj2.status=="DOWNLOADED"){
					obj.downloads=obj2.activity;
				}else if(obj2.status=="PENDING"){
					obj.pending=obj2.activity;
				}else if(obj2.status=="REMOVED"){
					obj.removed=obj2.activity;
				}else if(obj2.status=="INQUEUE"){
					obj.inqueue=obj2.activity;
				}
			});
			arr.push(obj);
			//obj.downloads=0;obj.inqueue=0;obj.removed=0;
			//if(obj.assets){
			//	obj.assets.forEach(function(obj2){
			//		if(obj2.status=="DOWNLOADED"){obj.downloads=obj2.assets;}
			//		if(obj2.status=="IN-QUEUE"){obj.inqueue=obj2.assets;}
			//		if(obj2.status=="REMOVED"){obj.removed=obj2.assets;}
			//	});
			//}
		//	if(that.current_status="all"){
		//		arr.push(obj);	
		//	}else{
		//		if(obj.status==that.current_status){
			//		arr.push(obj);
		//		}
		//	}
		});
		return arr;
	}
}
