        <section class="section-filters section-filters--bottom-offset">
            <div class="shell">
                <h3>Filters:</h3>

                <div class="cols cols--alt cols--bottom-offset">
                    <div class="col col--1of5">
                        <div class="select" [ngClass]="{'is-open':select_open}"  (click)="toggleSelectOpen();">
                            <div class="select__head">
                                <p>{{current_status ? 'Status: '+ current_status: 'By Status'}}</p>
                            </div><!-- /.select__head -->

                            <div class="select__list" (mouseleave)="toggleSelectOpen();">
                                <ul>
                                   
									<li (click)="selectOption('active');">Active</li>

                                    <li (click)="selectOption('expired');">Expired</li>

                                    <li (click)="selectOption('unpublished');">Unpublished</li>
                                </ul>
                            </div><!-- /.select__list -->
                        </div><!-- /.select -->
                    </div><!-- /.col-/-1of5 -->
                </div><!-- /.cols -->
            </div><!-- /.shell -->
        </section><!-- /.section-filters -->
        

        <section class="section-created-assets">
            <div class="shell shell--size-4">
                <div class="section__inner">
                    <div class="section__head">
                        <h4>Manage Assets</h4>

                        <a (click)="createAsset()" class="btn section__btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <rect data-name="icons/add background" width="20" height="20" fill="none"></rect>
                              <rect data-name="Box 24pt" width="20" height="20" fill="none"></rect>
                              <path data-name="Icon Style" d="M7.792,7.5h6.375V9.167H7.792v7.5H6.375v-7.5H0V7.5H6.375V0H7.792Z" transform="translate(1.417 1.667)" fill="currentColor"></path>
                            </svg>

                            <span>
                                Add New Asset
                            </span>
                        </a>
                    </div><!-- /.section__head -->

                    <div class="section__body">
                        <div class="table-assets">
                            <table>
                                <colgroup>
                                    <col class="table__col--size-1">

                                    <col class="table__col--size-1">

                                    <col class="table__col--size-1">
									
									<col class="table__col--size-1">
									<col class="table__col--size-3">
									<col class="table__col--size-1">
									<col class="table__col--size-1">
									<col class="table__col--size-1">
									<col class="table__col--size-1">
									<col class="table__col--size-1">
									<col class="table__col--size-1">
									<col class="table__col--size-1">
									<col class="table__col--size-1">
									<col class="table__col--size-1">


                                 <!--   <col class="table__col--size-3">

                                    <col class="table__col--size-4">

                                    <col class="table__col--size-5">

                                    <col class="table__col--size-4">

                                    <col class="table__col--size-6">

                                    <col class="table__col--size-8">

                                    <col class="table__col--size-9">-->
                                </colgroup>

                                <thead>
                                    <tr>
                                        <th></th>
										 <th></th>

                                        <th>
                                            <a (click)="sortBy('status')" >Status<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>
										
                                        <th>
                                            

                                            <a (click)="sortBy('asset_id')" >Asset ID<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th >
                                            

                                            <a (click)="sortBy('asset_name')" >Asset Name<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th>
                                            
                                            

											<a (click)="sortBy('asset_type')" >Asset Type<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th>
                                            

                                            <a (click)="sortBy('last_updated')" >Last Updated<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th>
                                            

                                            <a (click)="sortBy('asset_start')" >Asset Start<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th>
                                            

                                            <a (click)="sortBy('asset_end')" >Asset End<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th>
                                            

                                            <a (click)="sortBy('campaign')" >Campaigns<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>
                                        <th>
                                            

                                            <a (click)="sortBy('network')" >Networks<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>
                                        <th>
                                            

                                            <a (click)="sortBy('downloads')" >Downloads<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>
										<th>
											
											<a (click)="sortBy('pending')" >Download Pending<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
										</th>

										<th>
                                            

                                            <a (click)="sortBy('inqueue')" >In-Queue<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>
										<th>
                                            

                                            <a (click)="sortBy('removed')" >Abandons<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                    

                                    </tr>
                                </thead>
                                
                                <tbody>
									<ng-container *ngFor="let asset of processedAssets() | statusFilter: 'status': current_status | sortBy: reverse : propertyName">
										<tr >
											<td>
												<a (click)="editAsset(asset);" class="btn btn--edit">
													<svg id="Admin_no_text" data-name="Admin no text" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 35 35" fill="currentColor">
														<g id="Edit" transform="translate(9 9)">
															<path id="Shape" d="M.379,16H.374A.37.37,0,0,1,.11,15.89.375.375,0,0,1,0,15.62L.042,12a.375.375,0,0,1,.109-.261L11.782.11a.374.374,0,0,1,.53,0l3.577,3.578A.384.384,0,0,1,16,3.955a.372.372,0,0,1-.11.265L4.261,15.847A.372.372,0,0,1,4,15.956ZM9.66,3.292h0L.79,12.161.754,15.244l3.083-.035,8.87-8.869L9.66,3.292ZM12.046.906h0L10.188,2.762,13.236,5.81l1.858-1.856L12.046.906Z" transform="translate(1 0)"></path>
														</g>
													</svg>
												</a>
											</td><td>
												<a href="https://hallmarkmediahub.com/library/{{asset.asset_id}}/asset" target="_blank" class="btn btn--edit">
													<svg _ngcontent-isk-c129="" xmlns="http://www.w3.org/2000/svg" id="search" width="32" height="32" viewBox="-4 -4 32 32"><path xmlns="http://www.w3.org/2000/svg" d="M7 17q-2.075 0-3.537-1.463Q2 14.075 2 12t1.463-3.538Q4.925 7 7 7h3q.425 0 .713.287Q11 7.575 11 8t-.287.712Q10.425 9 10 9H7q-1.25 0-2.125.875T4 12q0 1.25.875 2.125T7 15h3q.425 0 .713.287.287.288.287.713t-.287.712Q10.425 17 10 17Zm2-4q-.425 0-.712-.288Q8 12.425 8 12t.288-.713Q8.575 11 9 11h6q.425 0 .713.287.287.288.287.713t-.287.712Q15.425 13 15 13Zm5 4q-.425 0-.712-.288Q13 16.425 13 16t.288-.713Q13.575 15 14 15h3q1.25 0 2.125-.875T20 12q0-1.25-.875-2.125T17 9h-3q-.425 0-.712-.288Q13 8.425 13 8t.288-.713Q13.575 7 14 7h3q2.075 0 3.538 1.462Q22 9.925 22 12q0 2.075-1.462 3.537Q19.075 17 17 17Z"></path></svg>
													
													
													
<!--													<img src="assets/images/svg/search--large.svg" alt="">-->
												</a>
											</td>

											<td class="table__cell--center">{{asset.status}}</td>
											<td>{{asset.asset_id}}</td>
											<td>{{asset.asset_name}}</td>
											<td>{{asset.asset_type}}</td>
											<td>{{asset.last_updated | date: 'shortDate'}}</td>
											<td>{{asset.asset_start | date: 'shortDate'}}</td>
											<td>{{asset.asset_end | date: 'shortDate'}}</td>
											<td ><p *ngFor="let camp of asset.campaign">{{camp.campaign_name}}</p></td>
											<td ><p *ngFor="let net of asset.networks" >{{net.network_full}}</p></td>
											<!--<td><a (click)="viewActivity('downloads')">{{asset.downloads}}</a></td>
											<td><a (click)="viewActivity('inqueue')">{{asset.inqueue}}</a></td>
											<td><a (click)="viewActivity('removed')">{{asset.removed}}</a></td>-->
											<td *ngIf="asset.downloads>0">
												<a href="https://api.hallmarkmediahub.com/lib/reports/downloads.php?id={{asset.asset_id}}&status=DOWNLOADED" target="_blank">{{asset.downloads}}</a>
											</td>
											<td *ngIf="asset.downloads==0">{{asset.downloads}}</td>
											<td *ngIf="asset.pending>0">
												<a href="https://api.hallmarkmediahub.com/lib/reports/downloads.php?id={{asset.asset_id}}&status=PENDING" target="_blank">{{asset.pending}}</a>
											</td>
											<td *ngIf="asset.pending==0">{{asset.pending}}</td>
											<td *ngIf="asset.inqueue>0">
												<a href="https://api.hallmarkmediahub.com/lib/reports/downloads.php?id={{asset.asset_id}}&status=IN-QUEUE" target="_blank">{{asset.inqueue}}</a>
											</td>
											<td *ngIf="asset.inqueue==0">{{asset.inqueue}}</td>
											<td *ngIf="asset.removed>0">
												<a href="https://api.hallmarkmediahub.com/lib/reports/downloads.php?id={{asset.asset_id}}&status=REMOVED" target="_blank">{{asset.removed}}</a>
											</td>
											<td *ngIf="asset.removed==0">{{asset.removed}}</td>

										</tr>
									</ng-container>
                                </tbody>
                            </table>
                        </div><!-- /.table-assets -->          
                    </div><!-- /.section__body -->
                </div><!-- /.section__inner -->
            </div><!-- /.shell shell--size-3 -->
        </section><!-- /.section-created-assets -->
<app-alert></app-alert>
<app-asset-detail [asset]="current_asset" *ngIf="ADM.viewAsset"></app-asset-detail>
