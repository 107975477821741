
<section class="section-schedule">
	<div class="shell">
		<ng-container *ngIf="Programs?.length>0">
			<div class="schedule " *ngFor="let P of Programs; let i=index"
			[ngClass]="i==CS.active_ntwk? 'program_active':'program_inactive'">
				<ul class="schedule__list" >
					<li class="schedule__info">
						<img src="assets/images/svg/tv.svg" alt="">
						now playing

					</li>

					<li class="schedule__date">
						<em>{{weekday}}</em> {{ showtime}}
					</li>


					<li>

						<p>
							<span><strong>{{P.network_full}}</strong> | {{P.programName}} - {{P.titleShortSynopsis}}</span>
						<!--	<span><strong>{{N.network_full}}</strong> | Program Information Coming Soon</span> -->
						</p>

							<a href="{{P.schedule_link}}" class="link link--continue schedule__link">View Full Schedule</a>

					</li>
				</ul><!-- /.schedule__list -->
			</div><!-- /.schedule -->	
		</ng-container>
		<ng-container *ngIf="!Programs">
			<div class="schedule " *ngFor="let N of Networks; let i=index"
			[ngClass]="i==CS.active_ntwk? 'program_active':'program_inactive'">
				<ul class="schedule__list" >
					<li class="schedule__info">
						<img src="assets/images/svg/tv.svg" alt="">
						now playing

					</li>

					<li class="schedule__date">
						<em>{{weekday}}</em> {{ showtime}}
					</li>


					<li>

						<p>
						   <span><strong>{{N.network_full}}</strong> | Program Information Coming Soon</span>
						</p>

							<a href="{{N.schedule_link}}" class="link link--continue schedule__link">View Full Schedule</a>

					</li>
				</ul><!-- /.schedule__list -->
			</div><!-- /.schedule -->	
		</ng-container>
	</div><!-- /.shell -->
</section><!-- /.section-schedule -->
