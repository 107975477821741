import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusFilter'
})
export class StatusFilterPipe implements PipeTransform {
    transform(items: any[], field : string, value : string): any[] {  
      if(field=='review_status'){
		  if (!items) return [];
      	  if (!value || value.length == 0) return items;
		  if (value == 'all') return items;
		  if (value == 'open') return items.filter(it => it['request_approved']==null && it['request_denied']==null);
		  if (value == 'unreviewed') return items.filter(it => it['customs_detail'].length==1);
		  if (value == 'closed') return items.filter(it => it['request_approved']!==null || it['request_denied']!==null);
		  
		  
	  }else{		  
		if (!items) return [];
      	if (!value || value.length == 0) return items;
		//console.log('value is'+value+ ' and field is'+ field);
      	return items.filter(it => it[field].toLowerCase().indexOf(value.toLowerCase()) !=-1);
	  }
    }
}
	