<div class="modal" [ngClass]="{ 'is-open': CS.loginOpen }">
	<div class="modal__inner" [ngClass]="{'modal--bottom-spacing-1': CS.loginState=='pending' ||CS.loginState=='fail'  }">
		<div class="form form--modal" *ngIf="CS.loginState!=='pending' && CS.loginState!=='fail'">
			
			<form name="loginForm" #loginForm="ngForm" >	

				<header class="form__head" *ngIf="CS.loginState=='begin'">
					
					<h2>Log in</h2>
					<h3>Fill in your email to create or access your account.</h3>
				</header><!-- /.form__head -->

				<header class="form__head" *ngIf="CS.loginState=='reminder'">
					<h2>Forgot your password?</h2>

					<h3>Enter your email address below, and if an account exists, we’ll resend your credentials.</h3>
				</header><!-- /.form__head -->

				<div class="alert alert--warning form__alert" *ngIf="unregistered">
					<span class="alert__image"></span>
				     <p>We don’t recognize this email address.<br>Please re-enter or <a routerLink="/register" class="link alert__link">Create an account.</a></p>
				</div><!-- /.alert form__alert -->

				<div class="form__body">
					
					<div class="form__row" >
						<label for="field-email" class="form__label">Email Address</label>

						<div class="form__controls">
							<input type="text" class="field" name="field-email" id="field-email" 
								   email required #email="ngModel"
								   (focus)="unregistered=false;" (change)="checkEmail()" [(ngModel)]="login.email">
							<p class="form__error" *ngIf="email.invalid && email.touched">Please enter a valid email address.</p><!-- /.form__error -->

							<!--<div class="checkbox">
								<input type="checkbox" id="checkbox_logged" checked="checked">

								<label for="checkbox_logged">Keep me logged in.</label>
							</div><!-- /.checkbox -->
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					<div class="form__row" *ngIf="CS.loginState=='begin' && !unregistered && !email.invalid">
						<label for="field-password" class="form__label">Password*</label>

						<div class="form__controls">
							<input [type]="showPassword ? 'text' : 'password'" class="field" name="field-password" id="field-password" [(ngModel)]="login.password" #password="ngModel">

							<a (click)="CS.loginState='reminder'" class="link form__link">Forgot your password?</a>

							<a (click)="showPassword=!showPassword;" class="form__filter">{{ showPassword ? 'Hide' : 'Show'}}</a>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
				</div><!-- /.form__body -->

				<div class="form__actions modal__actions" *ngIf="CS.loginState=='begin'">
					<a (click)="submit_login_form(loginForm.form)"  class="btn form__btn" value="Log in" name="login">Log In</a>

			<a routerLink="/register" class="link link-2">Create a New Account</a>
		
				
					<!--<input routerLink="/register" class="btn form__btn" type="submit" value="New Account" id="register">-->
				</div><!-- /.form__actions -->
				
				<div class="form__actions" *ngIf="CS.loginState=='reminder'">
					<input type="submit" (click)="reset_password()" class="btn form__btn" value="Password Reminder" name="password-reset">
				</div><!-- /.form__actions -->
			</form>
		</div><!-- /.form -->
		
		<div *ngIf="CS.loginState=='pending' || CS.loginState=='fail'">
			<div class="modal__entry" *ngIf="CS.loginState=='pending'">
				<h2>Looks like your registration is still under review.</h2>
				<h3>Please check back again in 24hrs or if it has been more than 3 Business days since your registration, please reach out to your Hallmark Media Rep for assistance.</h3>
			</div><!-- /.modal__entry -->
			<div class="modal__entry" *ngIf="CS.loginState=='fail' ">
				<h2 *ngIf="!reminderfail">This Email/Password combination is not valid.</h2>
				<h2 *ngIf="reminderfail">This Email Address does not appear in our records</h2>
				<h3>If you think you’ve arrived here in error, go back and re-enter your email. Otherwise, you must request enrollment. </h3>
			</div><!-- /.modal__entry -->

			<div class="modal__actions" *ngIf="CS.loginState=='fail' ">
				<a routerLink="/register" class="btn btn--size-3">Request Enrollment</a>
				<a (click)="CS.loginState='begin'" class="link">Go back</a>
			</div><!-- /.modal__actions -->

		</div>
		<div *ngIf="CS.loginState=='error'">
			<div class="modal__entry">
				<h2>We've encountered a submission error.</h2>
				<h3>Please try again or reach out to <a href="mailto:support@hallmarkmediahub.com">customer support</a>. </h3>
			</div><!-- /.modal__entry -->

			<div class="modal__actions" >
				<a (click)="CS.loginState='begin'" class="link">Go back</a>
			</div><!-- /.modal__actions -->

		</div>

		<a (click)="CS.loginEnd()" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="">
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->



