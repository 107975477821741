import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-queue-footer',
  templateUrl: './queue-footer.component.html',
  styleUrls: ['./queue-footer.component.css']
})
export class QueueFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
