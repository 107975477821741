import { Component, Input, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';


@Component({
  selector: 'app-audience-table',
  templateUrl: './audience-table.component.html',
  styleUrls: ['./audience-table.component.css']
})
export class AudienceTableComponent implements OnInit {
	 @Input() Recipients: any | [];
	 @Input() Status: string;
	propertyName="account_id";
	reverse='asc';
	allSelected=false;
	
	//accountFilter={'promo_yr': this.current_promo};
	
	
	
  constructor(
	public AS:AdminService
	) { }

  ngOnInit(): void {
  }
  sortByAccount(field){
	  if(this.propertyName!==field){
		  this.propertyName=field;
		  this.reverse="asc";
	  }else if(this.reverse=="asc"){
		  this.reverse="desc";
	  }else{
		  this.reverse="asc";
	  }
  }
	clickActive(){
		this.allSelected=false;
	}
	selectAll(){
		this.allSelected=!this.allSelected;
		 var that = this;
		this.Recipients.forEach(function(value,key){
			value.receive_email=that.allSelected;
		});
	}
	
	
	
	
}
