<div class="wrapper">
	
	<app-header *ngIf="!fullscreen"></app-header>
	<app-queue-summary *ngIf="CS.summary"></app-queue-summary>
	<!--
	<div class="main" role="main" [ngClass]="{'main--login':fullscreen}" 
		 [ngStyle]="{'background-image':fullscreen? '' : '' }">-->
		
			<router-outlet></router-outlet>
		
		
<!--	</div><!-- /.main -->
	
	<app-footer *ngIf="!fullscreen"></app-footer>
	
</div>