import { Component, OnInit,Input, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';
import { S3UploadService } from '../../services/s3-upload-service.service';



@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
	private ngUnsubscribe = new Subject<void>();
	//@Input() Profile: any |[];
  	Profile: any |[];
	pid=null;
	select_open=false;
	Assets: any |[];
	History: any |[];
	Results: any |[];
	Customizations: any |[];
	showPassword=false;
	verifying=false;
	pwnumb=false;
	pwchar=false;
	pwsymb=false;
	pwcap=false;
	notpw=false;
	pw_match_fail=false;
	pwords={
		repeat:null,
		newpw:null,
		oldpw:null,
	}
	propertyName="asset_history_id";
	reverse="desc";
	Files: any | [];
  	Files_assets: any | [];
  file_upload_error=false;
  Error: any |[];
	
	

  constructor(public PS:ProfileService,public AS:AssetsService, public ADM:AdminService, public CS: ContentManagerService,public route: ActivatedRoute,public S3S: S3UploadService, public router: Router) { }

  ngOnInit(): void {
	//  console.log('user', this.User);
	  //get id variable
	  this.route.paramMap.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
		  if(params.get('id')){
			  this.ADM.detail_id=params.get('id');
			  this.ADM.updateAdmin(this.ADM.detail_id);
			  this.ADM.updateUserDetail();
			  this.AS.getHistorybyID(this.ADM.detail_id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
				this.History=data; 
			  });
		  }else{
			  this.ADM.updateAdmin(this.ADM.detail_id);
			  this.ADM.updateUserDetail();
		  }
	  
		  this.ADM.getUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
			  console.log('mkdata',data);
			  console.log('mkid',this.ADM.detail_id);
			  this.Profile = this.processUser(data); 
			  console.log('mkprofile', this.Profile);
		  });
		  this.AS.getAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.Assets=data;});	
	  	  
		  console.log(this.ADM.detail_id);
		  this.ADM.getUserResults().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		   this.Results=this.setUpExpand(data);
			  console.log('results', this.Results); 
		  });
		  this.ADM.getUserCustoms().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		   this.Customizations=this.setUpExpand(data);
			  console.log('customs', this.Customizations); 
		  });
	  });
	  
	  
  }
	uploadFiles(event, el) {
	    
		var files = event.target.files;
		console.log('started', files);
		console.log('started2', files.length);
        this['Files_'+el] = files;
		console.log('started3', this['Files_'+el].length);
		var that=this;

      if (files && files.length > 0){ 
		   this['Files_'+el][0].pathway=el+'/'; //setting S3 Path
			  //fileEvent($event,'uploads')
              this.S3S.uploadSingleFile(this['Files_'+el][0], el);
      }
  };
	setUpExpand(data){
		if(data!=="No data"){
			console.log('dt', data);
			data.forEach(function(obj){
				obj.expand=false;
			});
		}else{
			data=[];
		}
		return data
	}
	processUser(data){
		var that=this;
		var target=[];
		data.forEach(function(obj){
			if(obj.profile_id==that.ADM.detail_id){
				target=obj;
			}
		});
		return target;
	}
	toggleExpand(item){
		if(!item.expand){
			this.Customizations.forEach(function(obj){
				obj.expand=false;
			});
			this.Results.forEach(function(obj){
				obj.expand=false;
			});
			item.expand=true;
		}else{
			item.expand=false;
		}
	}
	editUser(user){
		this.ADM.current_user_detail=user;
		//console.log('user is', user);
		this.PS.editProfile('admin');
		this.router.navigate(['admin/users/'+user.profile_id]);
		//this.CS.alert_message="Not yet available.";
		//this.CS.showAlert=true;
	}
	ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	sortBy(field){
		console.log('clicked'+field);
	  if(this.propertyName!==field){
		  this.propertyName=field;
		  this.reverse="asc";
	  }else if(this.reverse="asc"){
		  this.reverse="desc";
	  }else{
		  this.reverse="asc";
	  }
  }
	toggleSelectOpen(){
		this.select_open=!this.select_open;
	}
	select_option(val){
		this.Profile.account_type=val;
	}
	keyPressNumbers(event) {
		var charCode = (event.which) ? event.which : event.keyCode;
		// Only Numbers 0-9
		if ((charCode < 48 || charCode > 57)) {
		  event.preventDefault();
		  return false;
		} else {
		  return true;
		}
	  }	
	
	passwordCheck(e){
		//var charCode = (event.which) ? event.which : event.keyCode;
		  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		this.pwsymb=specialChars.test(this.pwords.newpw);  
		this.pwnumb=/\d/.test(this.pwords.newpw);
		this.pwchar=this.pwords.newpw==null? false : this.pwords.newpw?.length>=8? true : false;
		this.pwcap=/[A-Z]/.test(this.pwords.newpw);
		
	}
	verifyPw(){
		if(this.pwsymb && this.pwnumb && this.pwchar && this.pwcap && (this.pwords.newpw==this.pwords.repeat)){
			return true;
		}else{
			return false;
		}
		
	}
	checkPw(){
		this.notpw= this.pwords.oldpw!==this.Profile.password;
	}
	checkMatch(){
		this.verifying=true;
		this.pw_match_fail=(this.pwords.newpw!==this.pwords.repeat);
	}
	submitProfile(prf, frm){
		console.log("form", frm);
		console.log('profile',prf);
		this.PS.edit_submitted=true;
		if(this.pwords.newpw!==this.pwords.repeat){
			this.pw_match_fail=true;
		}else if(frm.valid){
			if(this.PS.edit_state=="pw"){
				prf.old_password=this.pwords.oldpw;
				prf.new_password=this.pwords.newpw;
			}else if(this.PS.edit_state=="eml"){
				prf.new_email=prf.email;
			}
			console.log(prf);
			var id = prf.profile_id;
			var dt=[prf];
			var json_data = [];
			json_data.push([prf]);

				
			
				this.PS.updateProfile(id,prf).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
				  (response) => {
					  console.log('response', response);
					  var errcheck = JSON.stringify(response);

					  if(errcheck.includes('Unknown error')|| response[0]=='There was en error' ){
						  this.CS.alert_message="We've encountered a submission error.  Please try again or reach out to customer support.";
						  this.CS.showAlert=true;
					  }else if(response[0] == "new_email"){
						  this.PS.eml_in_use=true;
					   }else if(response[0] == "password"){
						  this.notpw=true;
					  }else if(response[0] !== "password"){
						  console.log('success');
						  //this.resetProfile();
						  this.PS.closeModals();
					  }
				  }
				);
				
				this.PS.edit_submitted=false;
			}

	}
	approveUser(id){
		this.ADM.approveUser(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
		   var strcheck = JSON.stringify(data);
		   if(strcheck.includes('uccess') ){
				console.log('succeeded');
				this.CS.alert_message = "Success";
				this.CS.showAlert = true;
				
						  this.PS.closeModals();
			   //this.ngOnInit();
		   }else{
				console.log('issue');
				this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>technical support</a>";
				this.CS.showAlert = true;
		   }
	   });	
	}
	denyUser(id){
		this.ADM.denyUser(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
		   var strcheck = JSON.stringify(data);
		   if(strcheck.includes('uccess') ){
				console.log('succeeded');
			    this.CS.alert_message = "Success";
				this.CS.showAlert = true;
			  this.PS.closeModals();
//				this.ngOnInit();
		   }else{
				console.log('issue');
				this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>technical support</a>";
				this.CS.showAlert = true;
		   }
	   });	
	}
	setStage(val){
		this.AS.getHistory().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  	this.History=data; console.log('history', this.History);
			this.processHistory();
	  	  });
		
		this.PS.admin_stage=val;
	}
	processHistory(){
		this.History.forEach(function(obj){
			if(obj.results_denied>obj.results_approved){
				obj.results_status="Denied";
				obj.results_date=obj.results_denied;
			}else if(obj.results_approved>'2022-01-01'){
				obj.results_status="Approveded";
				obj.results_date=obj.results_approved;
			}else if(obj.results_submitted>'2022-01-01'){
				obj.results_status="Submitted";
				obj.results_date=obj.results_submitted;
			}else{
				obj.results_status="Unsubmitted";
				obj.results_date=false;
			}
		});				
	}
	
	approveCustom(item){
		//update the detail set staus to approved  send the email
	}
	submitComment(item, approved){
		console.log('here', item);
		item.approved=approved;
		item.reviewed_by=this.PS.profile_id;
		if(item.reviewer_comment=="Add response and/or upload file and click submit below"){
			item.reviewer_comment=null;
		}
		if(this.Files_assets){
			item.support_file=this.S3S.uploads_assets[0].url
		}
		var json_data=[item];
		this.ADM.putUserCustoms(json_data, item.reviewed_by).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
		   var strcheck = JSON.stringify(data);if(strcheck.includes('uccess') ){
				console.log('succeeded');
				this.CS.alert_message = "Success";
				this.CS.showAlert = true;
			   this.ngOnDestroy();
			   this.ngOnInit();

		   }else{
				console.log('issue');
				this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>technical support</a>";
				this.CS.showAlert = true;
		   }
	   });	
	}
	clearComment(item){
		//clear the item
		//set expand to false
	}


}
