import { Component, OnInit } from '@angular/core';

import { ContentManagerService } from '../../services/content-manager.service';


@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  constructor(public CS: ContentManagerService) { }

  ngOnInit(): void {
  }


}
