import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';
import { EmailManagementService } from '../../services/email-management.service';


@Component({
  selector: 'app-email-overview',
  templateUrl: './email-overview.component.html',
  styleUrls: ['./email-overview.component.css']
})
export class EmailOverviewComponent implements OnInit {
   breadcrumbs: any |[];
  request_account=false;
  current_promo_yr=2021;
  email_status="draft";
	private ngUnsubscribe = new Subject<void>();

	
  public AdminEmails: any | [];


  constructor(
    public PS: ProfileService, 
	 public AS: AdminService,
	 public EMS: EmailManagementService,
	 public route: ActivatedRoute,
	 public router: Router

		 ) { }

  ngOnInit(): void {
	//   this.breadcrumbs=this.route.snapshot.data;
	//  this.PS.adminView=true;
	  
	  this.EMS.getEmailTemplates().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.AdminEmails=data;});
	   
  }
  	ngOnDestroy(){
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
	set_status(status){
		this.email_status=status;	  
	}
	create_new_email_template(adhoc){
		this.EMS.create_email_template_admin = true;
		this.EMS.original_template="";
		this.EMS.current_template_id=null;
		if(adhoc){
			this.router.navigate(['admin/email-detail/0']);
		}else{
			this.router.navigate(['admin/email-newsletter-detail/0']);
		}		
	}
}

