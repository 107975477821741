<app-alert></app-alert>
<div class="main__body">


	<section class="section section__audience">
		<div class="section__head section__head__audience">
			<div class="title">
				<h1>Email Audience Management</h1>
			</div><!-- /.title -->
		</div><!-- /.section__head -->
		<!--<app-filter-promo_yr></app-filter-promo_yr>-->
	</section>
  
  <section class="section">
    <div class="section__body">
		<div class="overview-cta">
			<a routerLink="/admin/emails" class="btn btn--purple">Back to Email List</a>
			<a routerLink="/admin/email-detail/{{EMS.current_template_id}}"  class="btn btn--purple">Back to Email Detail</a>
     </div>
		
		

      <div class="tabs">
		  <div class="tabs__head">
			<nav class="tabs__nav">
			  <ul>
				  <li class="current--alt"><a>RECIPIENTS - {{email_template.adhoc>0 ? 'Ad Hoc Email': 'Newsletter'}}</a></li>
				 
				</ul>
			</nav><!-- /.tabs__nav -->
		  </div><!-- /.tabs__head -->

		  <div class="tabs__body">
			<div class="tab section__body current">
				<div class="box">
					<header class="box__head" style="background-image: url(assets/images/temp/background-img-violet.png);">

					  <span class="h3">EMAIL AUDIENCE</span><a  (click)="admin_send_accounts_email(EMS.current_template_id, AdminRecipients);" class="btn btn--purple btn--medium btn__launch">Launch Email</a>
						<br><span clss="h4">Template Name:{{email_template.template_name}}</span>
						<br><span clss="h4">SUBJECT:{{email_template.email_subject}}</span>

			
					</header><!-- /.box__head -->
					<div *ngIf="email_template.adhoc"><button class="btn" (click)="AS.showFilter=true">Add Filters</button></div>
					<app-filter [filter_assets]="Assets" *ngIf="email_template.adhoc && AS.showFilter"></app-filter>    

					<app-audience-table [Recipients]="AdminRecipients" ></app-audience-table>
				</div>
			</div><!-- /.tab -->

		  </div><!-- /.tabs__body -->
		</div>
    </div><!-- /.tabs -->
</section><!-- /.section-faq -->

</div>
