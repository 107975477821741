import { Component,Input, OnInit } from '@angular/core';
import { AssetsService } from '../../services/assets.service';


@Component({
  selector: 'app-asset-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {
	
  constructor(public AS: AssetsService) { }

  ngOnInit(): void {
  }
	
}
