import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import { AuthService } from './services/auth.service';
import { AuthService as AuthGuard } from './services/auth.service';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { NetworkComponent } from './components/network/network.component';
import { AssetLibraryComponent } from './components/asset-library/asset-library.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { MyActivityComponent } from './components/my-activity/my-activity.component';
import { AssetQueueComponent } from './components/asset-queue/asset-queue.component';
import { UsersComponent } from './admin/users/users.component';
import { UserDetailComponent } from './admin/user-detail/user-detail.component';
import { AssetsComponent } from './admin/assets/assets.component';
import { ContentComponent } from './admin/content/content.component';
import { ReportsComponent } from './admin/reporting/reports.component';
import { SupportComponent } from './components/support/support.component';
import { AudienceOverviewComponent } from './admin/communication/audience-overview.component';
import { EmailDetailComponent } from './admin/communication/email-detail.component';
import { EmailNewsletterDetailComponent } from './admin/communication/email-newsletter-detail.component';
import { EmailOverviewComponent } from './admin/communication/email-overview.component';
import { InfopageComponent } from './components/infopage/infopage.component';
import { ResourcesComponent} from './components/infopage/resources.component';
import { CustomReqsComponent } from './admin/custom-reqs/custom-reqs.component';





const routes: Routes = [
	{ path: '', redirectTo: '/home', pathMatch: 'full'},
	{ path: 'login', redirectTo: '/home/login', pathMatch: 'full' },
	{ path: 'logout', redirectTo: '/home/logout', pathMatch: 'full' },
	{ path: 'home/:loginstate/:asset', component: LandingComponent },
	{ path: 'home/:loginstate', component: LandingComponent },
	{ path: 'home', component: LandingComponent },
	{ path: 'register', component: RegisterComponent},
	{ path: 'network/:ntwk', component: NetworkComponent, canActivate: [AuthGuard]},
	{ path: 'network',  redirectTo: '/network/hc', pathMatch: 'full'},
	{ path: 'support', component: SupportComponent, canActivate: [AuthGuard]},
	{ path: 'support/:tag', component: SupportComponent, canActivate: [AuthGuard]},
	{ path: 'library/:id/:criteria', component: AssetLibraryComponent, canActivate: [AuthGuard] },
	{ path: 'library', component: AssetLibraryComponent, canActivate: [AuthGuard]},
	{ path: 'info', component: InfopageComponent, canActivate: [AuthGuard]},
	{ path: 'resources', component: ResourcesComponent, canActivate: [AuthGuard]},
	{ path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard]},
	{ path: 'my-activity', component: MyActivityComponent, canActivate: [AuthGuard]},
	{ path: 'asset-queue/:id', component: AssetQueueComponent, canActivate: [AuthGuard]},
	{ path: 'asset-queue', component: AssetQueueComponent,canActivate: [AuthGuard]},
	{ path: 'admin/users/:id', component: UserDetailComponent,canActivate: [AuthGuard]},
	{ path: 'admin/users', component: UsersComponent,canActivate: [AuthGuard]},
	{ path: 'admin/assets/:id/', component: AssetsComponent,canActivate: [AuthGuard]},
	{ path: 'admin/assets', component: AssetsComponent,canActivate: [AuthGuard]},
	{ path: 'admin/content/:id/', component: ContentComponent,canActivate: [AuthGuard]},
	{ path: 'admin/content', component: ContentComponent,canActivate: [AuthGuard]},
	{ path: 'admin/customreqs', component: CustomReqsComponent,canActivate: [AuthGuard]},
	{ path: 'admin/reporting', component: ReportsComponent,canActivate: [AuthGuard]},
	{ path: 'admin/emails', component: EmailOverviewComponent,canActivate: [AuthGuard]},
	{ path: 'admin/email-detail/:id', component: EmailDetailComponent,canActivate: [AuthGuard]},
	{ path: 'admin/email-newsletter-detail/:id', component: EmailNewsletterDetailComponent,canActivate: [AuthGuard]},
	{ path: 'admin/audience', component: AudienceOverviewComponent,canActivate: [AuthGuard]},
	{ path: '**', redirectTo: '/home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
