<div class="modal modal--right modal--scroll is-open">
	<div class="modal__inner">
		<header class="modal__head">
			<div class="modal__title">
				<h2>Your Download Summary</h2>

				<h3><strong>{{Queue?.length}}</strong> items.</h3>
			</div><!-- /.modal__title -->

			<a routerLink="/asset-queue" (click)="closeModal()"  class="btn">View Queue</a>
		</header><!-- /.modal__head -->

		<div class="modal__body">
			<div class="asset asset--preview"   *ngFor="let queue of Queue">
				<div class="asset__aside">
					<figure class="asset__image">
						<img src="{{queue.asset_info[0].thumb}}" alt="{{queue.asset_info[0].asset_name}}" width="195" height="110">
					</figure><!-- /.asset__image -->
				</div><!-- /.asset__aside -->

				<div class="asset__main">
					<h3>{{queue.asset_info[0].asset_name}}</h3>
					<div class="asset__actions">
						<a routerLink="asset-queue/{{queue.history_id}}" class="btn btn--size-7">
							<svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.499" viewBox="0 0 16.5 16.499">
								<path d="M.374,16A.373.373,0,0,1,0,15.62L.042,12a.375.375,0,0,1,.109-.261L11.782.11a.374.374,0,0,1,.53,0l3.577,3.578A.383.383,0,0,1,16,3.955a.373.373,0,0,1-.11.265L4.261,15.847A.373.373,0,0,1,4,15.956L.379,16Z" transform="translate(0.25 0.25)" fill="#582c83" stroke="#582c83" stroke-miterlimit="10" stroke-width="0.5"/>
							</svg>

							Edit
						</a>

						<a (click)="removeAsset(queue);" class="link link--delete">Delete</a>
					</div><!-- /.asset__actions -->
				</div><!-- /.asset__main -->
			</div><!-- /.asset asset-preview -->

		</div><!-- /.modal__body -->

		<a (click)="closeModal()" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="" />
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->
