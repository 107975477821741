import { Component, OnInit } from '@angular/core';
import { AssetsService } from '../../services/assets.service';


@Component({
  selector: 'app-download-success',
  templateUrl: './download-success.component.html',
  styleUrls: ['./download-success.component.css']
})
export class DownloadSuccessComponent implements OnInit {

  constructor(public AS: AssetsService) { }

  ngOnInit(): void {
  }

}
