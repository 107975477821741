<section class="section-form">
	<div #step1 class="shell shell--size-1">
		<div class="form"><!-- [ngClass]="currentStep>3 ? 'form--alt-2': currentStep>2 ? 'form--alt':''"-->
		
			<form name="profileForm" #profileForm="ngForm" novalidate >
			
				<ng-container id="addr" >
					
						<header class="form__head">
							<h3>User: {{Profile.first_name}} {{Profile.last_name}}<br>Status: {{Profile.status}}</h3>
						</header><!-- /.form__head -->
							<div class="form__actions" *ngIf="Profile.status=='pending'">
								<a (click)="approveUser(Profile.profile_id);" class="btn  form__btn">Approve User</a>
								<a (click)="denyUser(Profile.profile_id);" class="btn  form__btn">Deny User</a>
							</div><!-- /.form__actions -->	
										

				
					<div class="form__body" >
						<div class="form__row cols">
							<div class="col--1of3">
								<label for="first-name" class="form__label">First Name</label>

								<div class="form__controls">
									<input type="text" class="field" name="first-name" id="first-name" 
										   [(ngModel)]="Profile.first_name" #fname="ngModel" required>
								<p class="form__error" *ngIf="fname.error">Please complete this field.</p>
								</div><!-- /.form__controls -->
							</div>
							<div class="col--1of3">

								<label for="last-name" class="form__label">Last Name</label>

								<div class="form__controls">
									<input type="text" class="field" name="last-name" id="last-name" 
										   [(ngModel)]="Profile.last_name" #lname="ngModel" required>
								<p class="form__error" *ngIf="lname.error">Please complete this field.</p>
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->
							<div class="col--1of3">
								<label for="phone" class="form__label">Phone</label>

								<div class="form__controls">
									<input type="text" class="field" name="phone" id="phone"  
										   [(ngModel)]="Profile.phone" #phone="ngModel" required>
								<p class="form__error" *ngIf="phone.error">Please complete this field.</p>
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->
						</div>

						<div class="form__row cols">
							<div class="col--1of3">
								<label for="title" class="form__label">Title</label>

								<div class="form__controls">
									<input type="text" class="field" name="title" id="title"  
										   [(ngModel)]="Profile.title" #title="ngModel" >
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->

							<div class="col--1of3">
								<label for="mvpd" class="form__label">Partner</label>

								<div class="form__controls">
									<input type="text" class="field" name="partner" id="partner" 
										   [(ngModel)]="Profile.partner_full" #partner="ngModel" required>
								<p class="form__error" *ngIf="partner.error">Please complete this field.</p>

								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->
							<div class="col--1of3">
								<label for="select-type" class="form__label">Account Type</label>

								<div class="form__controls">
									<div class="select select--gray" id="select-typ" [ngClass]="{'is-open':select_open, 'select_error': Profile.account_type==null}"
							 (click)="toggleSelectOpen();" >
										<div class="select__head">
											<p>{{ Profile.account_type ?  Profile.account_type  : "Select" }}</p>
										</div><!-- /.select__head -->

										<div class="select__list" (mouseleave)="toggleSelectOpen();">
											<ul>
												<li (click)="select_option('Local Ad Sales')">Local Ad Sales</li>
												<li (click)="select_option('Marketing')">Marketing</li>
												<li (click)="select_option('National')">National</li>
												<li (click)="select_option('Enterprise')">Enterprise</li>
											</ul>
										</div><!-- /.select__list -->	
									</div><!-- /.select -->
									<p class="form__error" *ngIf="Profile.account_type==null">Please make a selection.</p>
								</div><!-- /.form__controls -->
							</div>
						
						</div>
							
						
						<div class="form__row cols">
							<div class="col--1of3">
								<label for="fld-email" class="form__label">Email</label>

								<div class="form__controls">
									<input type="text" email class="field" name="fld-email" id="fld-email" 
										   [(ngModel)]="Profile.email" #email="ngModel" required
										   [ngClass]="{'is--error': PS.eml_in_use}" (focus)="PS.eml_in_use=false">
								<p class="form__error" *ngIf="PS.eml_in_use">A profile is already associated with this email.</p>
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->
							<div class="col--1of3">
								<label for="current-password" class="form__label">Current Password</label>
								<div class="form__controls">
									<input [type]="showPassword ? 'text' : 'password'" class="field" name="current-password" id="current-password"
									 [(ngModel)]="pwords.oldpw" #oldpw="ngModel"  required
										   [ngClass]="{'is--error': notpw }"
										   (focus)="notpw=false" 										   
										   >

									<a (click)="showPassword=!showPassword;" class="form__filter">Show</a>
									<p class="form__error" *ngIf="!oldpw.valid  && PS.edit_submitted">This field is required.</p>
									<!-- /.form__error -->
									<p class="form__error" *ngIf="notpw">This is not your current password.</p>
									<!-- /.form__error -->
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->

							

							<div class="col--1of3">
								<label for="field-password" class="form__label">New Password</label>

								<div class="form__controls">
									<input [type]="showPassword ? 'text' : 'password'" class="field" required name="field-password" id="field-password"  [(ngModel)]="pwords.newpw" #newpw="ngModel" (keyup)="passwordCheck($event)"
										   [ngClass]="{'pw_error':(!pwnumb || !pwcap || !pwsymb || !pwchar) && PS.edit_submitted}"
										   >
									<p class="form__error" *ngIf="(!pwnumb || !pwcap || !pwsymb || !pwchar) && PS.edit_submitted">Please enter a valid password</p><!-- /.form__error -->

									<a (click)="showPassword=!showPassword;" class="form__filter is-on">{{ showPassword ? 'Hide' : 'Show'}}</a>

								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->

						</div><!-- /.form__row -->

					</div><!-- /.form__body -->

				</ng-container>
					<div class="form__actions" >
						<input (click)="submitProfile(Profile, profileForm)" class="btn form__btn" value="Save Changes" name="login">
						<a href="javascript:history.back()" class="link">Cancel</a>
					</div><!-- /.form__actions -->	
				  


			</form>
			<div class="section__body" >
				<header class="form__head form__head__alt head__export">
					<h3>Asset History</h3>
					<a class="btn btn--export" href="https://api.hallmarkmediahub.com/lib/reports/userdownloads.php?id={{Profile.profile_id}}&status=DOWNLOADED" target="_blank">EXPORT</a>
				</header><!-- /.form__head -->
				<div class="table table-assets">
					<table>
						<thead>
							<tr>
								<th (click)="sortBy('history_id')">HIST.ID</th>
								<th (click)="sortBy('status')">STATUS</th>	
								<th (click)="sortBy('last_updated')">AS OF</th>	
								<th class="tdid" (click)="sortBy('asset_id')">ASSET ID</th>	
								<th (click)="sortBy('asset_info[0].asset_name')">ASSET NAME</th>
								<th >PROFILE ID</th>
								
							
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let results of History  | sortBy: reverse : propertyName">
								<td>{{results.history_id}}</td>
								<td>{{results.status}}</td>
								<td>{{results.last_updated | date: 'shortDate'}}</td>
								<td  class="tdid" >{{results.asset_id}}</td>
								<td>{{results.asset_info[0].asset_name}}</td>
								<td>{{results.profile_id}}</td>
								
								
							</tr>
						</tbody>
					</table>
			 </div>
			</div>
			<div class="section__body" >
				<header class="form__head form__head__alt">
					<h3>Result Submissions</h3>
				</header><!-- /.form__head -->
				<div class="table table-results">
					<table>
						<thead>
							<tr>
								<th (click)="sortBy('history_id')">HIST.ID</th>
								<th (click)="sortBy('results_submitted')">UPDATED</th>	
								<th (click)="sortBy('asset_info.asset_name')">ASSET NAME</th>	
								<th>RESULT STATUS</th>
								<th>DETAIL</th>
							</tr>
						</thead>
						<tbody>
							<ng-container  *ngFor="let result of Results">
							<tr>
								<td>{{result.history_id}}</td>
								<td>{{result.results_submitted | date: 'shortDate'}}</td>
								<td>{{result.asset_info.asset_name}}</td>
								<td >{{result.result_status}}
									<span *ngIf="result.results_submitted">{{result.result_date | date: 'shortDate'}}</span>
								</td>
								<td ><a  (click)="toggleExpand(result)" class="btn btn--light">{{result.expand? 'HIDE':'VIEW'}} DETAIL</a></td>
							</tr>
								<div class="accordion__section  "  [ngClass]="{'is-open': result.expand}">
									<div class="accordion__body">
										<div class="accordion__body-content" >
											<div>Intended Markets:{{result.result_detail.IACCTS}}<br>
											Intended Reach:{{result.result_detail.IREACH}}<br>
											Intended Tactics:{{result.result_detail.ITACTS}}
											</div>
											<div>Actual Markets:{{result.result_detail.IACCTS}}<br>
											Reported Reach:{{result.result_detail.IREACH}}<br>
											Reported Tactics:{{result.result_detail.ITACTS}}<br>
											From: {{result.result_detail.STARTDATE | date: 'shortDate'}}-{{result.result_detail.ENDDATE | date: 'shortDate'}}<br>
											<a href="{{result.result_detail.FILES}}">SupportFile</a>
												
											</div>
											
										</div><!-- /.accordion__body-head -->
										
										
									</div><!-- /.accordion__body -->
									
									
									
								</div><!-- /.accordion__section -->

							
								</ng-container>
						</tbody>
					</table>
			 </div>
			</div>
			<div class="section__body" >
				<header class="form__head form__head__alt">
					<h3>Customization Requests</h3>
				</header><!-- /.form__head -->
				<div class="table table-custom">
					<table>
						<thead>
							<tr>
								<th (click)="sortBy('request_id')">REQ.ID</th>
								<th (click)="sortBy('asset_name')">ASSET NAME</th>	
								<th (click)="sortBy('request_submitted')">SUBMITTED</th>	
								<th (click)="sortBy('request_status')">STATUS</th>		
								<th (click)="sortBy('last_updated')">AS OF</th>	
								<th>DETAIL</th>
							</tr>
						</thead>
						<tbody>
							<ng-container *ngFor="let itemz of Customizations">
								<tr >
									<td>{{itemz.request_id}}</td>
									<td>{{itemz.asset_name}}</td>
									<td>{{itemz.request_submitted | date: 'shortDate'}}</td>
									<td>{{itemz.request_status}}</td>
									<td>{{itemz.last_updated| date: 'shortDate'}}</td>
									<td ><a  (click)="toggleExpand(itemz)" class="btn btn--light">{{itemz.expand? 'HIDE':'VIEW'}} DETAIL</a></td>
								</tr>
								<div class="accordion" >
									<div class="accordion__section  "  [ngClass]="{'is-open': itemz.expand}">
										<div class="accordion__body">
											<div class="accordion__body-head" >
												<h3><b>ACTIVITY</b>:{{itemz.request_approved>'2022-01-01'? "APPROVED":''}} {{itemz.customs_detail[0]?.activity[0]?.descr}}</h3>
											</div><!-- /.accordion__body-head -->
											<div class="message-container">

												<ng-container *ngFor="let a of itemz.customs_detail">
													<ng-container *ngFor="let m of a.activity">

														<div class="message-bubble" [ngClass]="{'outgoing':m.reviewer_comment}" >

															<p>{{m.reviewer_comment? "Reviewer: ":"User: "}}{{m.descr}} {{m.user_comment}} {{m.reviewer_comment}}</p>
															<!--<a *ngIf="m.support" href="{{m.support}}" TARGET="_blank">DOWNLOAD</a>-->
															<a *ngIf="m.support" (click)="S3S.secureDownload(m.support)" target="_blank">DOWNLOAD</a>
															<span class="message-time">{{a.created_date | date: 'shortDate'}}</span>
														</div>
													</ng-container>

												</ng-container>
											</div>


											<div class="accordion__body-content" *ngIf="!itemz.request_approved>'2022-01-01' ||itemz.request_approved==null">

												<div class="newcomment">
													<div class="form__row">
														<textarea [(ngModel)]="itemz.reviewer_comment" placeholder="Add response and/or upload file and click submit below"></textarea>
													</div>
													<div class="form__row">
														<div class="file-box file-box-assets" style="margin-bottom:1.5rem;">
															<p style="margin-bottom:0;">
																<a *ngIf="itemz.url && !Files_assets" href="{{itemz.url}}" target="_blank">Attachment</a>
																<span *ngFor="let upload of S3S.uploads_assets" ><br>New Attachment: 
																	<a href="upload.Location" target="_blank">{{upload.filename}}  - {{upload.fileprogress}}% - Uploaded</a>
																</span>						
															</p>


															<label for="itemzurl" class="btn btn--small">
																<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
																	<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
																</svg>

																Browse
															</label>
															<input id="itemzurl" #itemzurl type="file" style="display:none" (change)="uploadFiles($event,'assets')"  required/>
															<div class="upload_status" *ngIf="Files_assets?.length > 0 && S3S.uploads_assets?.length==0">
																<div *ngFor="let file of Files_assets">
																	<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>
																</div>
								<!-- upload errors-->
																<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
																	We had trouble uploading the file. Please refresh the page and try again.
																	<p>If the problem persists please supply us with the following details</p>

																</div>
																<div>{{Error}}</div>
														  </div>
														<div><input (click)="submitComment(itemz, false)" class="btn form__btn" value="Submit" name="submitcomment"></div>	
														</div><!-- /.file-box -->
													</div><!-- /.form__row -->
													<div class="form__actions" >
						
						<input (click)="clearComment(itemz)" class="btn form__btn" value="Clear" name="clearcomment">
						<input (click)="submitComment(itemz, true)" class="btn form__btn" value="Approve Customization" name="approve">
						
					</div><!-- /.form__actions -->	
				

												</div>
											</div><!-- /.accordion__body-content -->
										</div><!-- /.accordion__body -->



									</div><!-- /.accordion__section -->

								</div><!-- /.accordion js-accordion -->
							</ng-container>
							

						</tbody>
					</table>
			 </div>
			</div>

		</div><!-- /.form -->

		
	</div><!-- /.modal__inner -->
</section><!-- /.modal -->
<!--
											<div class="message-bubble">
    <p>{{desc}} {{user_comment}} {{reviewer_comment}}</p>
    <span class="message-time">10:30 AM</span>
  </div>
  <div class="message-bubble outgoing">
    <p>Hey there!</p>
    <span class="message-time">10:32 AM</span>
  </div>
  <div class="message-bubble">
    <p>How are you doing today?</p>
    <span class="message-time">10:35 AM</span>
  </div>
  <div class="message-bubble outgoing">
    <p>Not too bad, thanks for asking.</p>
    <span class="message-time">10:38 AM</span>
  </div>
</div>

										-->





