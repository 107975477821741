        <section class="section-created-assets" *ngIf="!resources">
            <div class="shell shell--size-4">
                <div class="section__inner" style="overflow: unset">
                    <div class="section__head">
                        <h4>On Demand Reporting</h4>
   
    
                        
                    </div><!-- /.section__head -->

                    <div class="section__body">
						<div class="table-reports">
							<div class="cols ">
								
								<div class="col col--1of2"><label class="form__label">Period Starting:</label>

									<div class="form__controls">
										<input matInput [matDatepicker]="picker1" [(ngModel)]="report_start" required type="text" class="field field--size-1" name="report_start" id="report_start" (dateChange)="updateStats()">
										 <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
										<mat-datepicker #picker1></mat-datepicker>
									</div><!-- /.form__controls -->
								</div>
								<div class="col col--1of2"><label class="form__label">Period Ending:</label>

									<div class="form__controls">
										<input matInput [matDatepicker]="picker2" [(ngModel)]="report_end" required type="text" class="field field--size-1" name="report_start" id="report_start" (dateChange)="updateStats()">
										 <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
										<mat-datepicker #picker2></mat-datepicker>
									</div><!-- /.form__controls -->
								</div>
							</div>
							<div class="cols cols--bottom-offset">
								<div class="col col--1of3 stats_tile"><b>ASSETS DOWNLOADS:</b><br>{{stats.downloads}}</div>
								<div class="col col--1of3 stats_tile"><b>ASSETS UPLOADED:</b><br>{{stats.uploads}}</div>
								<div class="col col--1of3 stats_tile"><b>ASSETS AVAILABLE:</b><br>{{stats.available}}</div>
								<div class="col col--1of3 stats_tile"><b>HEAVIEST USER:</b>
									<li *ngFor="let user of stats.users">{{user.email}} - {{user.DL}}</li>
								</div>
								<div class="col col--1of3 stats_tile"><b>HEAVIEST PARTNER:</b>
									<li *ngFor="let partner of stats.partners">{{partner.partner_full}} - {{partner.DL}}</li>
								</div>
								<div class="col col--1of3 stats_tile"><b>MOST POPULAR ASSET:</b>
									<li *ngFor="let asst of stats.assets">{{asst.asset_name}} - {{asst.DL}}</li>
								</div>
								
							</div>
							
							<div class="cols cols--alt cols--bottom-offset">
								<div class="col col--1of2">
									<label class="form__label">Report Requested:</label>
									<div class="select" [ngClass]="{'is-open':network_select_open}"  (click)="toggleSelectOpen('network');">
										<div class="select__head">
											<p>{{current_report ? current_report.report_title:'Choose Report'}}</p>
										</div><!-- /.select__head -->

										<div class="select__list" (mouseleave)="toggleSelectOpen('network');">
											<ul>

												<li *ngFor="let rpt of Reports" (click)="selectOption('rpt', rpt);">{{rpt.report_title}}</li>

											</ul>
										</div><!-- /.select__list -->
									</div><!-- /.select -->
								</div><!-- /.col-/-1of5 -->
							</div><!-- /.cols -->
							
							<!--
							<div class="form__terms terms__3">
								<div class="checkbox">
									<input type="checkbox" id="checkbox_publish" [checked]="current_affiliate" [(ngModel)]="current_affiliate">

									<label for="checkbox_publish">Affiliates Only</label>
								</div><!-- /.checkbox --
							</div> /.form__terms 
							-->
							
							<div class="form__row" *ngIf="current_report">
								<a href="https://api.hallmarkmediahub.com/lib/reports/{{current_report.url}}.php/?s={{report_start | date:'YYYY-MM-dd'}}&e={{report_end | date:'YYYY-MM-dd'}}" target="_blank" class="btn">Generate Report</a>
							</div>

						</div>

					
					</div><!-- /.section__body -->
                </div><!-- /.section__inner -->
            </div><!-- /.shell shell--size-3 -->
        </section><!-- /.section-created-assets -->
<app-alert></app-alert>

<!--
//create report table and urls
-->
