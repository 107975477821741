import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router, CanActivate } from "@angular/router";
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

//Services
import { ErrorhandlerService } from './errorhandler.service';
import { ProfileService } from './profile.service';

//Environment
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AssetsService {
	private apiUrl = environment.apiUrl; 
	public assetsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	previewOpen=false;
	previewAsset=null;
	viewResults=false;
	viewCAR=false;
	previewName=null;
	previewVidFile=null;
	downloadSuccess=false;
	assetSearch=null;
	current_network=null;
	current_campaign=null;
	current_type=null;
	current_title=null;
	current_tag=null;
	filteredCount=0;
	current_sort="asset_start";
	public historyObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public customsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public resultsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public queueObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public fullHistoryObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
		
	

  constructor(private http: HttpClient,
	  private location: Location,
	 private router: Router,
	 private PS : ProfileService,
	 private errorhandlerService: ErrorhandlerService) { }
	
	updateAssets(id){
		if(id>0){
			this.pullHistory(id).subscribe((response) => {
				this.processHistory(response);
			});
			this.pullAssetsById(id).subscribe((response) => {
				this.assetsObj.next(response);
			});
			this.pullCARsById(id).subscribe((response) => {
				this.customsObj.next(response);
			});
		}else if(this.PS.profile_id){
			this.pullHistory(this.PS.profile_id).subscribe((response) => {
				this.processHistory(response);
			});
			this.pullAssetsById(this.PS.profile_id).subscribe((response) => {
				this.assetsObj.next(response);
			});
			this.pullCARsById(this.PS.profile_id).subscribe((response) => {
				this.customsObj.next(response);
			});
		}else{
			this.historyObj.next([]);
			this.queueObj.next([]);			
			this.pullAssets().subscribe((response) => {
				this.assetsObj.next(response);
			});
		}
	}
	
	closeVid(){
		this.previewOpen=false;
		this.previewAsset=null;
		this.previewName=null;
		this.previewVidFile=null;	
	}
	copySearchLink(){
		let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = "https://hallmarkmediahub.com/library/search/"+encodeURIComponent(this.assetSearch);
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		alert('copied to clipboard');
	}
	copyAssetLink(id, cat){
	  let selBox = document.createElement('textarea');
		selBox.style.position = 'fixed';
		selBox.style.left = '0';
		selBox.style.top = '0';
		selBox.style.opacity = '0';
		selBox.value = "https://hallmarkmediahub.com/library/"+id+"/"+cat;
		document.body.appendChild(selBox);
		selBox.focus();
		selBox.select();
		document.execCommand('copy');
		document.body.removeChild(selBox);
		alert('copied to clipboard');
	  }
	
	getAssets(){return this.assetsObj;}
	getCustoms(){return this.customsObj;}
	getQueue(){return this.queueObj;}
	getHistory(){return this.historyObj;}
	getHistorybyID(id){
		this.pullHistory(id).subscribe((response) => {
			this.processHistory(response);
		});
		console.log('id submitted was',id)
		return this.historyObj;
	}
	getFullHistory(){return this.fullHistoryObj;}


	
	processHistory(data){
		var qarr=[];
		var harr=[];
		var ttlarr=[];
		if(data && data!=="no history"){
			console.log('data',data);
			data.forEach(function(obj){
				if(obj.status == "IN-QUEUE"){
					qarr.push(obj);
				}else if(obj.status == "DOWNLOADED"){
					harr.push(obj);
				}
				ttlarr.push(obj);
			});
		}
		this.historyObj.next(harr);
		this.queueObj.next(qarr);
		this.fullHistoryObj.next(ttlarr);
	}
	pullAssets():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'assets').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullAssetsById(id):Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'assets/'+id).pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullUsageById(id):Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'usage/'+id).pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullHistory(id):Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'history/'+id).pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullCARById(id):Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'custom/'+id).pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	closeAssetSuccess(){
		this.downloadSuccess=false;
		this.router.navigate(['/my-activity']);
	}
	pullCARsById(id):Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'usercustoms/'+id).pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	addtoQueue(id,json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'addtoqueue/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	reDownloadAsset(id,json_data):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'reload/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	removefromQueue(id,json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'removefromqueue/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	checkoutQueue(id,json_data):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'checkoutqueue/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	submitResults(id,json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'results/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	submitAssets(id,json_data):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'asset/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	submitCustomization(id,json_data):Observable<any>{
		return this.http.post<any[]>(this.apiUrl + 'custom/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	

	
}
