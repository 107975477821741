<div class="modal modal--secondary" [ngClass]="{'is-open' : PS.edit_state!==null}">
	<div class="modal__inner" [ngClass]="{'results_overview':PS.admin_stage=='asset_history'}">
		<div class="form form--modal">
			<form name="profileForm" #profileForm="ngForm" novalidate >
			
				<ng-container id="addr" *ngIf="PS.edit_state=='addr' || PS.edit_state=='admin'">
					<header class="form__head" *ngIf="PS.edit_state!=='admin'">
						<h2>Update Personal Information</h2>
					</header><!-- /.form__head -->
					<ng-container id="adminheader" *ngIf="PS.edit_state=='admin'">
						<header class="form__head">
							<h3>User: {{Profile.first_name}} {{Profile.last_name}}<br>Status: {{Profile.status}}</h3>
						</header><!-- /.form__head -->
							<div class="form__actions" *ngIf="Profile.status=='pending'">
								<a (click)="approveUser(Profile.profile_id);" class="btn  form__btn">Approve User</a>
								<a (click)="denyUser(Profile.profile_id);" class="btn  form__btn">Deny User</a>
							</div><!-- /.form__actions -->	
							<div class="form__actions">
								<a *ngIf="PS.admin_stage!=='user_info'" (click)="PS.admin_stage='user_info';" class="btn form__btn">User Info</a>
								<a *ngIf="PS.admin_stage!=='asset_history' && Profile.assets" (click)="setStage('asset_history')" class="btn form__btn">Asset History</a>
								<a *ngIf="PS.admin_stage!=='results' && Profile.results>0" (click)="PS.admin_stage='results';" class="btn form__btn">Submitted Results</a>
								<a *ngIf="PS.admin_stage!=='customization' && Profile.customs>0" (click)="PS.admin_stage='customization';" class="btn form__btn">Customization Requests</a>
							</div><!-- /.form__actions -->					

					</ng-container>

					<div class="form__body" *ngIf="PS.admin_stage=='user_info' ||PS.edit_state=='addr'">
						<div class="form__row">
							<label for="first-name" class="form__label">First Name</label>

							<div class="form__controls">
								<input type="text" class="field" name="first-name" id="first-name" 
									   [(ngModel)]="Profile.first_name" #fname="ngModel" required>
							<p class="form__error" *ngIf="fname.error">Please complete this field.</p>
							</div><!-- /.form__controls -->
						</div><!-- /.form__row -->

						<div class="form__row">
							<label for="last-name" class="form__label">Last Name</label>

							<div class="form__controls">
								<input type="text" class="field" name="last-name" id="last-name" 
									   [(ngModel)]="Profile.last_name" #lname="ngModel" required>
							<p class="form__error" *ngIf="lname.error">Please complete this field.</p>
							</div><!-- /.form__controls -->
						</div><!-- /.form__row -->

						<div class="form__row">
							<label for="phone" class="form__label">Phone</label>

							<div class="form__controls">
								<input type="text" class="field" name="phone" id="phone"  
									   [(ngModel)]="Profile.phone" #phone="ngModel" required>
							<p class="form__error" *ngIf="phone.error">Please complete this field.</p>
							</div><!-- /.form__controls -->
						</div><!-- /.form__row -->

						<div class="form__row">
							<label for="title" class="form__label">Your Title</label>

							<div class="form__controls">
								<input type="text" class="field" name="title" id="title"  
									   [(ngModel)]="Profile.title" #title="ngModel" >
							</div><!-- /.form__controls -->
						</div><!-- /.form__row -->

						<div class="form__row">
							<label for="mvpd" class="form__label">Partner</label>

							<div class="form__controls">
								<input type="text" class="field" name="partner" id="partner" 
									   [(ngModel)]="Profile.partner_full" #partner="ngModel" required>
							<p class="form__error" *ngIf="partner.error">Please complete this field.</p>

							</div><!-- /.form__controls -->
						</div><!-- /.form__row -->
						
						<ng-container   *ngIf="PS.edit_admin">
							<div class="form__row" >
								<label for="fld-email" class="form__label">Email</label>

								<div class="form__controls">
									<input type="text" email class="field" name="fld-email" id="fld-email" 
										   [(ngModel)]="Profile.email" #email="ngModel" required
										   [ngClass]="{'is--error': PS.eml_in_use}" (focus)="PS.eml_in_use=false">
								<p class="form__error" *ngIf="PS.eml_in_use">A profile is already associated with this email.</p>
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->
							
						</ng-container>

						<div class="form__row">
							<label for="select-type" class="form__label">Account Type</label>

							<div class="form__controls">
								<div class="select select--gray" id="select-typ" [ngClass]="{'is-open':select_open, 'select_error': Profile.account_type==null}"
						 (click)="toggleSelectOpen();" >
									<div class="select__head">
										<p>{{ Profile.account_type ?  Profile.account_type  : "Select" }}</p>
									</div><!-- /.select__head -->

									<div class="select__list" (mouseleave)="toggleSelectOpen();">
										<ul>
											<li (click)="select_option('Local Ad Sales')">Local Ad Sales</li>
											<li (click)="select_option('Marketing')">Marketing</li>
											<li (click)="select_option('National')">National</li>
											<li (click)="select_option('Enterprise')">Enterprise</li>
										</ul>
									</div><!-- /.select__list -->	
								</div><!-- /.select -->
								<p class="form__error" *ngIf="Profile.account_type==null">Please make a selection.</p>
							</div><!-- /.form__controls -->
						</div><!-- /.form__row -->

					</div><!-- /.form__body -->

				</ng-container>

				<ng-container id="eml" *ngIf="PS.edit_state=='eml'">
					<header class="form__head">
						<h2>Change Your Email Address</h2>
					</header><!-- /.form__head -->

					<div class="form__body">
						<div class="form__row">
							<label for="fld-email" class="form__label">Email</label>

							<div class="form__controls">
								<input type="text" email class="field" name="fld-email" id="fld-email" 
									   [(ngModel)]="Profile.email" #email="ngModel" required
									   [ngClass]="{'is--error': PS.eml_in_use}" (focus)="PS.eml_in_use=false">
							<p class="form__error" *ngIf="PS.eml_in_use">A profile is already associated with this email.</p>
							</div><!-- /.form__controls -->
						</div><!-- /.form__row -->
					</div><!-- /.form__body -->

				</ng-container>

				<ng-container id="pw" *ngIf="PS.edit_state=='pw'">
						<header class="form__head">
							<h2>Change Password</h2>
						</header><!-- /.form__head -->


					<div class="form__body">
						<div class="form__row">
							<label for="current-password" class="form__label">Current Password</label>
								<div class="form__controls">
									<input [type]="showPassword ? 'text' : 'password'" class="field" name="current-password" id="current-password"
									 [(ngModel)]="pwords.oldpw" #oldpw="ngModel"  required
										   [ngClass]="{'is--error': notpw }"
										   (focus)="notpw=false" 										   
										   >

									<a (click)="showPassword=!showPassword;" class="form__filter">Show</a>
									<p class="form__error" *ngIf="!oldpw.valid  && PS.edit_submitted">This field is required.</p>
									<!-- /.form__error -->
									<p class="form__error" *ngIf="notpw">This is not your current password.</p>
									<!-- /.form__error -->
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->

							

						<div class="form__row">
							<label for="field-password" class="form__label">New Password</label>

							<div class="form__controls">
								<input [type]="showPassword ? 'text' : 'password'" class="field" required name="field-password" id="field-password"  [(ngModel)]="pwords.newpw" #newpw="ngModel" (keyup)="passwordCheck($event)"
									   [ngClass]="{'pw_error':(!pwnumb || !pwcap || !pwsymb || !pwchar) && step_submitted}"
									   >
								<p class="form__error" *ngIf="(!pwnumb || !pwcap || !pwsymb || !pwchar) && PS.edit_submitted">Please enter a valid password</p><!-- /.form__error -->

								<div class="form__hints">
									<span class="form__hint" [ngClass]="newpw.dirty && pwchar ? 'on-success':'on-error'" >8 characters</span>

									<span class="form__hint" [ngClass]="newpw.dirty && pwsymb ? 'on-success':'on-error'">1 symbol</span>

									<span class="form__hint" [ngClass]="newpw.dirty && pwnumb? 'on-success':'on-error'">1 number</span>

									<span class="form__hint" [ngClass]="newpw.dirty && pwcap? 'on-success':'on-error'">1 capital letter</span>
								</div><!-- /.form__hints -->
								<a (click)="showPassword=!showPassword;" class="form__filter is-on">{{ showPassword ? 'Hide' : 'Show'}}</a>

							</div><!-- /.form__controls -->
						</div><!-- /.form__row -->

						<div class="form__row" >
							<label for="field-password_repeat" class="form__label">Repeat Password</label>

							<div class="form__controls">
								<input [type]="showPassword ? 'text' : 'password'"
									   [disabled]="!pwnumb || !pwcap || !pwsymb || !pwchar"
									   class="field on-focus" name="field-password_repeat" id="field-password_repeat" (change)="checkMatch();" (focus)="verifying=false" [(ngModel)]="pwords.repeat" #repeat="ngModel" [ngClass]="{'is--error':verifying && pw_match_fail}">
								<p class="form__error" *ngIf="verifying && pw_match_fail">Your password entries do not match.</p><!-- /.form__error -->

								<a (click)="showPassword=!showPassword;" class="form__filter is-on">{{ showPassword ? 'Hide' : 'Show'}}</a>

							</div><!-- /.form__controls -->
						</div><!-- /.form__row -->

					
					</div><!-- /.form__body -->

				</ng-container>
				
				<div class="section__body" *ngIf="PS.admin_stage=='asset_history'">
                        <div class="table-assets table-results">
							<table>
								<tr>
									<td>HIST.ID</td>
									<td>STATUS</td>	
									<td>AS OF</td>	
									<td>ASSET ID</td>	
									<td>ASSET NAME</td>	
									<td>RESULTS</td>
								</tr>
								<tr *ngFor="let results of History">
									<td>{{results.history_id}}</td>
									<td>{{results.status}}</td>
									<td>{{results.last_updated | date: 'shortDate'}}</td>
									<td>{{results.asset_id}}</td>
									<td>{{results.asset_info[0].asset_name}}</td>
									<td *ngIf="results.results_date">{{results.results_status}} : {{results.results_date | date: 'shortDate'}}</td>
									<td *ngIf="!results.results_date">{{results.results_status}}</td>
								</tr>
							</table>
					 </div>
				</div>

				
				
				
					<div class="form__actions" *ngIf="PS.edit_state!=='admin' || (PS.edit_state=='admin' && PS.admin_stage=='user_info')">
						<input (click)="submitProfile(Profile, profileForm)" class="btn form__btn" value="Save Changes" name="login">

						<a (click)="PS.closeModals();" class="link">Cancel</a>
					</div><!-- /.form__actions -->	
				   <div class="form__actions" *ngIf="PS.edit_state=='admin' && PS.admin_stage!=='user_info'">
						
						<a  (click)="PS.admin_stage='user_info';" class="link">Back to User Info</a>
					</div><!-- /.form__actions -->	


			</form>
		</div><!-- /.form -->

		<a (click)="PS.closeModals();" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="">
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->






