<div class="modal modal--alt is-open"><!-- [ngClass]="{ 'is-open': AS.viewResults }"-->
	<div class="modal__inner">
		<div class="popup__head">
			<h2 class="h6">Email Preview: {{email_template.email_subject}}</h2><!-- /.h6 -->

		</div><!-- /.popup__head -->
		<div *ngIf="email_template.adhoc">
			<style type="text/css" media="screen">
				/* Linked Styles */
				body { padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#e1e3e4; -webkit-text-size-adjust:none }
				a { color:#802595; text-decoration:none }
				p { padding:16px 0 !important; margin:0 !important }
				img { -ms-interpolation-mode: bicubic; /* Allow smoother rendering of resized image in Internet Explorer */ }
				.mcnPreviewText { display: none !important; }
				.btn{
					text-align:center;
					background-color: #f1c400;
					font-size: 16px; 
					font-family: Arial, Helvetica, sans-serif; 
					font-weight: bold; 
					text-decoration: none; 
					padding: 10px 25px; 
					color: #582C83;
					border-radius: 100px; 
					display: inline-block; 
					mso-padding-alt: 0;			
				}

				.m-block { display: none !important; width: 0 !important; height: 0 !important; font-size: 0 !important; line-height: 0 !important; min-height: 0 !important; mso-hide:all;}

				.m-width { width:600px;}

				.bg-rollover:hover {
					background-color: #582C83 !important;
					color: #f1c400 !important;
				}

				* {-webkit-text-size-adjust: none;}

				/* Text */
				.h1 { font-size: 32px !important; line-height: 38px !important; }
				.h2 { font-size: 24px !important; line-height: 30px !important; }
				.h3 { font-size: 18px !important; line-height: 24px !important; }
				.h4 { font-size: 12px !important; line-height: 18px !important; }

				.secondary-text{
					-webkit-text-size-adjust: none; 
					font-family:Arial, Helvetica, sans-serif; 
					font-size:12px; 
					line-height:1.5; 
					font-style:normal; 
					font-weight:normal; 
					color:#616161; 	
				}		
				.footer-text{
					font-size: 12px;
					font-family: Arial, Helvetica, sans-serif; 
				}
				.footer-text a{
					color: #802595;
					line-height: 2;
				}


				/*Desktop Gmail List Fix*/
				/* u + .body .glist { margin-left: 0 !important; }
				@media only screen and (max-width: 640px) {
				u + .body .glist { margin-left: 25px !important; }
				} */


				/* Mobile styles */
				@media only screen and (max-device-width: 540px), only screen and (max-width: 540px) {

					u + .body .gwfw { width:100% !important; width:100vw !important; }
					.m-width { width: 100%; max-width:600px;}
					.m-shell { width: 100% !important; min-width: 100% !important; }
					.m-center { text-align: center !important; }
					.center { margin: 0 auto !important; }
					.td { width: 100% !important; min-width: 100% !important; }
					.td-stack{width: 100%!important;display: block;float: none!important;}
					.m-hide { display: none !important; width: 0 !important; height: 0 !important; font-size: 0 !important; line-height: 0 !important; min-height: 0 !important; mso-hide:all;}
					.m-block { display: block !important; width:100% !important; height: auto !important;}
					.fluid-img img { width: 100% !important; max-width: 100% !important; height: auto !important; }
					.wauto { width: auto !important; }

					/* Text */
					.h1 { font-size: 30px !important; line-height: 36px !important; }
					.h2 { font-size: 22px !important; line-height: 28px !important; }
					.h3 { font-size: 16px !important; line-height: 22px !important; }
					.h4 { font-size: 12px !important; line-height: 18px !important; }
					.secondary-text{font-size:14px !important;}
					.footer-text{font-size: 12px !important; line-height: 18px !important; }

				}
			</style>
			<body class="body" style="padding:0 !important; margin:0 !important; display:block !important; min-width:100% !important; width:100% !important; background:#e1e3e4; -webkit-text-size-adjust:none;" bgcolor="#e1e3e4">
				<div class="preheader" style="font-size: 1px; display: none !important;">Hallmark Media Hub</div>
				<table width="100%" border="0" cellspacing="0" cellpadding="0" bgcolor="#e1e3e4" class="gwfw">
					<tr>
						<td align="center" valign="top" >
							<table width="600"  style="max-width: 600px" border="0" cellspacing="0" cellpadding="0" class="m-shell m-width">
								<tr>
									<td class="td m-width" style="max-width:600px; font-size:0pt; line-height:0pt; padding:0; margin:0; font-weight:normal;">
										<table width="100%" border="0" cellspacing="0" cellpadding="0">
											<!-- Wrapper -->
											<tr>
												<td style="padding:0;">
													<table width="100%" border="0" cellspacing="0" cellpadding="0">
												<!-- HEADER -->
														<tr>
															<td  class="m-width" style="-webkit-text-size-adjust: none; font-family:Arial, Helvetica, sans-serif; font-size:14px; line-height:21px; font-weight:normal; min-width:auto !important; color:#ffffff;background:#582C83; padding: 20px 20px;text-align: center;" >
																<table width="100%" border="0" cellspacing="0" cellpadding="0">
																	<tr>
																		<td class="td-stack" width="100%" style="text-align: center;">
																			<a href="https://hallmarkmediahub.com/" target="_blank" title="Hallmark Media Hub"><img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/HallmarkMedia_WhiteLogo.png" width="100" style="max-width:100px;color:#ffffff;font-size:16px;line-height: normal;text-align: center;font-family:Arial, Helvetica, sans-serif;padding:0 5px;" border="0" alt="Hallmark Media Hub" title="Hallmark Media Hub"/></a>
																		</td>

																	</tr>													
																</table>
															</td>
														</tr>
														<!-- END HEADER -->
														<!-- HERO BANNER -->	
														<tr>
															<td style="font-size:16px;line-height:1.5; color:#333333;line-height:0pt; text-align:center;background:#ffffff;padding:20px;"  class="m-width"></td>
														</tr>
														<!-- END HERO BANNER -->
														<!-- BODY COPY -->								
														<tr>
															<td  class="m-width body-text" style="-webkit-text-size-adjust: none; font-family:Arial, Helvetica, sans-serif; font-size:16px; line-height:1.5; font-style:normal; font-weight:normal; min-width:auto !important; color:#333333; text-align:left;background: #ffffff; padding: 30px 34px 16px 34px;" valign="top">
															<h3 style="margin:0;"><span style="color:#333333;font-size: 18px;line-height: 24px;font-weight: bold;" class="h3">Dear RecipientFirstName</span></h3><br>
															<span [innerHTML]="email_template.email_body_1"></span>

															</td>
														</tr>
														<!-- END BODY COPY -->

														<!-- ROUNDED BUTTON -->
														<tr *ngIf="email_template.custom_cta">
															<td style="padding: 20px 40px 20px 40px;background: #ffffff;" align="center" >
																<table border="0" cellspacing="0" cellpadding="0">
																	<tr>
																		<td style="mso-padding-alt:20px 40px; color:#ffffff; font-family:Arial, Helvetica, sans-serif; font-size:16px; font-weight:bold;line-height:normal; text-align:center; min-width:auto!important;">

																			<a href="{{email_template.custom_cta_link}}" style="text-align:center;background-color: #f1c400; font-size: 16px; font-family: Arial, Helvetica, sans-serif; font-weight: bold; text-decoration: none; padding: 10px 25px; color: #582C83;border-radius: 100px; display: inline-block; mso-padding-alt: 0;" target="_blank" class="btn bg-rollover" title="{{email_template.custom_cta_copy}}">
																				<span>{{email_template.custom_cta_copy}}</span>
																				</a>

																		</td>
																	</tr>
																</table>
															</td>
														</tr>
														<!-- END ROUNDED BUTTON	 -->
														<!-- SECONDARY COPY -->	
														<tr>
															<td  class="m-width secondary-text" style="-webkit-text-size-adjust: none; font-family:Arial, Helvetica, sans-serif; font-size:12px; line-height:1.5; font-style:normal; font-weight:normal; min-width:auto !important; color:#616161; text-align:left;background: #ffffff; padding: 16px 34px 16px 34px;" valign="top">
																If you have any questions or concerns, feel free to contact Hallmark Media by emailing <a href="mailto: questions@hallmarkmediahub.com" style="color:#802595">questions@hallmarkmediahub.com</a>. For questions about Hallmark Media, please contact your Hallmark representative.
														</td>	
														</tr>
														<!-- END SECONDARY COPY -->	
														<!-- DIVIDER LINE -->
														<tr>
															<td style="font-size:0pt; line-height:0pt; text-align:left;background:#ffffff;padding:20px 34px 20px 34px;"  class="m-width">
																<img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/line.png"  width="522" style="width: 100%; max-width: 522px;color:#333333;font-size:16px;line-height: normal;text-align: center;font-family:Arial, Helvetica, sans-serif;" border="0" />
															</td>
														</tr>
														<!-- END DIVIDER LINE -->													
														<!-- FOOTER LINKS -->
														<tr>
															<td  class="m-width footer-text" style="-webkit-text-size-adjust: none; font-family:Arial, Helvetica, sans-serif; font-size:12px; line-height:1.5; font-weight:normal; min-width:auto !important; color:#333333;background:#ffffff; padding: 16px 34px 16px 34px;" valign="top">
																<table width="100%" border="0" cellspacing="0" cellpadding="0">
																	<tr>
																		<td class="td-stack" width="50%" style="text-align: left;">
																			<h4 style="margin:0;"><span style="color:#333333;font-size: 12px;line-height: 18px;font-weight: bold;" class="h4">Helpful Links</span></h4>									
																			<br>
																			<a href="https://hallmarkmediahub.com" style="color:#802595;line-height:2;font-family:Arial, Helvetica, sans-serif;">HALLMARK MEDIA HUB</a><br>
																			<a href="https://www.hallmarkchannel.com/terms-of-use" target="_blank"  style="color:#802595;line-height:2;font-family:Arial, Helvetica, sans-serif;">TERMS OF USE</a><br>
																			<a href="https://www.hallmarkchannel.com/privacy-policy" target="_blank"  style="color:#802595;line-height:2;font-family:Arial, Helvetica, sans-serif;">PRIVACY POLICY</a>

																		</td>
																		<td class="td-stack" width="50%" style="text-align: right;" valign="bottom">
																			<a href="https://hallmarkmediahub.com" target="_blank" title="Hallmark Media Hub"><img src="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/email/HallmarkMedia_PurpleLogo.png" width="80" style="max-width:80px;color:#333333;font-size:12px;line-height: normal;text-align: center;font-family:Arial, Helvetica, sans-serif;padding:20px 0 5px;" border="0" alt="Hallmark Media Hub" title="Hallmark Media Hub"/></a>
																		</td>
																	</tr>													
																</table>
															</td>
														</tr>	
														<!-- END FOOTER LINKS -->
														</table>
												</td>
											</tr>
										</table>
									</td>
								</tr>
							</table>
						</td>
					</tr>
				</table>
			</body>

		</div>
		<div *ngIf="!email_template.adhoc" [innherHTML]="email_template.email_body_1">
		</div>

		<a (click)="closeResults();" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="">
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->

