import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-full',
  templateUrl: './header-full.component.html',
  styleUrls: ['./header-full.component.css']
})
export class HeaderFullComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
