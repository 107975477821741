import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	private ngUnsubscribe = new Subject<void>();
    token: any|[];
	

  constructor(public router:Router,public PS: ProfileService) { }

  ngOnInit(): void {
	  this.PS.getToken().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { this.token = data; console.log(this.token)});
	 
  }
	ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }

}
