<div class="modal welcome-modal " [ngClass]="{ 'is-open': CS.homePreviewOpen }">
	<ng-container *ngFor="let asset of Assets">
    <div class="modal__inner" *ngIf="asset.asset_id==CS.homePreviewId">
        <header class="form__head">
            <h3 >{{asset.program}}</h3>
        </header>
		<div style="width: 671px;
    height: 321px;
    background: none;
    position: absolute;
    z-index: 100;"></div>
 		<video width="100%" height="auto" controls autoplay controlsList="nodownload" contextmenu="false" class="still-img" *ngIf="CS.homePreviewOpen" >
			
            <!--<source src="https://ik.imagekit.io/hallmedia/HallmarkMediaHubWelcome_PN419706.mp4" type="video/mp4" autoplay>--> 
			<source src="{{asset.video_preview}}" type="video/mp4">
			Your browser does not support this video preview.
        </video>
        
        <a *ngIf="asset.active==1" class="btn" (click)="CS.viewAsset()" >Go To Asset</a>
        <a class="btn" (click)="CS.closeHomePreview()" >Close Preview</a>
        <a (click)="CS.closeHomePreview()" class="modal__btn">
            <img src="assets/images/svg/exit-button.svg" alt="">
        </a>
    </div>
	</ng-container>
</div>