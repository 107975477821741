<div class="modal" [ngClass]="{ 'is-open': AS.previewOpen }">
	<div class="modal__inner" >

		<header class="form__head" >
			<h3>{{AS.previewName}}</h3>
		</header><!-- /.form__head -->
		
		<ng-container *ngIf="AS.previewVidFile==null" >
			<div class="still-img" ><img src="{{AS.previewAsset}}" alt="{{AS.previewName}}"></div>
		</ng-container>
		<ng-container *ngIf="AS.previewVidFile!==null">
			<video class="still-img" width="100%" height="auto" controls>
				<source src="{{AS.previewVidFile}}" type="video/mp4">
				Your browser does not support this video preview.
			</video>
		</ng-container>
	

		<a (click)="AS.closeVid()" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="">
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->



