import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.css']
})
export class CountdownComponent implements OnInit {
  countdownDate: Date = new Date('2023-10-20T19:59:59');
  currentDate: Date = new Date();
  countdownInterval: any;
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.startCountdown();
  }

  startCountdown(): void {
    this.countdownInterval = setInterval(() => {
      this.currentDate = new Date();
      const timeDifference = this.countdownDate.getTime() - this.currentDate.getTime();

      if (timeDifference <= 0) {
        clearInterval(this.countdownInterval);
        return;
      }

      this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      this.hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      this.minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    }, 1000);
  }
}


/*
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.css']
})
export class CountdownComponent implements OnInit {
  countdownDate: Date = new Date('2023-12-31T23:59:59');
  currentDate: Date = new Date();
  countdownInterval: any;
  digitImages: string[] = [];
  labelImages: string[] = [];

  constructor() {
    for (let i = 0; i <= 9; i++) {
      this.digitImages.push(`assets/${i}.png`);
    }
    this.labelImages.push('assets/days.png', 'assets/hours_minutes.png');
  }

  ngOnInit(): void {
    this.startCountdown();
  }

  startCountdown(): void {
    this.countdownInterval = setInterval(() => {
      this.currentDate = new Date();
      const timeDifference = this.countdownDate.getTime() - this.currentDate.getTime();

      if (timeDifference <= 0) {
        clearInterval(this.countdownInterval);
        return;
      }

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);

      this.labelImages[0] = `assets/days.png`;
      this.labelImages[1] = `assets/hours_minutes.png`;

      this.digitImages[0] = `assets/${Math.floor(days / 10)}.png`;
      this.digitImages[1] = `assets/${days % 10}.png`;
      this.digitImages[2] = `assets/${Math.floor(hours / 10)}.png`;
      this.digitImages[3] = `assets/${hours % 10}.png`;
      this.digitImages[4] = `assets/${Math.floor(minutes / 10)}.png`;
      this.digitImages[5] = `assets/${minutes % 10}.png`;
    }, 1000);
  }
}
*/
