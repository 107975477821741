<!--<app-download-success></app-download-success>-->
<app-submit-results [asset]="current_asset" *ngIf="AS.viewResults"></app-submit-results>
<app-submit-custom [asset]="current_asset" *ngIf="AS.viewCAR"></app-submit-custom>
<app-alert></app-alert>
		<section class="section-headline">
			<div class="shell shell--size-3">
				<h2>My Activity</h2>

				<div class="tabs js-tabs">
					<nav class="tabs__nav">
						<ul>
							<li [ngClass]="{'is-current' :current_tab=='history'}">
								<a (click)="current_tab='history'">Asset History</a>
							</li>

							<li [ngClass]="{'is-current':current_tab=='custom'}" *ngIf="Customizations?.length>0">
								<a (click)="current_tab='custom'">Submitted Customizations </a>
							</li>
						</ul>
					</nav><!-- /.tabs__nav -->
				</div><!-- /.tabs js-tabs -->
			</div><!-- /.shell -->
         </section><!-- /.section-headline -->




		<section class="section-tabs">
			<div class="shell shell--size-2">
				<div class="tabs">
					<div class="tab" *ngIf="current_tab=='history'">
						<div class="assets">

						<div class="section-utilities">
						<div class="shell">
							<div class="section__inner">
							<div class="section__tags">

								<div class="tags">
									<span>Age: </span>
									<a (click)="age_range='all'" class="tag tag--small" [ngClass]="{'is-selected':age_range=='all'}">All</a>

									<a (click)="age_range='<30days'" class="tag tag--small" [ngClass]="{'is-selected':age_range=='<30days'}"><30 Days</a>

									<a (click)="age_range='31-60days'" class="tag tag--small" [ngClass]="{'is-selected':age_range=='31-60days'}">31-60 Days</a>

									<a (click)="age_range='61-120days'" class="tag tag--small" [ngClass]="{'is-selected':age_range=='61-120days'}">61-120 Days</a>

									<a (click)="age_range='>120days'" class="tag tag--small" [ngClass]="{'is-selected':age_range=='>120days'}">>120 Days</a>
								</div><!-- /.tags -->
							</div>
							<div class="section__filters">

								<div class="section__filter">
									<h4>Sort:</h4>

									<div class="select select--alt" [ngClass]="{'is-open':select_open_sort}" (click)="toggleSelectOpen('sort');">
										<div class="select__head">
											<h4>{{ getSortLabel(current_sort,false) ?  getSortLabel(current_sort,false)+" "+direction  : "Select" }}</h4>
										</div><!-- /.select__head -->

										<div class="select__list" (mouseleave)="toggleSelectOpen('sort');">
											<ul>
												<li *ngFor="let itm of sorts" (click)="select_option('sort',itm)">{{itm.label}}</li>
											</ul>
										</div><!-- /.select__list -->
									</div><!-- /.select -->
								</div><!-- /.section__filter -->
							</div><!-- /.section__filter -->
							</div>
						</div>
						</div>
						<div class="asset asset--alt" *ngFor="let H of History | ageFilter: age_range : 'date_added' |
										   searchFilter:assetSearch | sortByDate: current_sort: reverse ">
								<div class="asset__aside">
									<figure class="asset__image">
										<img src="{{H.asset_info[0].thumb ? H.asset_info[0].thumb : CS.default_img}}" alt="{{H.asset_info[0].asset_name}}" width="195" height="110">
									</figure><!-- /.asset__image -->
								</div><!-- /.asset__aside -->

								<div class="asset__main">
									<div class="asset__row">
										<div class="asset__entry"><h3>{{H.asset_info[0].asset_name}}</h3></div>
										<div class="asset__row">
											<div class="asset__col asset__col--1of2">
												<ul class="asset__info-list">
													<li *ngIf="H.asset_info[0].flight_copy">
														<strong>Usage Window: </strong>{{H.asset_info[0].flight_copy}}
													</li>

													<li><strong>Date Downloaded:</strong> {{H.date_added | date:'mediumDate'}}</li>

												</ul><!-- /.asset__info-list -->
												<ng-container *ngIf="H.asset_info[0].asset_status=='active'">
													<a routerLink="/library/{{H.asset_info[0].asset_id}}/asset" class="link link--alt">Review Detail</a><br><br>
												</ng-container>

												<a (click)="reDownload(H)" class="link link--alt" [ngClass]="{'isDisabled': H.asset_info[0].asset_status=='expired'}">{{H.asset_info[0].asset_status=='expired'? 'Asset Expired' : H.file_downloaded? 'Re-download'+' ('+H.asset_info[0].file_size+')' : 'Download'+' ('+H.asset_info[0].file_size+')'}}</a>
											</div><!-- /.asset__entry -->

											<div class="asset__col asset__col--1of2" *ngIf="H.file_downloaded">
												<ul class="asset__info-list" >
														<li  *ngIf="H.results_submitted"><strong>Affidavit Status: </strong>{{getStatus(H)}}</li>
														<li  *ngIf="!H.results_submitted"><a (click)="openAff(H);" class="btn btn--size-1 ">Submit Affidavit</a></li>
														
														<li ><a  (click)="openCAR(H);" class="btn btn--size-2">Submit New Customization Request</a></li>
													<li *ngIf="H.custom_status!=='Request In Progress'"><a  (click)="viewReqs(H);" class="btn btn--size-2">View Existing Requests</a></li>
													</ul><!-- /.asset__info-list -->	
											</div><!-- /.asset__col asset__col-/-1of2 -->
										</div><!-- /.asset__row -->
									</div><!-- /.asset__row -->
								</div><!-- /.asset__main -->
							</div><!-- /.asset asset-/-alt -->

						</div><!-- /.assets -->
					</div><!-- /.tab -->
					<div class="tab customs" *ngIf="current_tab=='custom'">
						<div class="assets">
						<div class="section-utilities">
						<div class="shell">
							<div class="section__inner">
								
							<div class="section__tags">

								<div class="tags">
									<span>Age: </span>
									<a (click)="age_range='all'" class="tag tag--small" [ngClass]="{'is-selected':age_range=='all'}">All</a>

									<a (click)="age_range='<30days'" class="tag tag--small" [ngClass]="{'is-selected':age_range=='<30days'}"><30 Days</a>

									<a (click)="age_range='31-60days'" class="tag tag--small" [ngClass]="{'is-selected':age_range=='31-60days'}">31-60 Days</a>

									<a (click)="age_range='61-120days'" class="tag tag--small" [ngClass]="{'is-selected':age_range=='61-120days'}">61-120 Days</a>

									<a (click)="age_range='>120days'" class="tag tag--small" [ngClass]="{'is-selected':age_range=='>120days'}">>120 Days</a>
								</div><!-- /.tags -->
							</div>
							<div class="section__filters">
								<div class="section__filter">
									<h4>Sort:</h4>
									<div class="select select--alt" [ngClass]="{'is-open':select_open_custom_sort}" (click)="toggleSelectOpen('custom_sort');">
										<div class="select__head">
											<h4>{{ getSortLabel(current_custom_sort,true) ?  getSortLabel(current_custom_sort, true)+" "+direction  : "Select" }}</h4>
										</div><!-- /.select__head -->

										<div class="select__list" (mouseleave)="toggleSelectOpen('custom_sort');">
											<ul>
												<li *ngFor="let itm of custom_sorts" (click)="select_option('custom_sort',itm)">{{itm.label}}</li>
											</ul>
										</div><!-- /.select__list -->
									</div><!-- /.select -->
								</div><!-- /.section__filter -->
							</div><!-- /.section__filter -->
							</div>
							<div><a  (click)="openNonAssetCAR();" class="btn btn--size-2">Submit Non-Asset Customization Request</a></div>
						</div>
						</div>
						<div class="asset asset--alt" *ngFor="let H of Customizations | ageFilter: age_range : 'request_submitted' |
										   searchFilter:assetSearch | sortByDate: current_custom_sort: reverse ">
								<div class="asset__aside">
									<figure class="asset__image">
										<img src="{{H.customs_detail[0]?.activity[0]?.support && isImageFile(H.customs_detail[0]?.activity[0]?.support) ? H.customs_detail[0]?.activity[0]?.support : CS.default_img}}" alt="{{H.custom_name}}" width="195" height="110">
									</figure><!-- /.asset__image -->
								</div><!-- /.asset__aside -->

								<div class="asset__main">
									<div class="asset__row">
										<div class="asset__entry"><h3>{{H.asset_name}}</h3></div>
										<div class="asset__row custom__row">
											<div class="asset__col asset__col--1of2">
												<ul class="asset__info-list">
													<li >
														<strong>Request Submitted: </strong>{{H.request_submitted | date:'mediumDate'}}
													</li>
													<li >
														<strong>Request ID: </strong>{{H.request_id}}
													</li>


													<li><strong>Status:</strong> {{H.request_status}}</li>

												</ul><!-- /.asset__info-list -->
												
											</div><!-- /.asset__entry -->
											
											<a  (click)="toggleExpand(H)" class="btn btn--size-1">{{H.expand? 'HIDE':'VIEW'}} DETAIL</a>

											
										</div><!-- /.asset__row -->
										<div class="accordion" >
											<div class="accordion__section  "  [ngClass]="{'is-open': H.expand}">
												<div class="accordion__body">
													<div class="accordion__body-head" >
														<b>{{H.customs_detail[0]?.activity[0]?.descr}} {{H.request_approved>'2022-01-01'? "APPROVED":''}}</b>
													</div><!-- /.accordion__body-head -->
													<div class="message-container">

														<ng-container *ngFor="let a of H.customs_detail">
															<ng-container *ngFor="let m of a.activity">

																<div class="message-bubble" [ngClass]="{'outgoing':m.reviewer_comment}" >

																	<p>{{m.reviewer_comment? "Reviewer: ":"User: "}}{{m.descr}} {{m.user_comment}} {{m.reviewer_comment}}</p>
																	<!--<a *ngIf="m.support" href="{{m.support}}" TARGET="_blank">DOWNLOAD</a>-->
																	<a *ngIf="m.support" (click)="S3S.secureDownload(m.support)" target="_blank">DOWNLOAD</a>
																	<span class="message-time">{{a.created_date | date: 'shortDate'}}</span>
																</div>
															</ng-container>

														</ng-container>
													</div>


													<div class="accordion__body-content" *ngIf="!H.request_approved>'2022-01-01' ||H.request_approved==null">

														<div class="newcomment">
															<div class="form__row">
																<textarea [(ngModel)]="H.user_comment" placeholder="Add response and/or upload file and click submit below"></textarea>
															</div>
															<div class="form__row">
																<div class="file-box file-box-assets" style="margin-bottom:1.5rem;">
																	<p style="margin-bottom:0;">
																		<a *ngIf="H.url && !Files_assets" href="{{H.url}}" target="_blank">Attachment</a>
																		<span *ngFor="let upload of S3S.uploads_assets" ><br>New Attachment: 
																			<a href="upload.Location" target="_blank">{{upload.filename}}  - {{upload.fileprogress}}% - Uploaded</a>
																		</span>						
																	</p>


																	<label for="itemzurl" class="btn btn--small">
																		<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
																			<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
																		</svg>

																		Browse
																	</label>
																	<input id="itemzurl" #itemzurl type="file" style="display:none" (change)="uploadFiles($event,'assets')"  required/>
																	<div class="upload_status" *ngIf="Files_assets?.length > 0 && S3S.uploads_assets?.length==0">
																		<div *ngFor="let file of Files_assets">
																			<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>
																		</div>
										<!-- upload errors-->
																		<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
																			We had trouble uploading the file. Please refresh the page and try again.
																			<p>If the problem persists please supply us with the following details</p>

																		</div>
																		<div>{{Error}}</div>
																  </div>
																<div><input (click)="submitComment(H, false)" class="btn form__btn" value="Submit" name="submitcomment"></div>	
																</div><!-- /.file-box -->
															</div><!-- /.form__row -->
															<div class="form__actions" >

							</div><!-- /.form__actions -->	


														</div>
													</div><!-- /.accordion__body-content -->
												</div><!-- /.accordion__body -->



									</div><!-- /.accordion__section -->

										</div><!-- /.accordion js-accordion -->
				
										
										
									</div><!-- /.asset__row -->
								</div><!-- /.asset__main -->
							</div><!-- /.asset asset-/-alt -->

						</div><!-- /.assets -->
					</div><!-- /.tab -->
				</div><!-- /.tabs -->
			</div><!-- /.shell -->
		</section><!-- /.section-tabs -->
<!--
						<section class="section-search">
							<div class="shell">
								<div class="search">
									<form action="?" method="get">
										<label for="search-assets" class="hidden">Search</label>

										<input type="search" name="search-assets" id="search-assets" [(ngModel)]="assetSearch" placeholder="Search Asset History" class="search__field" (change)="updateSearch('asset')" (focusout)="searchScroll()">

										<button type="submit" value="GO" class="search__btn">
											<img src="assets/images/svg/search--large.svg" alt="">
										</button>

										<button (click)="assetSearch=null" class="search__exit">
											<img src="assets/images/svg/exit-button.svg" alt="">
										</button>
									</form>
								</div>
							</div>
						</section>-->	

