import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlerService {

  constructor() { }

  /** Catch any errors in HTTP Rest call **/
  handleError<T> (result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error('caught',error); // log to console instead

      //response object
      let response = error;
      let errorStatus = error.status;

      //Switch statement for error handling
      switch(errorStatus){

        //Form Input error
        case 401:
        console.log('input validation error');
        response.errorMsg = 'Please verify all fields have values';
        console.log(response.errorMsg);
        break;

        //Age input error
        case 402:
        console.log('range validation error');
        response.errorMsg = 'The input age does not meet the requirement';
        console.log(response.errorMsg);
        break;

        //Validation error
        case 403:
        console.log('duplicate entry');
        response.errorMsg = 'You may only enter once per day';
        console.log(response.errorMsg);
        break;

        //Not Found Error
        case 404:
        console.log('not found error');
        response.errorMsg = 'Not Found error. Please refresh page';
        console.log(response.errorMsg);
        break;

        //Email Validation Error
        case 406:
        console.log('Email Validation');
        response.errorMsg = 'Please enter a valid email address.';
        console.log(response.errorMsg);
        break;

        //Server Error
        case 500:
        response.errorMsg = 'There was an error in the submission. Please try again';
        console.log(response.errorMsg);
        break;
			 
	    //Server Error
        case 200:
        //response.errorMsg = 'There was an error in the submission. Please try again';
        console.log('replace with',response.error.text);
        break;

        //Default error
        default:
        console.log('unknown error');
        response.errorMsg = 'Unknown error. Please refresh page';
        console.log('here',response.errorMsg);
        break;
      }

      // Let the app keep running by returning an empty result.
      return of(error as T);
    };
  }

}
