  
    <div class="box__body">
      <div class="table table--manage-account-list">
        <table>
          <thead>
            <tr>
				
              <th>STATUS</th>

              <th><a (click)="sortBy('template_id')">Template ID</a></th>

              <th><a (click)="sortBy('template_name')">Template Name</a></th>

              <th><a (click)="sortBy('email_subject')">Subject</a></th>	
			
              <th  *ngIf="Status!=='draft'"><a (click)="sortBy('sent')">Sent</a></th>
				
			  <th><a (click)="sortBy('last_update')">Last Updated</a></th>

              <th>CTA</th>
			  	<th  *ngIf="Status!=='draft'">Sent</th>
				<th  *ngIf="Status!=='draft'">Recipients</th>
				<th  *ngIf="Status!=='draft'">Opens</th>
				<th  *ngIf="Status!=='draft'">Clicks</th>
				<th  *ngIf="Status!=='draft'">Bounces/Issues</th>
				

            </tr>
          </thead>

          <tbody>
            <tr *ngFor=" let email of Emails | statusFilter: 'STATUS' : Status | sortBy: reverse : propertyName"><!--| filter : {status: 'accepted'} : true | filter : category_promo_filter | orderBy:propertyName:reverse-->
    
			<td>
                <h6>{{email.template_id}}</h6>
              </td>

              <td>
                <h6>{{email.STATUS}}</h6>
              </td>


              
              <td>
                <h6>{{email.template_name}}</h6>
              </td>

              <td>
                <h6>{{email.email_subject}}</h6>
              </td>
				
			  <td *ngIf="Status!=='draft'">
                <h6 >{{email.sent | date: "shortDate"}}</h6>
              </td>

              <td>
                <h6 *ngIf="email.last_update!=='0000-00-00 00:00:00'">{{email.last_update | date: "shortDate"}}</h6>
              </td>

              <td>
                <a *ngIf="Status=='draft'" (click)="edit_email_template(email.template_id)"  class="btn btn--purple">EDIT/STAGE</a>
				<!--<a *ngIf="Status!=='draft'" (click)="EMS.adminGetSentPreview(email.template_id)"  class="btn btn--purple">VIEW</a>-->
				<a *ngIf="Status!=='draft'" (click)="EMS.reuse_existing_template(email.template_id, email.adhoc)"  class="btn btn--purple">REUSE</a>
				<a *ngIf="Status!=='draft'" target="_blank" (click)="processMailStats(email.template_id)" class="btn btn--purple">Update Stats</a>
				<a *ngIf="Status!=='draft'" target="_blank" href="https://api.hallmarkmediahub.com/lib/reports/campaign_detail.php?id={{email.template_id}}" class="btn btn--purple">Stat Detail</a>
				
              </td>
				<td  *ngIf="Status!=='draft'"><h6>{{email.stats.Sends}}</h6></td><!---->
			    <td  *ngIf="Status!=='draft'"><h6>{{email.stats.Recipients}}</h6></td>
				<td  *ngIf="Status!=='draft'"><h6>{{email.stats.Opens}}</h6></td>
				<td  *ngIf="Status!=='draft'"><h6>{{email.stats.Clicks}}</h6></td>
				<td *ngIf="Status!=='draft'"><h6>{{email.stats.Issues}}</h6></td>
				
				
            </tr>
          </tbody>
        </table>
      </div><!-- /.table -->
    </div><!-- /.box__body -->
<app-alert></app-alert>
  

