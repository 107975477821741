<div *ngFor="let network of Networks | preciseFilter: 'network_short': ntwk | statusFilter: 'detail': '1'" >
	<div class="hero hero--secondary" >
		<div class="shell">{{ntwk}} - {{network.network_short}}
			<div class="hero__image" style="background-image: url(assets/images/temp/hero-img-1.jpg);"></div><!-- /.hero__image -->

			<div class="hero__logo">
				<img src="{{network.network_logo}}" alt="{{ntwk}}-{{network.network_full}}">	
			</div><!-- /.hero__logo -->

			<div class="hero__inner">
							

				<swiper class="swiper slider-cards"  [config]="config">
					
						<ng-template swiperSlide  class="swiper-slide slider__slide" 
						 *ngFor="let item of Content | statusFilter: 'network_id': network.network_id | statusFilter: 'page_feature': '0' | sortBy: 'asc' : 'order'" data-hash="item.content_id">
							<div class="card">
								<div class="card__inner">
									<div class="card__front">
										<figure class="card__background">
											<img src="{{item.artwork}}" alt="{{item.header}}" width="589" height="330">
										</figure><!-- /.card__background -->
									</div><!-- /.card__front -->

									<div class="card__back">
										<div class="card__entry">
										<h2 [innerHtml]="item.headline"></h2>

										<div class="card__info" *ngIf="item.subhead">
											<p [innerHtml]="item.subhead"></p>
										</div><!-- /.card__info -->

											<p [innerHtml]="item.copy"></p>
											<a *ngIf="item.video=='1'" (click)="launchVid(item.reference);CS.trackActivity('video',item.content_id)" class="btn  btn--purple card__btn mr1">Play Video</a>
							

											<a *ngIf="item.content_type!=='external'  && item.cta!=='none'" (click)="CS.followLink(item.content_type,item.reference, ntwk, item.content_id)"
											   class="btn  btn--purple card__btn">{{item.cta? item.cta : 'View Assets'}}</a>
											<!--routerLink="/library/{{item.reference}}/{{item.content_type}}" href="{{item.reference}}"-->
											<a *ngIf="item.content_type=='external'  && item.cta!=='none'"
											   (click)="CS.followLink('external',item.reference, ntwk, item.content_id)"
											    target="_blank" class="btn  btn--purple  card__btn">{{item.cta? item.cta : 'Visit Site'}}</a>

										</div><!-- /.card__entry -->
									</div><!-- /.card__back -->
								</div><!-- /.card__inner -->

								<h6>{{item.undertag}}</h6>
							</div><!-- /.card -->
						</ng-template><!-- /.swiper-slide slider__slide -->
					

					

				</swiper><!-- /.swiper -->

			</div><!-- /.hero__inner -->
		</div><!-- /.shell -->
	</div><!-- /.hero -->

	<section class="section section--purple" *ngFor="let item of Content | statusFilter: 'network_id': network.network_id | statusFilter: 'page_feature': '1'">
		<div class="shell">
			<div class="box">
				<div class="grid">
					<div class="grid__col grid__col--size-1" >
						<div class="box__content">
							<h2 [innerHtml]="item.headline"></h2>

							<h4 [innerHtml]="item.subhead"></h4>
							
							<p [innerHtml]="item.copy"></p>			

							<a *ngIf="item.content_type!=='external' && item.cta!=='none'" (click)="CS.followLink(item.content_type,item.reference,ntwk, item.content_id)" class="btn">{{item.cta? item.cta : 'View Assets'}}</a>
											<!--routerLink="/library/{{item.reference}}/{{item.content_type}}" href="{{item.reference}}"-->
							<a *ngIf="item.content_type=='external' && item.cta!=='none'"  
							   (click)="CS.followLink('external',item.reference,ntwk, item.content_id)"
								 class="btn">{{item.cta? item.cta : 'Visit Site'}}</a>
							
						</div><!-- /.box__content -->
					</div><!-- /.grid__col -->

					<div class="grid__col grid__col--size-2">
						<div class="box__image">
							<img src="{{item.artwork ? item.artwork  : network.featured_default}}" alt="{{item.headline}}">
						</div><!-- /.box__image -->
					</div><!-- /.grid__col -->
				</div><!-- /.grid -->
			</div><!-- /.box -->
		</div><!-- /.shell -->
	</section><!-- /.section -->
	
	

	<section class="section section--cards padding-5">
		<div class="shell">
			<div class="section__head">
				<h3>Recently Uploaded Assets</h3>
			</div><!-- /.section__head -->

			<div class="cards-secondary cards-secondary-recent">
				<div class="grid">
					<ng-container *ngFor="let asset of getNetworkAssets(Assets) ;let  i=index">
						<div class="grid__col grid__col--1of4"  *ngIf=" i < 4 ">
							<div class="card-secondary"> 
								<div class="card__image">
									<img src="{{asset.thumb ? asset.thumb : network.thumb_default}}" alt="{{asset.asset_name}}">
								</div><!-- /.card__image -->

								<div class="card__content">
									<p [innerHtml]="asset.asset_name"></p>

									<div class="card__actions">
										<a (click)="CS.followLink('asset',asset.asset_id,ntwk, asset.asset_id)"
										   class="btn">View Asset</a>
									</div><!-- /.card__actions -->
								</div><!-- /.card__content -->
							</div><!-- /.card-secondary -->
						</div><!-- /.grid__col -->
					</ng-container>

				</div><!-- /.grid -->
			</div><!-- /.cards-secondary -->

			<div class="section__actions">
				<a routerLink="/library/{{ntwk}}/network" class="link link--alt">
					View All Recent Assets
				</a>
			</div><!-- /.section__actions -->
		</div><!-- /.shell -->
	</section><!-- /.section -->

	<section class="section section--cards section--spacing-1 padding-11" >
		<div class="shell">
			<div class="section__head">
				<h3>Resources</h3>
			</div><!-- /.section__head -->

			<div class="cards-secondary cards-secondary--spacing-1">
				<div class="grid">
					<div class="grid__col grid__col--1of3">
						<div class="card-secondary">
							<div class="card__content">
								<div class="card__info">
									<span>Brand</span>
								</div><!-- /.card__info -->

								<ul class="card__links">
									<ng-container *ngFor="let item of network.resources | statusFilter: 'network_category': 'brand' ">
										<li  *ngIf="item.network_short=='all' || item.network_short==ntwk">
											<a (click)="CS.followLink('network_resource',item.path,ntwk, item.asset_id)" target="_blank" class="link link--alt ">
												<img src="assets/images/svg/ico-document.svg" alt="">
												{{item.program}}
											</a>
										</li>
									
									</ng-container>
									
						
								</ul><!-- /.card__links -->
							</div><!-- /.card__content -->
						</div><!-- /.card-secondary -->
					</div><!-- /.grid__col -->
					<div class="grid__col grid__col--1of3"  *ngIf="ntwk !=='hesp' && ntwk !=='hmmore' && ntwk !=='hplus'">
						<div class="card-secondary">
							<div class="card__content">
								<div class="card__info">
									<span>FCC Certifications</span>
								</div><!-- /.card__info -->

								<ul class="card__links">
									<ng-container *ngFor="let item of network.resources | statusFilter: 'network_category': 'fcc' ">
										<li  *ngIf="item.network_short=='all' || item.network_short==ntwk">
											<a (click)="CS.followLink('network_resource',item.path,ntwk, item.asset_id)" target="_blank" class="link link--alt ">
												<img src="assets/images/svg/ico-document.svg" alt="">
												{{item.program}}
											</a>
										</li>
									
									</ng-container>
	
						
								</ul><!-- /.card__links -->
							</div><!-- /.card__content -->
						</div><!-- /.card-secondary -->
					</div><!-- /.grid__col -->

					<div class="grid__col grid__col--1of3"  *ngIf="ntwk !=='hesp' && ntwk !=='hmmore' && ntwk !=='hplus'">
						<div class="card-secondary">
							<div class="card__content">
								<div class="card__info">
									<span>Technical Information Sheet</span>
								</div><!-- /.card__info -->

								<ul class="card__links">
									<ng-container *ngFor="let item of network.resources | statusFilter: 'network_category': 'technical' ">
										<li  *ngIf="item.network_short=='all' || item.network_short==ntwk">
											<a (click)="CS.followLink('network_resource',item.path,ntwk, item.asset_id)"
											   
											   target="_blank" class="link link--alt ">
												<img src="assets/images/svg/ico-document.svg" alt="">
												{{item.program}}
											</a>
										</li>
									
									</ng-container>

								<!--	<li>
										<a href="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/assets/Hallmark+Media+Tech+Sheet_Aug+2022.pdf" target="_blank" class="link link--alt">
											<img src="assets/images/svg/ico-document.svg" alt="">

											Technical Information Sheet
										</a>
									</li>-->
								</ul><!-- /.card__links -->
							</div><!-- /.card__content -->
						</div><!-- /.card-secondary -->
					</div><!-- /.grid__col -->

				</div><!-- /.grid -->
			</div><!-- /.cards-secondary -->

			</div><!-- /.shell -->
	</section><!-- /.section -->
	
	
</div>
<app-home-preview></app-home-preview>
