import { Component, OnInit, OnDestroy } from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormGroup,FormsModule }   from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
	private ngUnsubscribe = new Subject<void>();
	current_status="accepted";
	Users: any |[];
	Profile:any |[];
	role:any |[];
	select_open=false;
	propertyName="created_date";
	reverse="desc";
	user_edit: any |[];
  constructor(public CS: ContentManagerService, public AS: AssetsService, public PS: ProfileService, public ADM: AdminService, public router: Router){ }

  ngOnInit(): void {
	  console.log('started');
	  this.PS.getRole().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.role = data; 
		  if(this.role!=='admin'){
			  this.router.navigate(['/home']);
		  }

	  });
	  this.ADM.updateAdmin();
	    //this.Profile=this.PS.profileObj.getValue();
	   this.ADM.getUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Users = data; console.log('users', this.Users);
	  });
	  this.ADM.current_user_detail=null;
	 
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
	toggleSelectOpen(){
		this.select_open=!this.select_open;
	}
	editUser(user){
		//get assets, results, customizations for user
		this.AS.updateAssets(user.profile_id);
		//this.user_edit=user;
		this.ADM.current_user_detail=user;
		//console.log('user is', user);
		this.PS.editProfile('admin');
		this.router.navigate(['admin/users/'+user.profile_id]);
		//this.CS.alert_message="Not yet available.";
		//this.CS.showAlert=true;
	}
	approveUser(id){
		this.ADM.approveUser(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
		   var strcheck = JSON.stringify(data);
		   if(strcheck.includes('uccess') ){
				console.log('succeeded');
				this.CS.alert_message = "Success";
				this.CS.showAlert = true;
				this.ngOnInit();
		   }else{
				console.log('issue');
				this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>technical support</a>";
				this.CS.showAlert = true;
		   }
	   });	
	}
	denyUser(id){
		this.ADM.denyUser(id).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
		   var strcheck = JSON.stringify(data);
		   if(strcheck.includes('uccess') ){
				console.log('succeeded');
			    this.CS.alert_message = "Success";
				this.CS.showAlert = true;
				this.ngOnInit();
		   }else{
				console.log('issue');
				this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>technical support</a>";
				this.CS.showAlert = true;
		   }
	   });	
	}
	sortBy(field){
	  console.log('clicked'+this.propertyName+field+ this.reverse);
	  if(this.propertyName!==field){
		  this.propertyName=field;
		  this.reverse="asc";
		  console.log('no match'+this.reverse);
	  }else if(this.reverse=="asc"){
		  this.reverse="desc";
		   console.log('matchasc'+this.reverse);
	  }else{
		  this.reverse="asc";
		  console.log('matchdesc'+this.reverse);
	  }
  }
	processedUsers(){
		var arr=[];
		var that=this;
		this.Users.forEach(function(obj){
			obj.downloads=0;obj.pending=0;obj.inqueue=0;obj.removed=0;
			if(obj.assets){
				obj.assets.forEach(function(obj2){
					if(obj2.status=="PENDING"){obj.pending=obj2.assets;}
					if(obj2.status=="DOWNLOADED"){obj.downloads=obj2.assets;}
					if(obj2.status=="IN-QUEUE"){obj.inqueue=obj2.assets;}
					if(obj2.status=="REMOVED"){obj.removed=obj2.assets;}
				});
			}
		//	if(that.current_status="all"){
		//		arr.push(obj);	
		//	}else{
		//		if(obj.status==that.current_status){
					arr.push(obj);
		//		}
		//	}
		});
		return arr;
	}
	selectOption(val){
		this.current_status=val;
	}
}
