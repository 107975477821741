import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { EmailManagementService } from '../../services/email-management.service';
import { AdminService } from '../../services/admin.service';



@Component({
  selector: 'app-audience-overview',
  templateUrl: './audience-overview.component.html',
  styleUrls: ['./email-overview.component.css']
})
export class AudienceOverviewComponent implements OnInit {
   breadcrumbs: any |[];
  request_account=false;
  current_promo_yr=2021;
	alert_message='';
	showAlert=false;
	Assets: any |[];
	email_template: any |[];
    private ngUnsubscribe = new Subject<void>();

  
  public AdminRecipients: any | [];



  constructor(
    public PS: ProfileService, 
	 public AS: AdminService,
	 public EMS: EmailManagementService,
	 public CS: ContentManagerService,
	 public route: ActivatedRoute,
//	  public AMS: AccountManagementService,
	 public router: Router

		 ) { }

  ngOnInit(): void {
	  this.AS.updateAdmin();
	  this.AS.getAdminAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Assets=data;
	  });
	  
	  this.EMS.getEmailTemplates().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.email_template=this.pickTemplate(data);
		  console.log('adhoc is ',this.email_template.adhoc);
		  if(!this.email_template.adhoc ||this.email_template.adhoc==0 ){
			  console.log('not');
			   this.AS.getSellin().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
				  var temp=this.processAccounts(data);
				  this.AdminRecipients=temp;
				  console.log('sellin', this.AdminRecipients);
			  });
		  }else{
			  console.log('is');
			  this.AS.getUsers().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
				  var temp=this.processAccounts(data);
				  this.AdminRecipients=temp;
				  console.log('uzers', this.AdminRecipients);
			  });
		  }
	  });
	   
  }
ngOnDestroy(){
	this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
}
  processAccounts(data){
	  data.forEach(function(value, key){
		value.receive_email=false;
	  });
	  return data;
  }
pickTemplate(data){
	  var arr=[];
		 var that=this;
	  data.forEach(function(obj){
		  console.log(obj.template_id +"-"+ that.EMS.current_template_id);
		if(obj.template_id==that.EMS.current_template_id){
			arr=obj;
		}
	  });
	  return arr;
  }
	admin_send_accounts_email(template_id, audience){
		var json_data = [];
		var account_recipients = [];
		var filtr=this.AS.accountFilter;
		var recips=0
//		let arr = [];  
//			Object.keys(filtr).map(function(key){  
//				arr.push({[key]:filtr[key]})  
//				console.log('key',key, filtr[key]);
//				return arr;  
//			})
//		console.log('arr', arr);
		//isjson
		//convert it, break it out and apply
		//if(this.AS.accountFilter !== null && this.AS.accountFilter !==[] && this.AS.accountFilter !==''){
		//	arr.forEach(function( value, key){
		//		console.log('val',value);
		//	});
		//}else{
		//	console.log('filter is null');
		//}
		//console.log(this.AS.accountFilter.length);
		//Loop through object and check if receive email is selected
		this.AdminRecipients.forEach(function(value,key){			
		  if(value.receive_email == true){
			  account_recipients.push({email: value.email, first_name: value.first_name});
			  recips++;
		  }
		});
		this.CS.showAlert=true;
		this.CS.alert_message="Processing "+ recips +" recipients, please be patient."
	
		
		
		
		
		json_data.push({template_id: template_id, recipients: account_recipients})
		var data= JSON.stringify(json_data);
		console.log(data);
		
		this.EMS.adminSendEmails(template_id, json_data).pipe(takeUntil(this.ngUnsubscribe)).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
			(response) => {
				console.log('emailresponse', response);
				var errcheck = JSON.stringify(response);
				var id=errcheck.replace(/['"]+/g, '')
				//this.alert_message=response;
				if(errcheck.includes('Unknown error')){
					  this.CS.alert_message="We've encountered a submission error.  Please try again or reach out to customer support.";
					  this.CS.showAlert=true;
					console.log('err');
				}else if(errcheck.includes('No ')||errcheck.includes('borted')){
					  this.CS.alert_message=errcheck;
					  this.CS.showAlert=true;
					console.log('err');
				}else{
					this.CS.alert_message="Template #"+template_id+ " WAS SENT TO " + account_recipients.length + " accounts.";
					this.CS.showAlert=true;
				    console.log('success');
					//this.router.navigate(['/email-management']);
				}
			}
		)
		
	  }
 

}

