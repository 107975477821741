<div class="main__body">
	<section class="section section-request">
		<div class="shell">
			<div class="section__head">
					<div class="title">
						<h1>Email Template Detail</h1>
					</div>
				    <div class="participate">
						<div class="back-btn">
							<a routerLink="/admin/emails"  class="btn btn--purple">Back to Email List</a>
						</div>
						<p *ngIf="EMS.create_email_template_admin==true">Complete the form below.</p>
						<p *ngIf="EMS.create_email_template_admin==false">Review and edit the form below below as needed.</p>
						<p>Upon completion, save the form and you can choose to preview your email, send a test, or proceed to select your audience and launch the email.  You can also use the back button above to return to the email list and launch the email at another time.</p>
				    </div>

			</div><!-- /.section__head -->
			<div class="section__form">
				<div class="form-contact form-contact--dev form-contact--dev-alt ">
					<form #newEmailForm="ngForm" name="newEmailForm" novalidate>
						<div class="form__title" *ngIf="email_template.template_id!==null">
							#{{email_template.template_id}} - {{email_template.template_name}}
						</div><!-- /.section__head -->
						<div class="form__body">
							<div class="form__row form__row--small">
								<label for="template_name" class="form__label">Template Name</label>
								<div class="form__controls">
									<input type="text" class="field field--dev field--purple" #eml_template_name="ngModel" [(ngModel)]="email_template.template_name" name="template_name" id="template_name">
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->

							<div class="form__row form__row--small">
								<label for="subject" class="form__label">Subject Line</label>
								<div class="form__controls">
									<input type="text" class="field field--dev field--purple" #eml_subject="ngModel" [(ngModel)]="email_template.email_subject" name="subject" id="subject">
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->
							
							
							<div class="form__row">
								<div class="file-box file-box-assets" style="margin-bottom:1.5rem;">
									<p style="margin-bottom:0;">Newsletter HTML File: 
										<a *ngIf="email_template.email_body_1 && !Files_html" href="{{email_template.email_body_1}}" target="_blank">Current Asset</a>
										<span *ngFor="let upload of S3S.uploads_html" ><br>New HTML File: 
											<a href="upload.Location" target="_blank">{{upload.filename}}  - {{upload.fileprogress}}% - Uploaded</a>
										</span>						
									</p>
									<p class="form__error" *ngIf="submitted && !Files_html && !email_template.email_body_1">Please upload the asset file.</p>

									<label for="email_body_1" class="btn">
										<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
											<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
										</svg>

										{{email_template.email_body_1? 'Replace' : 'Upload'}}
									</label>
									<input id="email_body_1" #email_body_1 type="file" style="display:none" (change)="uploadFiles($event,'html')"  required/>
									<div class="upload_status" *ngIf="Files_html?.length > 0 && S3S.uploads_html?.length==0"><!--&& !S3S.uploadStatus=='Success!'">-->
										<div *ngFor="let file of Files_html">
											<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>

											<p class="form__error" *ngIf="up_inprogress && file.progressPercentage<100">Please wait until upload is complete.</p>
										</div>
		<!-- upload errors-->
										<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
											We had trouble uploading the file. Please refresh the page and try again.
											<p>If the problem persists please supply us with the following details</p>

										</div>
										<div>{{Error}}</div>
								  </div>
								</div><!-- /.file-box -->
							</div><!-- /.form__row -->

							<div class="form__row">
								<div class="file-box file-box-assets" style="margin-bottom:1.5rem;">
									<p style="margin-bottom:0;">Add New Artwork: 
										<span *ngFor="let upload of S3S.uploads_email" ><br>New Artwork: 
											<a href="upload.Location" target="_blank">{{upload.filename}}  - {{upload.fileprogress}}% - Uploaded</a>
										</span>						
									</p>

									<label for="email_artwork_1" class="btn">
										<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
											<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
										</svg>Upload
									</label>
									<input id="email_artwork_1" #email_artwork_1 type="file" style="display:none" (change)="uploadFiles($event,'email')"  required/>
									<div class="upload_status" *ngIf="Files_email?.length > 0 && S3S.uploads_email?.length==0"><!--&& !S3S.uploadStatus=='Success!'">-->
										<div *ngFor="let file of Files_email">
											<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>

											<p class="form__error" *ngIf="up_inprogress && file.progressPercentage<100">Please wait until upload is complete.</p>
										</div>
		<!-- upload errors-->
										<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
											We had trouble uploading the file. Please refresh the page and try again.
											<p>If the problem persists please supply us with the following details</p>

										</div>
										<div>{{Error}}</div>
								  </div>
								</div><!-- /.file-box -->
							</div><!-- /.form__row -->						
							
						

							 
								   <!--test email recipient-->
							   <div class="form__row form__row--small" *ngIf="email_template.test_email">
								 <label for="test" class="form__label">Email Recipient</label>

								 <div class="form__controls">
								   <input type="text" class="field field--dev field--purple" #eml_recipient="ngModel" [(ngModel)]="email_template.recipient" name="test" id="test">
								 </div><!-- /.form__controls -->
								 <br>
								 <a (click)="admin_send_test_email(email_template,newEmailForm);" class="btn btn--purple btn--medium">Send Email</a>
							   </div><!-- /.form__row -->
						</div><!-- /.form__body -->
						<div class="form__actions">
							<div>
								<a (click)="email_template.custom_cta = false;reset_email_updates(email_template)" class="btn btn--purple-secondary btn--medium">Cancel</a>
								<a (click)="submitted = true;admin_save_template(email_template,newEmailForm);" class="btn btn--purple btn--medium">{{newEmailForm.touched ? 'Save Changes' : 'Confirm'}}</a>
							</div>
							<div *ngIf="submitted" class="form__row">		
								<a *ngIf="email_template.adhoc" (click)="this.AS.viewEmailPreview=true;" class="btn btn--purple btn--medium" target="_blank">Preview Email</a>
								<a *ngIf="!email_template.adhoc" href="{{email_template.email_body_1}}" class="btn btn--purple btn--medium" target="_blank">Preview Email</a>
								
								<a  (click)="email_template.test_email = true;" class="btn btn--purple btn--medium">Send Test Email</a>
								<a  (click)="admin_get_audience(email_template,newEmailForm);" class="btn btn--purple btn--medium">Select Audience</a>
							</div>
						</div><!-- /.form__actions -->
						
					</form>
				</div>
			</div>
		</div>
	</section>

</div>
<app-alert></app-alert>
<app-email-preview [email_template]="email_template" *ngIf="AS.viewEmailPreview"></app-email-preview>
	