import { Component, OnInit, Input, OnDestroy,ViewChild} from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { AdminService } from '../../services/admin.service';

import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';
import { S3UploadService } from '../../services/s3-upload-service.service';

@Component({
  selector: 'app-asset-detail',
  templateUrl: './asset-detail.component.html',
  styleUrls: ['../../admin/admin.component.css']
})
export class AssetDetailComponent implements OnInit {
 private ngUnsubscribe = new Subject<void>();
  @Input() asset: any |[];
  Profile: any |[];
  Partners: any |[];
  Networks: any |[];
  Campaigns: any |[];
  Types: any |[];
  Tags: any |[];
  select_open_campaigns=false;
  select_open_networks=false;
  select_open_partners=false;
  select_open_type=false;
  select_open_tags=false;
  other_type=false;
  other_tags=false;
  other_campaign=false;
  
  	
  submitted=false;
  up_inprogress=false;
  Files: any | [];
  Files_assets: any | [];
  Files_thumbs: any | [];
  Files_preview: any | [];
  file_upload_error=false;
  Error: any |[];
	

  constructor(public S3S:S3UploadService, public PS:ProfileService,  public ADM: AdminService, public AS: AssetsService, public CS: ContentManagerService, public router:Router) { }

  ngOnInit(): void {
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Profile=data;
		  console.log('Profile is', this.Profile);
	  });
	  this.CS.getNetworks().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Networks=data;
		  console.log('Networks is', this.Networks);
	  });
	  this.CS.getTags().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Tags=data;
		  console.log('Tags is', this.Tags);
	  });
	  this.CS.getCampaigns().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Campaigns=data;
		  console.log('Campaigns is', this.Campaigns);
	  });
	  this.CS.getTypes().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Types=data;
		  console.log('types is', this.Types);
	  });
	  this.CS.getPartners().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
		  this.Partners = data;
	  });
	  console.log('asset', this.asset);
	  this.asset.asset_start = new Date(this.asset.asset_start);
	  this.asset.asset_end = new Date(this.asset.asset_end);
	  if(!this.asset.asset_id){
		  this.asset={};
		 // this.asset.campaign=any |[];
		//  this.asset.networks:any |[];
		 // this.asset.tags:any |[];
	  }
	  console.log('asset', this.asset);

	 
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }
  useDefault(){
	  this.asset.thumb="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/hallmark_media_fallback.jpg";
  }
  includedElement(Q,val,el){
	  const isThere = (element) => element == val; 
       var i = (Q[el] ? Q[el].findIndex(isThere): -1);
     if (i > -1) {
		  return true;
      }else{
		  return false;
	  }
  }
  addElement(Q, val, el){
	  if(val==0){
		if(el=='tags'){
		  	val={ tag :this.asset.other_tags_fld};
	  	}else if(el=='campaign'){
			val={campaign_id: '0', campaign_name: this.asset.other_campaign_fld};
		}
	  }
	  console.log('clicked',val);
	  const isThere = (element) => element == val; 
      var i = (Q[el] ? Q[el].findIndex(isThere): -1);
	  Q[el]=(Q[el] ? Q[el] : []);
	  console.log('Q is',Q);
      //var i = Q[el].findIndex(isThere);
      if (i > -1) {
		  Q[el].splice(i, 1);
      } else {
		  Q[el].push(val);
      }
	  this['other_'+el]=false;
  }
  toggleVariable(el){
	  this[el]=!this[el];
  }
  toggleSelectOpen(Q,el){  
	  if(!this['select_open_'+el]){
		 this['select_open_'+el]=true;
	  }else{
		 this['select_open_'+el]=false;
	  }
  }
  uploadFiles(event, el) {
	    
		var files = event.target.files;

		console.log('started', files);
		console.log('started2', files.length);
        this['Files_'+el] = files;
		console.log('started3', this['Files_'+el].length);
		var that=this;

      if (files && files.length > 0){ 
		   this['Files_'+el][0].pathway=el+'/'; //setting S3 Path
			  //fileEvent($event,'uploads')
              this.S3S.uploadSingleFile(this['Files_'+el][0], el);
		  if(el=="assets"){
			  this.asset.file_size=this.formatFileSize(this['Files_'+el][0].size);
			  console.log('szck',this.asset);
		  }
      }
  };
formatFileSize(bytes: number): string {
  if (bytes < 1024) {
    return bytes + ' bytes';
  } else if (bytes < 1048576) {
    return (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes < 1073741824) {
    return (bytes / 1048576).toFixed(2) + ' MB';
  } else {
    return (bytes / 1073741824).toFixed(2) + ' GB';
  }
}

	
 checkFiles(){
	 var asst=this.asset;
	 if((!asst.path && !this.Files_assets) || (!asst.thumb && !this.Files_thumbs)||(!asst.video_preview && !this.Files_preview && asst.asset_type.includes('romo'))){
		 console.log(asst.path,this.Files_assets);
		 console.log(asst.thumb,this.Files_thumbs);
		 console.log(asst.video_preview,this.Files_preview);
		 return true;
	 }else{
		 return false;
	 }
 }
 checkTags(){
	 var asst=this.asset;
	 if(asst.tags.length>0 && asst.networks.length>0 && asst.campaign.length>0){
		 return true;
	 }
	 return false;
 }
 checkInProgress(){
	 if(this.S3S.uploads_thumbs[0] && this.S3S.uploads_thumbs[0].fileprogress<100){
		 console.log('thumbs',this.S3S.uploads_thumbs);
		 return true;
	 }else if(this.S3S.uploads_assets[0] && this.S3S.uploads_assets[0].fileprogress<100){
		 console.log('assets',this.S3S.uploads_assets);
		 return true;
	 }else if(this.S3S.uploads_preview[0]  && this.S3S.uploads_preview[0].fileprogress<100){
		console.log('preview',this.S3S.uploads_preview);
		 return true;
	 }else{
		 console.log('clear');
		 return false;
	 }
 }
 submitAsset(form){
	 this.submitted=true;
	 this.file_upload_error=this.checkFiles();
	 var asst=this.asset;
	 console.log(this.asset);
	 console.log(Array.of(this.asset));
	 if(form.valid && this.checkTags() && !this.file_upload_error){
		 
		 
		 asst.path=(this.Files_assets? this.S3S.uploads_assets[0].url: asst.path);
		 asst.thumb=(this.Files_thumbs? this.S3S.uploads_thumbs[0].url: asst.thumb);
		 asst.video_preview=(this.Files_preview? this.S3S.uploads_preview[0].url: asst.video_preview);
		 console.log('asset',this.asset);
		 console.log('type', typeof(this.asset));
		// var json_data=(asst.asset_id>0? [this.asset] : [this.asset[0]]);
		 var json_data=[this.asset];
		 console.log('json', json_data);
		 var id=this.Profile.profile_id;
		 this.AS.submitAssets(id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			   if(data == "fail"){
				   this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>customer support</a>";
				   this.CS.showAlert = true;
			   }else{
				   this.CS.updateContent();
				   this.AS.updateAssets(id);
				   this.ADM.updateAdmin();
				   this.S3S.uploads=[];
				   this.S3S.uploads_thumbs=[];
				   this.S3S.uploads_assets=[];
				   this.S3S.uploads_preview=[];
				   this.closeResults();
			   }
		   });
	  }
	 else{
		 console.log('asset', this.asset);
		 console.log('form', form);
	 }
 }
 closeResults(){
	 this.ADM.viewAsset=false;
 }
 select_option(el, val){
	 if(val=='other'){
		 this['other_'+el]=true;
		 this.select_open_type=false;
 
	 }else{
		 this.asset[el]=val;
	 }
 }
 addOther(el,val){
	 this.addElement(this.asset, val, el);
	 this['other_'+el]=false;
	 this.asset['other_'+el+'_fld']=null;
 }

}
