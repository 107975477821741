<div class="main__body">
	<section class="section section-request">
		<div class="shell">
			<div class="section__head">
					<div class="title">
						<h1>Email Template Detail</h1>
					</div>
				    <div class="participate">
						<div class="back-btn">
							<a routerLink="/admin/emails"  class="btn btn--purple">Back to Email List</a>
						</div>
						<p *ngIf="EMS.create_email_template_admin==true">Complete the form below.</p>
						<p *ngIf="EMS.create_email_template_admin==false">Review and edit the form below below as needed.</p>
						<p>Upon completion, save the form and you can choose to preview your email, send a test, or proceed to select your audience and launch the email.  You can also use the back button above to return to the email list and launch the email at another time.</p>
				    </div>

			</div><!-- /.section__head -->
			<div class="section__form">
				<div class="form-contact form-contact--dev form-contact--dev-alt ">
					<form #newEmailForm="ngForm" name="newEmailForm" novalidate>
						<div class="form__title" *ngIf="email_template.template_id!==null">
							#{{email_template.template_id}} - {{email_template.template_name}}
						</div><!-- /.section__head -->
						<div class="form__body">
							<div class="form__row form__row--small">
								<label for="template_name" class="form__label">Template Name</label>
								<div class="form__controls">
									<input type="text" class="field field--dev field--purple" #eml_template_name="ngModel" [(ngModel)]="email_template.template_name" name="template_name" id="template_name">
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->

							<div class="form__row form__row--small">
								<label for="subject" class="form__label">Subject Line</label>
								<div class="form__controls">
									<input type="text" class="field field--dev field--purple" #eml_subject="ngModel" [(ngModel)]="email_template.email_subject" name="subject" id="subject">
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->
							 
							 
							
							<div class="form__row form__row--small">
								  <label for="email_body_1" class="form__label">Email Body Copy</label>
								  <div class="form__controls">
									 <div  #editable #email_body_1 (blur)="setBody('body1',email_body_1.innerHTML )" [innerHTML]="email_template.email_body_1">
									  </div>
								  </div>
							  </div><!-- /.form__row -->
					

								<!--Checkbox Area-->
								<div class="form__row form__row--small account-checkbox email_cta" >
								   <h5>CTA Selection:</h5>
								   
								  
								 <div class="checkbox checkbox--purple">
								  <input type="checkbox" #eml_custom_cta="ngModel" [(ngModel)]="email_template.custom_cta" name="custom" id="custom">

								  <label for="custom" [ngClass]="{'selected' : email_template.custom_cta}">Custom CTA</label>
								 </div><!-- /.checkbox -->
							   </div><!-- /.account-checkbox-->

							   <div class="form__row form__row--small" *ngIf="email_template.custom_cta == true">
								 <label for="customcopy" class="form__label">Custom CTA Copy</label>

								 <!--custom cta section-->
								 <div class="form__controls">
								   <input type="text" class="field field--dev field--purple" #eml_copy="ngModel" [(ngModel)]="email_template.custom_cta_copy" name="customcopy" id="customcopy">
								 </div><!-- /.form__controls -->
							   </div><!-- /.form__row -->

							   <div class="form__row form__row--small" *ngIf="email_template.custom_cta == true">
								 <label for="customlink" class="form__label">Custom CTA Link</label>

								 <div class="form__controls">
								   <input type="text" class="field field--dev field--purple" #eml_copy="ngModel" [(ngModel)]="email_template.custom_cta_link" name="customlink" id="customlink">
								 </div><!-- /.form__controls -->
							   </div><!-- /.form__row -->

							   <!--test email recipient-->
							   <div class="form__row form__row--small" *ngIf="email_template.test_email">
								 <label for="test" class="form__label">Email Recipient</label>

								 <div class="form__controls">
								   <input type="text" class="field field--dev field--purple" #eml_recipient="ngModel" [(ngModel)]="email_template.recipient" name="test" id="test">
								 </div><!-- /.form__controls -->
								 <br>
								 <a (click)="admin_send_test_email(email_template,newEmailForm);" class="btn btn--purple btn--medium">Send Email</a>
							   </div><!-- /.form__row -->
						</div><!-- /.form__body -->
						<div class="form__actions">
							<div>
								<a (click)="email_template.custom_cta = false;reset_email_updates(email_template)" class="btn btn--purple-secondary btn--medium">Cancel</a>
								<a (click)="submitted = true;admin_save_template(email_template,newEmailForm);" class="btn btn--purple btn--medium">{{newEmailForm.touched ? 'Save Changes' : 'Confirm'}}</a>
							</div>
							<div *ngIf="submitted" class="form__row">		
								<a *ngIf="email_template.adhoc" (click)="this.AS.viewEmailPreview=true;" class="btn btn--purple btn--medium" target="_blank">Preview Email</a>
								<a *ngIf="!email_template.adhoc" href="{{email_template.email_body_1}}" class="btn btn--purple btn--medium" target="_blank">Preview Email</a>
								
								<a  (click)="email_template.test_email = true;" class="btn btn--purple btn--medium">Send Test Email</a>
								<a  (click)="admin_get_audience(email_template,newEmailForm);" class="btn btn--purple btn--medium">Select Audience</a>
							</div>
						</div><!-- /.form__actions -->
						
					</form>
				</div>
			</div>
		</div>
	</section>

</div>
<app-alert></app-alert>
<app-email-preview [email_template]="email_template" *ngIf="AS.viewEmailPreview"></app-email-preview>
	