import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';
import { Component, OnInit, OnDestroy,ViewChild} from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-home-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class HomePreviewComponent implements OnInit {
private ngUnsubscribe = new Subject<void>();
Assets: any |[];
	
  constructor(public AS: AssetsService, public CS: ContentManagerService) { }

  ngOnInit(): void {
	  this.AS.updateAssets(0);
	  this.AS.getAssets().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Assets=data;
		  console.log("reasset",this.Assets);
		  console.log("reasset2",this.CS.homePreviewId);
		  
	  });
  }
  ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }


}
