import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from "@angular/router";
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';

//Services
import { ErrorhandlerService } from './errorhandler.service';
import { ProfileService } from './profile.service';

//Environment
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ContentManagerService {
	summary=false;
	loginOpen=false;
	loginState="begin";
	alert_message=null;
	showAlert=false;
	vidOpen=false;
	homePreviewOpen=false;
	homePreviewId=null;
	default_img="https://s3.amazonaws.com/assets.hallmarkaffiliates/mediahub/networks/hallmark_media_fallback.jpg";
	private apiUrl = environment.apiUrl; 
	public reportsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public resourcesObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public networksObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public campaignsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public partnersObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public tacticsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public contentObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public tagsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public typesObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public guideObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	active_ntwk=0;
	
	

  constructor( private http: HttpClient,
	 private location: Location,
	 private PS : ProfileService,
	private router:Router,
	private errorhandlerService: ErrorhandlerService) { }

	viewAsset(){
		this.homePreviewOpen=false;
		this.router.navigate(['library/'+this.homePreviewId+'/asset']);
	}
	back(): void {
    	this.location.back()
  	}
	followLink(source,src_id, network, reportid){
		var url='';
		var ref='';
		var id=reportid;
		var target="";
		if(source=='external'){
			url=src_id;
			ref=url;
			target="_blank";
		}else if(source=='network_resource'){
			url=src_id;
			ref=network+source;
			target="_blank";
			console.log('url', url);
		}else{
			console.log(source,src_id, network, reportid);
			url='/library/' +src_id + '/' + source;
			ref = source;
			target="_self";
		}
		this.trackActivity(ref,id);
		setTimeout(function () {
			window.open( url, target);
		}, 200);
		
		
	}
	trackActivity(action,rid){
		var pid = localStorage.getItem('profile');
		var json_data=[];			
		json_data.push({activity:action, reference_id: rid,profile_id:pid});
		this.postActivity(pid, json_data).subscribe(data => {});	
	}
	
	closeWelcome(){
		this.vidOpen=false;
		localStorage.setItem('vid','played');	
	}
	closeHomePreview(){
		this.homePreviewOpen=false;
		this.homePreviewId=null;
		localStorage.setItem('previewID',null);	
		console.log('clicked',localStorage.getItem('previewID'));
	}
	updateContent(){
		this.pullReports().subscribe((response) => {
			this.reportsObj.next(response);
		});
		this.pullNetworks().subscribe((response) => {
			this.networksObj.next(response);
		});
		this.pullResources().subscribe((response) => {
			this.resourcesObj.next(response);
		});
		this.pullCampaigns().subscribe((response) => {
			this.campaignsObj.next(response);
		});
		this.pullPartners().subscribe((response) => {
			this.partnersObj.next(response);
		});
		this.pullTactics().subscribe((response) => {
			this.tacticsObj.next(response);
		});
		this.pullContent().subscribe((response) => {
			this.contentObj.next(response);
		});
		this.pullTags().subscribe((response) => {
			this.tagsObj.next(response);
		});
		this.pullTypes().subscribe((response) => {
			this.typesObj.next(response);
		});
		this.pullGuide().subscribe((response) => {
			this.guideObj.next(response);
			//console.log('gd', this.guideObj.getValue());
		});
	}
	
	
	loginBegin(){
		this.loginState="begin";
		this.loginOpen=true;
	}
	loginEnd(){
		this.loginState="begin";
		this.loginOpen=false;
		this.router.navigate(['/home/skip']);//.then(() => {window.location.reload(); })
	}
	
	getResources(){return this.resourcesObj;}
	getContent(){return this.contentObj;}
	getNetworks(){return this.networksObj;}
	getCampaigns(){return this.campaignsObj;}
	getPartners(){return this.partnersObj;}
	getTactics(){return this.tacticsObj;}
	getTags(){return this.tagsObj;}
	getTypes(){return this.typesObj;}
	getGuide(){return this.guideObj;}
	getReports(){return this.reportsObj;}
	
	pullNetworks():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'networks').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullResources():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'resources').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullReports():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'reports').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullGuide():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'guide').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullCampaigns():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'campaigns').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullPartners():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'partners').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullTactics():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'tactics').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullTypes():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'assettypes').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullTags():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'tags').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	pullContent():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'content').pipe(
			catchError(
				this.errorhandlerService.handleError() 
			)
		);
	}
	postActivity(id,json_data):Observable<any>{
		console.log('posted');
		return this.http.post<any[]>(this.apiUrl + 'activity/' + id, json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);

	}
	submitContent(id,json_data):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'content/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	submitResource(id,json_data):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'resource/'+id,json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}
	generateReport(json_data):Observable<any>{
		return this.http.put<any[]>(this.apiUrl + 'reports',json_data).pipe(
			catchError(this.errorhandlerService.handleError())
		);
	}

}
