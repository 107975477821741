import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assetFilter'
})
export class AssetFilterPipe implements PipeTransform {
    transform(items: any[], field : string, subfield : string, value : string): any[] {  
      if (!items) {  return [];}
		else
	  if (!value || value.length == 0){return items;} else {
 		  items.forEach(function(obj){
			  obj[field].forEach(function(obj2){
				  if(obj2[subfield]==value){
					  obj.valcheck=1;
				  }
			  });
		  });
		return items.filter(it => it.valcheck==1);  
	  }
    }
}