<footer class="footer-sticky">
	<div class="shell">
		<div class="footer__inner">
			<a href="#" class="logo">
				<img src="assets/images/logo--white@2x.png" alt="" width="116" height="48" />
			</a>
			
			<div class="footer__aside">
				<div class="checkbox checkbox--white">
					<input type="checkbox" id="footer_checkbox">

					<label for="footer_checkbox">I agree to the terms and conditions.</label>
				</div><!-- /.checkbox -->

				<a href="#" class="btn btn--size-8">
					<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
						<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75ZM1.624,5.657l1-1.179L4.958,7.222V0H6.375V7.23L8.714,4.478l1,1.179L5.67,10.417Z" fill="#582c83"/>
					</svg>
					Download Assets
				</a>
			</div><!-- /.footer__aside -->
		</div><!-- /.footer__inner -->
	</div><!-- /.shell -->
</footer><!-- /.footer -->