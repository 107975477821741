import { Component, OnInit, OnDestroy, Renderer2, ElementRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';



@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit, OnDestroy, AfterViewInit {
  Resources: any[] = [];
  vid = true;
  expandedIndex: number | null = null;

  // Declare tileOrder property
  tileOrder: { expanded: number; default: number } = { expanded: 0, default: 1 };

  @ViewChildren('tiles') tiles: QueryList<ElementRef>; // Access the tile elements

  constructor(
    public PS: ProfileService,
    public AS: AssetsService,
    public CS: ContentManagerService,
    public renderer: Renderer2 // Inject Renderer2
  ) {}

  private ngUnsubscribe = new Subject<void>();

  ngOnInit(): void {
    this.CS.getResources()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.Resources = data;
		console.log(this.Resources);
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
    // Access the tiles QueryList in the AfterViewInit hook
    this.tiles.changes.subscribe(() => {
      // Ensure the tiles are populated before using them
      if (this.tiles && this.tiles.length > 0) {
        // Perform any initialization or updates here
      }
    });
  }

  previewModal(asset) {
    console.log(asset);
    this.AS.previewAsset = '';
    this.AS.previewName = '';
    this.AS.previewOpen = true;
  }

  expandTile(index: number) {
    // Collapse the previously expanded tile (if any)
    if (this.expandedIndex !== null) {
      // Use Renderer2 to update styles
      if (this.tiles && this.tiles.toArray()[this.expandedIndex]) {
        this.renderer.removeStyle(
          this.tiles.toArray()[this.expandedIndex].nativeElement,
          'transform'
        );
      }
    }

    // Update the expanded index
    this.expandedIndex = index;

    // Reorder the tiles in the flexbox container
    this.tileOrder = this.generateTileOrder(index);

    // Use Renderer2 to update styles with a smooth transition
    if (this.tiles && this.tiles.toArray()[index]) {
      this.renderer.setStyle(
        this.tiles.toArray()[index].nativeElement,
        'transform',
        'scale(1.1)' // Adjust the scale and other styles as needed
      );
    }
  }

  isTileExpanded(index: number) {
    return this.expandedIndex === index;
  }

  // Add a function to generate tile order based on the expanded tile index
  generateTileOrder(expandedIndex: number) {
    return {
      expanded: 0,
      default: expandedIndex === null ? 1 : 2
    };
  }
}
