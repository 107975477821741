import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subCatFilter'
})
export class SubCatFilterPipe implements PipeTransform {
    transform(items: any[], field : string, subfield : string, value : string): any[] {  
      if (!items) return [];
      if (!value || value.length == 0) return items;
	 console.log('field is'+field+'subfield'+subfield+'value'+value);
      //return items.filter(it => it[field][subfield].toLowerCase().indexOf(value.toLowerCase()) !=-1);
		
		
//		items.filter(it => game.platforms.some(el => el.toLocaleLowerCase().includes(typed)))
    return items.filter(it => it[field].filter(fld => fld[subfield].toLowerCase().indexOf(value.toLowerCase()) !=-1) !=-1);
    }
}/*
	transform(items: any[], networkval: string,	campaignval: string, titleval: string, typeval: string, tagval: string): any[] {  
      if (!items) return [];
	  
   //   if (!value || value.length == 0 ) return items;
		networkval= networkval.toLowerCase();
		campaignval= campaignval.toLowerCase();
		titleval= titleval.toLowerCase();
		typeval= typeval.toLowerCase();
		tagval= tagval.toLowerCase();
		
		return items.filter( it =>
				it.networks.some(el => el.toLowerCase().includes(networkval))||
				it.campaign.some(el => el.toLowerCase().includes(campaignval))||
				it.title.toLowerCase().indexOf(titleval) > -1 ||
				it.asset_type.toLowerCase().indexOf(typeval) > -1 ||
				it.tags.some(el => el.toLowerCase().includes(tagval)));
 }
}*/