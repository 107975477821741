import { Component, OnInit, Input, OnDestroy,ViewChild} from '@angular/core';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

//Services etc
import { ProfileService } from '../../services/profile.service';
import { ContentManagerService } from '../../services/content-manager.service';
import { AssetsService } from '../../services/assets.service';
import { S3UploadService } from '../../services/s3-upload-service.service';



@Component({
  selector: 'app-submit-results',
  templateUrl: './submit-results.component.html',
  styleUrls: ['./submit-results.component.css']
})

export class SubmitResultsComponent implements OnInit {
  private ngUnsubscribe = new Subject<void>();
  
  video=true;
  @Input() asset: any |[];
  current_start_date=null;
  current_end_date=null;
  Profile: any |[];
  submitted=false;
  select_open_tactics=false;
  up_inprogress=false;
  Files: any | [];
  Error: any |[];
	

	
  constructor(public S3S:S3UploadService, public PS:ProfileService,public AS: AssetsService, public CS: ContentManagerService, public router:Router) { }

  ngOnInit(): void {
	  this.PS.getProfile().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => { 
		  this.Profile=data;
		  console.log('Profile is', this.Profile);
	  });	
	  //this.asset.usage.start_date='0000-00-00';
	  //this.asset.usage.end_date='0000-00-00';

	  console.log('asset', this.asset);
	 
  }
 ngOnDestroy(){
	this.ngUnsubscribe.next();
	this.ngUnsubscribe.complete();
  }


  includedTactic(Q,val){
	  //console.log('usage', Q.usage[0].tactics);
	  const isThere = (element) => element == val; 
      var i = Q.usage.tactics.findIndex(isThere);
      if (i > -1) {
		  return true;
      }else{
		  return false;
	  }
  }
  includedAccount(Q,val){
	   const isThere = (element) => element == val; 
      var i = Q.usage.accounts.findIndex(isThere);
      if (i > -1) {
		  return true;
      }else{
		  return false;
	  }
  }
  addTactic(Q, val){
	  console.log('clicked',val);
	  const isThere = (element) => element == val; 
      var i = Q.usage.tactics.findIndex(isThere);
      if (i > -1) {
		  Q.usage.tactics.splice(i, 1);
      } else {
		  Q.usage.tactics.push(val);
      }
  }
  addAccount(Q, val){
	  const isThere = (element) => element == val; 
      var i = Q.usage.accounts.findIndex(isThere);
      if (i > -1) {
		  Q.usage.accounts.splice(i, 1);
      } else {
		  Q.usage.accounts.push(val);
      }
  }
  toggleVariable(el){
	  this[el]=!this[el];
  }
  toggleSelectOpen(Q,el){
	  
	  if(!this['select_open_'+el]){
		 this['select_open_'+el]=true;
	  }else{
		 this['select_open_'+el]=false;
	  }
  }
  uploadFiles(event) {
		var files = event.target.files;

		console.log('started', files);
		console.log('started2', files.length);
        this.Files = files;
		console.log('started3', this.Files.length);
		var that=this;

      if (files && files.length > 0){ 
		   this.Files[0].pathway='uploads/'; //setting S3 Path
			  //fileEvent($event,'uploads')
              this.S3S.uploadSingleFile(this.Files[0], 'uploads');
     
      }
  };
  submitAffid(form){
	  console.log(form);
	  this.submitted=true;
	  this.up_inprogress=false;
	  var that=this;
	  if(this.Files.length>0 && this.Files[0].progressPercentage<100){
		  this.up_inprogress=true;
	  }else if(form.valid && this.S3S.uploads.length>0){
		  if(this.includedTactic(this.asset, 'Other') && this.asset.usage.other){
				this.addTactic(this.asset,'Other');
				this.addTactic(this.asset,this.asset.usage.other);
		  }
		  console.log(this.S3S.uploads);
		  console.log(this.Files);
		  this.S3S.uploads.forEach(function(obj){
			 that.asset.usage.files.push(obj.url); 
		  });
		  this.asset.usage.history_id=this.asset.history_id;
		  console.log(this.asset);
		  var json_data=[this.asset.usage];
		  var id=this.Profile.profile_id;
		  this.AS.submitResults(id,json_data).pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
			   if(data == "fail"){
				   this.CS.alert_message = "This submission encountered an error.  Please try again or contact <a href='mailto:support@hallmarkmediahub.com'>customer support</a>";
				   this.CS.showAlert = true;
			   }else{
				   this.CS.updateContent();
				   this.AS.updateAssets(id);
				   this.S3S.uploads=[];
				   this.closeResults();
			   }
		   });
	  } 	  
  }
	closeResults(){
		this.S3S.uploads=[];
		this.AS.viewResults=false;
	}

}
