<div class="modal modal--alt is-open"><!-- [ngClass]="{ 'is-open': AS.viewResults }"-->
	<div class="modal__inner">
		<div class="form form--modal form--secondary form--scroll">
			<form name="affidForm" #affidForm="ngForm" novalidate [ngClass]="{'submitted':submitted}">
		
				<header class="form__head">
					<h2>Submit Affidavit</h2>
				</header><!-- /.form__head -->

				<div class="form__body">
					<h3>Confirm Usage</h3>
					<p>Please update this information with the actual asset usage:</p>

					<div class="spots_data" *ngIf="video">
						<div class="form__row grid">
							<div class="grid__col--1of2">
								<label for="noofspots" class="form__label">Number of Spots Run:</label>

								<div class="form__controls ">
									<input type="text" class="field field--size-2" name="noofspots" id="noofspots"  [(ngModel)]="asset.usage.spots_run" required>
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->
							<div class="grid__col--1of3">
								<label for="noofzones" class="form__label">Number of Zones:</label>

								<div class="form__controls">
									<input type="text" class="field field--size-2" name="noofzones" id="noofzones"  [(ngModel)]="asset.usage.zones" required>
								</div><!-- /.form__controls -->
							</div><!-- /.form__row -->
						</div>

					</div>

					
					<div class="form__row">
						<label class="form__label">Usage Start Date*</label>

						<div class="form__controls">
							<input required type="text" class="field field--size-1" name="flight_start" id="flight_start"
								   matInput [matDatepicker]="picker1" [(ngModel)]="asset.usage.start_date" ><!--[(ngModel)]="" -->
							 <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
							<mat-datepicker #picker1></mat-datepicker>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					

					<div class="form__row">
						<label class="form__label">Usage End Date*</label>

						<div class="form__controls">
							<input required type="text" class="field field--size-1" name="flight_end" id="flight_end"
								   matInput [matDatepicker]="picker2" [(ngModel)]="asset.usage.end_date" >
							 <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
							<mat-datepicker #picker2></mat-datepicker>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<label for="textarea" class="form__label">Describe how this asset was used:</label>

						<div class="form__controls">
							<textarea name="textarea" id="textarea" class="field field--textarea" [(ngModel)]="asset.usage.description"  required></textarea>
						</div><!-- /.form__controls -->

					</div><!-- /.form__row -->
					

					<div class="form__row">
						<label class="form__label">Select Platform/Tactic(s):</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1 select--checks"
								 [ngClass]="{'is-open': select_open_tactics, 'select_error': submitted && asset.usage.tactics.length==0}"
								(click)="toggleSelectOpen(asset,'tactics');" id="tactic">
								<div class="select__head">
									<p>Tactic Type</p>
								</div><!-- /.select__head --><!-- [ngClass]="{'is-open': asset?.select_open_tactics}" -->

								<div class="select__list" (mouseleave)="toggleSelectOpen(asset,'tactics');">
									<ul>
										<li *ngIf="asset.asset_info[0].video_preview">
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addTactic(asset, 'On-Air');" [checked]="includedTactic(asset, 'On-Air')" id="on-air">

												<label for="on-air">On-Air</label>
											</div><!-- /.checkbox -->
										</li>
										<li>
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addTactic(asset, 'Social Media');" [checked]="includedTactic(asset, 'Social Media')" id="Social-Media">

												<label for="Social-Media">Social Media</label>
											</div><!-- /.checkbox -->
										</li>												
										<li>
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addTactic(asset, 'Newsletter/Eblast');" [checked]="includedTactic(asset, 'Newsletter/Eblast')" id="Newsletter-Eblast">

												<label for="Newsletter-Eblast">Newsletter/Eblast</label>
											</div><!-- /.checkbox -->
										</li>

										<li>
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addTactic(asset, 'Website');" [checked]="includedTactic(asset, 'Website')" id="Website">

												<label for="Website">Website</label>
											</div><!-- /.checkbox -->
										</li>

										<li>
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addTactic(asset, 'Print');" [checked]="includedTactic(asset, 'Print')" id="Print">

												<label for="Print">Print</label>
											</div><!-- /.checkbox -->
										</li>

										<li>
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addTactic(asset, 'Events');" [checked]="includedTactic(asset, 'Events')" id="Events">

												<label for="Events">Events</label>
											</div><!-- /.checkbox -->
										</li>
										<li>
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addTactic(asset, 'Reference/Internal');" [checked]="includedTactic(asset, 'Reference/Internal')" id="Reference">

												<label for="Reference">Reference/Internal</label>
											</div><!-- /.checkbox -->
										</li>
										<li>
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addTactic(asset, 'Other');" [checked]="includedTactic(asset, 'Other')" id="OtherTactic">

												<label for="OtherTactic">Other</label>
											</div><!-- /.checkbox -->
										</li>
									</ul>
								</div><!-- /.select__list -->
								<div class="form__row" *ngIf="includedTactic(asset, 'Other')">
									<div class="form__controls">
										<label for="other">Other:</label>
										<input placeholder="Describe 'Other'" type="text" class="field field--size-3" name="other" id="other" [(ngModel)]="asset.usage.other" 
										 #other="ngModel"/>
									</div><!-- /.form__controls -->
								</div><!-- /.form__row -->


							</div><!-- /.select -->
							<div class="cols cols--alt form__cols--size-1">
								<div class="col col--1of3" *ngFor="let tactic of asset.usage.tactics">
									<div class="checkbox checkbox--gray" >
										<input type="checkbox" (click)="addTactic(asset, tactic);" [checked]="includedTactic(asset, tactic)" id="{{tactic}}">
										<label for="{{tactic}}">{{tactic}}</label>
									</div><!-- /.checkbox -->
								</div><!-- /.col col-/-1of3 -->

							</div><!-- /.cols -->
							<p class="form__error" *ngIf="submitted && asset.usage.tactics.length==0">Please select Tactic(s).</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row" *ngIf="Profile?.dmas.length>0">
						<label class="form__label">Confirm Market(s):</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1 select--checks"
			 [ngClass]="{'is-open': select_open_accounts, 'select_error': submitted && asset.usage.accounts.length==0}"
								(click)="toggleSelectOpen(asset,'accounts');" >
								<div class="select__head">
									<p>Intended Markets</p>
								</div><!-- /.select__head --><!-- [ngClass]="{'is-open': asset?.select_open_tactics}" -->

								<div class="select__list" (mouseleave)="toggleSelectOpen(asset,'accounts');">
									<ul>
										<li *ngFor="let account of Profile.dmas">
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addAccount(asset, account.dma);" [checked]="includedAccount(asset,  account.dma)" id="{{account.dma}}">

												<label for="{{account.dma}}">{{account.dma}}</label>
											</div><!-- /.checkbox -->
										</li>
									</ul>
								</div><!-- /.select__list -->


							</div><!-- /.select -->
							<div class="cols cols--alt form__cols--size-1">
								<div class="col col--1of3" *ngFor="let acct of asset.usage.accounts">
									<div class="checkbox checkbox--gray" >
										<input type="checkbox" (click)="addAccount(asset, acct);" [checked]="includedAccount(asset, acct)" id="{{acct}}">
										<label for="{{acct}}">{{acct}}</label>
									</div><!-- /.checkbox -->
								</div><!-- /.col col-/-1of3 -->

							</div><!-- /.cols -->
							<p class="form__error" *ngIf="submitted && asset.usage.accounts.length==0">Please select Market(s).</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->


					<div class="form__row">
						<label for="reach" class="form__label">Estimated Reach/Impressions:</label>

						<div class="form__controls">
							<input type="text" class="field field--size-2" name="reach" id="reach" [(ngModel)]="asset.usage.reach"  required>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<div class="file-box">
							<h4>Upload Report</h4>
							<p class="form__error" *ngIf="submitted && S3S.uploads.length==0">Please upload any supporting file(s).</p>

							<p>Acceptable file types: .PDF, .XLS, .XLSX, .JPEG, .PNG, .ZIP</p>
<!--button-->
							<div  *ngIf="S3S.uploads.length > 0" class="uploaded">Files:
								<div *ngFor="let upload of S3S.uploads" >
									<div  >{{upload.filename}}  - {{upload.fileprogress}}% - Uploaded</div>
								</div>
							</div>
							
							<label for="asset_uploads" class="btn">
								<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
									<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
								</svg>

								Upload
							</label>
<!--input field-->
							<input id="asset_uploads" type="file" style="display:none" (change)="uploadFiles($event)"  required/>
<!--upload status 1-->
							
<!--uploaded status 2-->
							<div class="upload_status" *ngIf="Files?.length > 0" ><!--&& !S3S.uploadStatus=='Success!'">-->
								<div *ngFor="let file of Files">
									<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>
								</div>
<!-- upload errors-->
								<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
									We had trouble uploading the file. Please refresh the page and try again.
									<p>If the problem persists please supply us with the following details</p>

								</div>
								<div>{{Error}}</div>
								<p class="form__error" *ngIf="up_inprogress && file.progressPercentage<100">Please wait until upload is complete.</p>

						

						  </div>
						</div><!-- /.file-box -->
					</div><!-- /.form__row -->

					<div class="form__divider"></div><!-- /.form__divier -->
				</div><!-- /.form__body -->
				<div><p class="form__error form__error__alt" *ngIf="up_inprogress && Files[0].progressPercentage<100">Please wait until upload is complete</p></div>
				<div><p class="form__error form__error__alt" *ngIf="submitted && (affidForm.invalid || S3S.uploads.length==0)">Please complete the fields above</p></div>

				<div class="form__actions">
					<a (click)="submitAffid(affidForm)" class="btn form__btn"  name="submit_affidavit">Submit</a>
				</div><!-- /.form__actions -->
				
			</form>
		</div><!-- /.form -->

		<a (click)="closeResults();" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="">
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->
