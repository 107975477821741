<div class="modal modal--alt is-open"><!-- [ngClass]="{ 'is-open': AS.viewResults }"-->
	<div class="modal__inner">
		<div class="form form--modal form--secondary form--scroll">
			<form name="affidForm" #affidForm="ngForm" novalidate [ngClass]="{'submitted':submitted}">
		
				<header class="form__head">
					<h2>Submit Asset Customization</h2>
				</header><!-- /.form__head -->

				<div class="form__body">
					<h3 *ngIf="asset?.length>0">Customization Request for {{asset?.asset_info[0]?.asset_name}}:</h3>
					<h3 *ngIf="asset?.length==0">Customization Request for User Supplied Asset:</h3>
					<ng-container *ngIf="asset?.length==0|| asset?.CAR?.request_submitted=='0000-00-00'">
						<p>Submit your request below and check back for the latest reponses to your request:</p>

						<div class="form__row">
							<label for="textarea" class="form__label">Briefly describe your customization and its purpose:</label>

							<div class="form__controls">
								<textarea name="textarea" id="textarea" class="field field--textarea" [(ngModel)]="asset_description" required></textarea>
							</div><!-- /.form__controls -->

						</div><!-- /.form__row -->

						<div class="form__row">
							<div class="file-box">
								<h4>Upload Your Customized Asset</h4>
								<p class="form__error" *ngIf="submitted && S3S.uploads.length==0 && (asset.CAR?.fileurl==null || asset?.length==0)">Please upload a copy or screengrab of the customized assets(s).</p>
	<!--button-->
								<div  *ngIf="S3S.uploads.length > 0" class="uploaded">Files:
									<div *ngFor="let upload of S3S.uploads" >
										<div  >{{upload.filename}}  - {{upload.fileprogress}}% - Uploaded</div>
									</div>
								</div>

								<label for="asset_uploads" class="btn">
									<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
										<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
									</svg>

									Upload
								</label>
	<!--input field-->
								<input id="asset_uploads" type="file" style="display:none" (change)="uploadFiles($event)"  required/>
	<!--upload status 1-->

	<!--uploaded status 2-->
								<div class="upload_status" *ngIf="Files?.length > 0" ><!--&& !S3S.uploadStatus=='Success!'">-->
									<div *ngFor="let file of Files">
										<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>
									</div>
	<!-- upload errors-->
									<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
										We had trouble uploading the file. Please refresh the page and try again.
										<p>If the problem persists please supply us with the following details</p>

									</div>
									<div>{{Error}}</div>
									<p class="form__error" *ngIf="up_inprogress && file.progressPercentage<100">Please wait until upload is complete.</p>



							  </div>
							</div><!-- /.file-box -->
						</div><!-- /.form__row -->


						<div class="form__row">
								<label for="fileurl" class="form__label">Alternatively, please provide a link to your customized asset:</label>

								<div class="form__controls ">
									<input type="text" class="field " name="fileurl" id="fileurl"  [(ngModel)]="asset_fileurl" [required]="S3S.uploads.length==0">
								</div><!-- /.form__controls -->

						</div>
						<div class="form__divider"></div><!-- /.form__divier -->
					</ng-container>
					<ng-container *ngIf="asset?.CAR?.request_submitted>'0000-00-00'">
						<p>Update your request below as needed and check back for the latest reponses to your request:</p>

						<div class="form__row">
							<label for="textarea" class="form__label">Additional Comments:</label>

							<div class="form__controls">
								<textarea name="textarea" id="textarea" class="field field--textarea" required></textarea>
							</div><!-- /.form__controls -->

						</div><!-- /.form__row -->

						<div class="form__row">
							<div class="file-box">
								<h4>Upload An updated customization</h4>
								<p class="form__error" *ngIf="submitted && S3S.uploads.length==0">Please upload a copy or screengrab of the customized assets(s).</p>
	<!--button-->
								<div  *ngIf="S3S.uploads.length > 0" class="uploaded">Files:
									<div *ngFor="let upload of S3S.uploads" >
										<div  >{{upload.filename}}  - {{upload.fileprogress}}% - Uploaded</div>
									</div>
								</div>

								<label for="asset_uploads" class="btn">
									<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
										<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
									</svg>

									Upload
								</label>
	<!--input field-->
								<input id="asset_uploads" type="file" style="display:none" (change)="uploadFiles($event)"  required/>
	<!--upload status 1-->

	<!--uploaded status 2-->
								<div class="upload_status" *ngIf="Files?.length > 0" ><!--&& !S3S.uploadStatus=='Success!'">-->
									<div *ngFor="let file of Files">
										<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>
									</div>
	<!-- upload errors-->
									<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
										We had trouble uploading the file. Please refresh the page and try again.
										<p>If the problem persists please supply us with the following details</p>

									</div>
									<div>{{Error}}</div>
									<p class="form__error" *ngIf="up_inprogress && file.progressPercentage<100">Please wait until upload is complete.</p>



							  </div>
							</div><!-- /.file-box -->
						</div><!-- /.form__row -->
						<div class="form__divider"></div><!-- /.form__divier -->
						
						<p>Originally Submitted: {{asset.CAR.request_submitted}}</p>
						<p>Status: {{asset.CAR.request_status}}</p>
						<p>Submitted Files:</p>
							<ul>
								<li *ngFor="let link of asset.CAR.supporting_files">{{link.last_updated}}: {{link.fileurl}}</li>
						</ul>
						<p>Most Recent Comments</p>
							<ul><li *ngFor="let quote of asset.CAR.comments">{{quote.last_updated}}: {{quote.comment}}</li></ul>

					</ng-container>




					
				</div><!-- /.form__body -->
				<div><p class="form__error form__error__alt" *ngIf="up_inprogress && Files[0].progressPercentage<100">Please wait until upload is complete</p></div>
				<div><p class="form__error form__error__alt" *ngIf="submitted && (affidForm.invalid || S3S.uploads.length==0)">Please complete the fields above</p></div>

				<div class="form__actions">
					<a (click)="submitCAR(affidForm)" class="btn form__btn"  name="submit_affidavit">Submit</a>
				</div><!-- /.form__actions -->
				
			</form>
		</div><!-- /.form -->

		<a (click)="closeResults();" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="">
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->
