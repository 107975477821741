<div class="modal" [ngClass]="{ 'is-open': CS.showAlert }">
	<div class="modal__inner modal--size-1">
		<div class="modal__entry">
			<figure class="modal__image">
				<img src="assets/images/svg/warning.svg" alt="">
			</figure><!-- /.modal__image -->

			<h2>Alert!</h2>
			<h3 [innerHtml]="CS.alert_message"></h3>

		</div><!-- /.modal__entry -->

		<div class="modal__actions">
			<a (click)="CS.showAlert=false;" class="btn btn--size-3">Close Window</a>

		</div><!-- /.modal__actions -->

		<a (click)="CS.showAlert=false;" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="">
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->

