
     <!-- <section class="section-headline section-headline--tertiary">
            <div class="shell shell--size-3">
                <h2>Users Overview</h2>
            </div><!-- /.shell shell-/-size-3 -->
      <!--  </section><!-- /.section-headline -->
<!--<app-edit-profile [Profile]="this.user_edit"></app-edit-profile>-->


        <section class="section-filters section-filters--bottom-offset">
            <div class="shell">
                <h3>Filters:</h3>

                <div class="cols cols--alt cols--bottom-offset">
                    <div class="col col--1of5">
                        <div class="select" [ngClass]="{'is-open':select_open}"  (click)="toggleSelectOpen();">
                            <div class="select__head">
                                <p>{{current_status ? 'Status: '+ current_status: 'By Status'}}</p>
                            </div><!-- /.select__head -->

                            <div class="select__list" (mouseleave)="toggleSelectOpen();">
                                <ul>
                                   
									<li (click)="selectOption('pending');">Pending</li>

                                    <li (click)="selectOption('accepted');">Accepted</li>

                                    <li (click)="selectOption('inactive');">Inactive</li>
                                </ul>
                            </div><!-- /.select__list -->
                        </div><!-- /.select -->
                    </div><!-- /.col-/-1of5 -->
                </div><!-- /.cols -->
            </div><!-- /.shell -->
        </section><!-- /.section-filters -->
        

        <section class="section-created-assets">
            <div class="shell shell--size-4">
                <div class="section__inner">
                    <div class="section__head">
                        <h4>Manage Users</h4>

                        <a routerLink="/register" class="btn section__btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <rect data-name="icons/add background" width="20" height="20" fill="none"></rect>
                              <rect data-name="Box 24pt" width="20" height="20" fill="none"></rect>
                              <path data-name="Icon Style" d="M7.792,7.5h6.375V9.167H7.792v7.5H6.375v-7.5H0V7.5H6.375V0H7.792Z" transform="translate(1.417 1.667)" fill="currentColor"></path>
                            </svg>

                            <span>
                                Add New User
                            </span>
                        </a>
                    </div><!-- /.section__head -->

                    <div class="section__body">
                        <div class="table-assets">
                            <table>
                                <colgroup>
                                    <col class="table__col--size-1">

                                    <col class="table__col--size-2 table__col--center">
									
									<col class="table__col--size-2">
									<col class="table__col--size-2">
									<col class="table__col--size-2">
									<col class="table__col--size-2">
									<col class="table__col--size-2">
									<col class="table__col--size-2">
									<col class="table__col--size-2">

                                 <!--   <col class="table__col--size-3">

                                    <col class="table__col--size-4">

                                    <col class="table__col--size-5">

                                    <col class="table__col--size-4">

                                    <col class="table__col--size-6">

                                    <col class="table__col--size-8">

                                    <col class="table__col--size-9">-->
                                </colgroup>

                                <thead>
                                    <tr>
                                        <th></th>

                                        <th (click)="sortBy('status')" >
                                            Status

                                            <a (click)="sortBy('status')" ><img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>
										
                                        <th >
                                            

                                            <a (click)="sortBy('created_date')" >Registered Date<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th >
                                            

                                            <a (click)="sortBy('confirmed_date')" >Approved Date<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>
										<th *ngIf="current_status=='pending'" >
                                            Deny
                                        </th>

                                        <th  >
                                            
                                           
											<a (click)="sortBy('first_name')" > First Name<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th >
                                            

                                            <a (click)="sortBy('last_name')" >Last Name<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th>
                                            

                                            <a (click)="sortBy('partner_full')" >Partner<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th >
                                           

                                            <a (click)="sortBy('account_type')" > Acct. Type<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                        <th >
                                            

                                            <a (click)="sortBy('email')" >Email<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>
										<ng-container  *ngIf="current_status=='accepted'">
										

											<th >
												

												<a (click)="sortBy('visit')" >Visits<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
											</th>

											<th >
												

												<a (click)="sortBy('downloads')" >Downloaded<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
											</th>
											<th>
												
												<a (click)="sortBy('pending')" >Download Pending<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
											</th>
											<th >								

												<a (click)="sortBy('inqueue')" >In-Queue<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
											</th>
											<th >
												

												<a (click)="sortBy('removed')" >Abandons<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
											</th>
											
										</ng-container>
						

                                        <!--<th>
                                            Last Accessed

                                            <img src="assets/images/svg/sort.svg" alt="sortbutton">
                                        </th>-->
										<th >
                                           

                                            <a (click)="sortBy('goldrewards')" > Gold Rewards Status<img src="assets/images/svg/sort.svg" alt="sortbutton"></a>
                                        </th>

                                    </tr>
                                </thead>
                                
                                <tbody>
									<ng-container *ngFor="let user of processedUsers() | statusFilter: 'status': current_status | sortBy: reverse : propertyName">
										<tr >
											<td>
												<a (click)="editUser(user);" class="btn btn--edit">
													<svg id="Admin_no_text" data-name="Admin no text" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="currentColor">
														<g id="Edit" transform="translate(9 9)">
															<path id="Shape" d="M.379,16H.374A.37.37,0,0,1,.11,15.89.375.375,0,0,1,0,15.62L.042,12a.375.375,0,0,1,.109-.261L11.782.11a.374.374,0,0,1,.53,0l3.577,3.578A.384.384,0,0,1,16,3.955a.372.372,0,0,1-.11.265L4.261,15.847A.372.372,0,0,1,4,15.956ZM9.66,3.292h0L.79,12.161.754,15.244l3.083-.035,8.87-8.869L9.66,3.292ZM12.046.906h0L10.188,2.762,13.236,5.81l1.858-1.856L12.046.906Z" transform="translate(1 0)"></path>
														</g>
													</svg>
												</a>
											</td>

											<td class="table__cell--center">{{user.status}}</td>
											<td>{{user.created_date | date: 'shortDate'}}</td>
											
											<td >
												<ng-container *ngIf="user.status!=='pending'">
													{{user.confirmed_date | date: 'shortDate'}}
												</ng-container>
												<ng-container *ngIf="user.status=='pending'">
													<a (click)="approveUser(user.profile_id);" class="btn btn--edit">Approve</a>
												
												</ng-container>
											</td>
											<td *ngIf="current_status=='pending'">
													<a (click)="denyUser(user.profile_id);" class="btn btn--edit">Deny</a>
												
											</td>
											<td >{{user.first_name}}</td>
											<td >{{user.last_name}}</td>
											<td >{{user.partner_full}}</td>
											<td >{{user.account_type}}</td>
											<td >{{user.email}}</td>
											<ng-container  *ngIf="current_status=='accepted'">

												<td *ngIf="user.status=='accepted'">{{user.visit}}</td>
												<td *ngIf="user.downloads>0">
													<a href="https://api.hallmarkmediahub.com/lib/reports/userdownloads.php?id={{user.profile_id}}&status=DOWNLOADED" target="_blank">{{user.downloads}}</a>
												</td>
												<td *ngIf="user.downloads==0">{{user.downloads}}</td>
												<td *ngIf="user.pending>0">
													<a href="https://api.hallmarkmediahub.com/lib/reports/userdownloads.php?id={{user.profile_id}}&status=PENDING" target="_blank">{{user.pending}}</a>
												</td>
												<td *ngIf="user.pending==0">{{user.pending}}</td>
												<td *ngIf="user.inqueue>0">
													<a href="https://api.hallmarkmediahub.com/lib/reports/userdownloads.php?id={{user.profile_id}}&status=IN-QUEUE" target="_blank">{{user.inqueue}}</a>
												</td>
												<td *ngIf="user.inqueue==0">{{user.inqueue}}</td>
												<td *ngIf="user.removed>0">
													<a href="https://api.hallmarkmediahub.com/lib/reports/userdownloads.php?id={{user.profile_id}}&status=REMOVED" target="_blank">{{user.removed}}</a>
												</td>
												<td *ngIf="user.removed==0">{{user.removed}}</td>
												<!--<td>{{user.last_accessed | date: 'shortDate'}}</td>-->
											</ng-container>
											<td>{{user.goldrewards}}</td>

										</tr>
									</ng-container>
                                </tbody>
                            </table>
                        </div><!-- /.table-assets -->          
                    </div><!-- /.section__body -->
                </div><!-- /.section__inner -->
            </div><!-- /.shell shell--size-3 -->
        </section><!-- /.section-created-assets -->
<app-alert></app-alert>
