<div class="box__body">
      <div class="table table--manage-account-list">
        <table>
          <thead>
            <tr>
				<th>Select/Deselect All  <input type="checkbox"  [(ngModel)]="allSelected"  name="selectall" id="selectall" (click)='selectAll();' ></th>
				<th><a (click)="sortByAccount('status')">STATUS</a></th>
				<th><a (click)="sortByAccount('email')">Email</a></th>
              	<th><a (click)="sortByAccount('first_name')">First Name</a></th>
				<th><a (click)="sortByAccount('last_name')">Last Name</a></th>
          		<th><a (click)="sortByAccount('partner_full')">Partner</a></th>
          
            </tr>
          </thead>

          <tbody>
			  
			  
            <tr *ngFor="let accepted_account of Recipients | filterRecips: 'profile_id': AS.assetUsers: AS.selectedAssetOption | sortBy: reverse : propertyName" 
				[ngClass]="{'selected': accepted_account.receive_email==true}"><!-- multiFilter: AS.accountFilter |-->
				<!--| filter : {status: 'accepted'} : true | filter : category_promo_filter | orderBy:propertyName:reverse-->
             
            <td>
             <input type="checkbox"  [(ngModel)]="accepted_account.receive_email" name="account{{accepted_account.account_id}}" id="account{{accepted_account.account_id}}" (click)="clickActive();" >
            </td>
			<td>
              <h6>{{accepted_account.status}}</h6>
			<h6>{{accepted_account.receieve_email}}</h6>
            </td>


            <td>
              <h6>{{accepted_account.email}}</h6>
            </td>

            <td>
              <h6>{{accepted_account.first_name}}</h6>
            </td>

            <td>
              <h6>{{accepted_account.last_name}}</h6>
            </td>

            <td>
              <h6>{{accepted_account.partner_full}}</h6>
            </td>

           
            </tr>
          </tbody>
        </table>
      </div><!-- /.table -->
    </div><!-- /.box__body -->
  

