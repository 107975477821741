<div class="modal modal--alt is-open"><!-- [ngClass]="{ 'is-open': AS.viewResults }"-->
	<div class="modal__inner">
		<div class="form form--modal form--secondary form--scroll">
			<form name="assetForm" #assetForm="ngForm" novalidate [ngClass]="{'submitted':submitted}">
		
				<header class="form__head">
					<h2 *ngIf="asset?.length>0">Edit Asset</h2>
					<h2 *ngIf="asset?.length==0">New Asset</h2>
				</header><!-- /.form__head -->

				<div class="form__body">
					<p>Please review/provide the asset information below:</p>
					<div class="form__row" >
						<label for="program" class="form__label">Program Name:</label>

						<div class="form__controls">
							<input type="text" class="field " name="program" id="program" [(ngModel)]="asset.program"  required>
						</div><!-- /.form__controls -->
					    <p class="form__error" *ngIf="submitted && !asset.program">Please provide the asset name.</p>
					</div><!-- /.form__row -->

					<div class="form__row" >
						<label class="form__label">Asset Type:</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1" *ngIf="!other_type"
			 [ngClass]="{'is-open': select_open_type, 'select_error': submitted && !asset.asset_type}"
								(click)="toggleSelectOpen(asset,'type');" >
								<div class="select__head">
									<p>{{ asset.asset_type ?  'Asset Type: '+asset.asset_type  : "Select Type" }}</p>
								</div><!-- /.select__head --><!-- [ngClass]="{'is-open': asset?.select_open_tactics}" -->

								<div class="select__list" (mouseleave)="toggleSelectOpen(asset,'type');">
									<ul>
										<li *ngFor="let typ of Types" (click)="select_option('asset_type', typ.asset_type);">{{typ.asset_type}}</li>
										<li (click)="select_option('type', 'other');">Other</li>
									</ul>
								</div><!-- /.select__list -->


							</div><!-- /.select -->
							<input *ngIf="other_type" type="text" class="field" name="other_type_fld" id="other_type_fld" (focusout)="other_type=false" [(ngModel)]="asset.asset_type" required>
							
							
							<p class="form__error" *ngIf="submitted && (!asset.asset_type || asset.asset_type=='other' )">Please provide the asset type.</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					<div class="form__row">
						<label for="asset_name" class="form__label">Asset Name:</label>

						<div class="form__controls">
							<input type="text" disabled class="field " name="asset_name" id="asset_name" value="{{asset.program}} - {{asset.asset_type}}" >
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					
										
					<div class="form__row">
						<label class="form__label">Select Network(s):</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1 select--checks"
								 [ngClass]="{'is-open': select_open_networks, 'select_error': submitted && !asset.networks}"
								(click)="toggleSelectOpen(asset,'networks');" id="networks">
								<div class="select__head">
									<p>Network(s):</p>
								</div><!-- /.select__head --><!-- [ngClass]="{'is-open': asset?.select_open_tactics}" -->

								<div class="select__list" (mouseleave)="toggleSelectOpen(asset,'networks');">
									<ul>
										<li *ngFor="let ntwk of Networks">
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addElement(asset,ntwk,'networks');" [checked]="includedElement(asset,ntwk,'networks')" id="net{{ntwk.network_id}}">

												<label for="net{{ntwk.network_id}}">{{ntwk.network_full}}</label>
											</div><!-- /.checkbox -->
										</li>
									</ul>
								</div><!-- /.select__list -->
							</div><!-- /.select -->
							<div class="cols cols--alt form__cols--size-1">
								<div class="col col--1of3" *ngFor="let net of asset.networks">
									<div class="checkbox checkbox--gray" >
										<input type="checkbox"  (click)="addElement(asset,net,'networks');" [checked]="includedElement(asset,net,'networks')"  id="nt{{net.network_id}}">
										<label for="nt{{net.network_id}}">{{net.network_full}}</label>
									</div><!-- /.checkbox -->
								</div><!-- /.col col-/-1of3 -->

							</div><!-- /.cols -->
							<p class="form__error" *ngIf="submitted && asset.networks?.length==0">Please select Network(s).</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->					
					
					<div class="form__row">
						<label class="form__label">{{other_campaign? 'Add New Campaign:' : 'Select Campaign(s):'}}</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1 select--checks" *ngIf="!other_campaign"
								 [ngClass]="{'is-open': select_open_campaigns, 'select_error': submitted && !asset.campaign}"
								(click)="toggleSelectOpen(asset,'campaigns');" id="campaign">
								<div class="select__head">
									<p>Campaign(s):</p>
								</div><!-- /.select__head --><!-- [ngClass]="{'is-open': asset?.select_open_tactics}" -->

								<div class="select__list" (mouseleave)="toggleSelectOpen(asset,'campaigns');">
									<ul>
										<li  (click)="select_option('campaign', 'other');">New Campaign</li>
										<li *ngFor="let camp of Campaigns">
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addElement(asset,camp,'campaign');" [checked]="includedElement(asset, camp,'campaign')" id="camp{{camp.campaign_id}}">

												<label for="camp{{camp.campaign_id}}">{{camp.campaign_name}}</label>
											</div><!-- /.checkbox -->
										</li>
										
									</ul>
								</div><!-- /.select__list -->
								
							</div><!-- /.select -->
							<input *ngIf="other_campaign" (change)="addElement(asset,0,'campaign');" [(ngModel)]="asset.other_campaign_fld" type="text" class="field " name="other_campaign_fld" id="other_campaign_fld">
							<div class="cols cols--alt form__cols--size-1">
								<div class="col col--1of3" *ngFor="let cmp of asset.campaign">
									<div class="checkbox checkbox--gray" >
										<input type="checkbox"  (click)="addElement(asset,cmp,'campaign');" [checked]="includedElement(asset, cmp,'campaign')"  id="cmp{{cmp.campaign_id}}">
										<label for="cmp{{cmp.campaign_id}}">{{cmp.campaign_name}}</label>
									</div><!-- /.checkbox -->
								</div><!-- /.col col-/-1of3 -->

							</div><!-- /.cols -->
							<p class="form__error" *ngIf="submitted && asset.campaign?.length==0">Please select Campaign(s).</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->					

					<div class="form__row">
						<label for="textarea" class="form__label">Description/Copy:</label>

						<div class="form__controls">
							<textarea name="description" id="description" class="field field--textarea" [(ngModel)]="asset.description"  required></textarea>
						</div><!-- /.form__controls -->

					</div><!-- /.form__row -->
										
					<div class="form__row">
						<label for="flight_copy" class="form__label">Flight Copy:</label>

						<div class="form__controls">
							<input type="text" class="field " name="flight_copy" id="flight_copy" [(ngModel)]="asset.flight_copy"  required>
						</div><!-- /.form__controls -->
					    <p class="form__error" *ngIf="submitted && asset.flight_copy==''">Please provide the flight copy.</p>
					</div><!-- /.form__row -->


					<div class="form__row">
						<label class="form__label">Asset Avail Starts:</label>

						<div class="form__controls">
							<input matInput [matDatepicker]="picker1" [(ngModel)]="asset.asset_start" required type="text" class="field field--size-1" name="asset_start" id="asset_start">
							 <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
							<mat-datepicker #picker1></mat-datepicker>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					
					
					<div class="form__row">
						<label class="form__label">Asset Expires</label>

						<div class="form__controls">
							<input required type="text" class="field field--size-1" name="asset_end" id="asset_end"
								   matInput [matDatepicker]="picker2" [(ngModel)]="asset.asset_end" >
							 <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
							<mat-datepicker #picker2></mat-datepicker>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<div class="file-box file-box-assets">
							<p>Asset File: 
								<a *ngIf="asset.path && !Files_assets" href="{{asset.path}}" target="_blank">Current Asset</a>
								<span *ngFor="let upload of S3S.uploads_assets" ><br>New Asset: 
									<a href="upload.Location" target="_blank">{{upload.filename}}  - {{upload.fileprogress}}% - Uploaded</a>
								</span>
							
							</p>
							<p class="form__error" *ngIf="submitted && !Files_assets && !asset.path">Please upload the asset file.</p>
							
							<label for="asset_upload" class="btn">
								<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
									<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
								</svg>

								{{asset.path? 'Replace' : 'Upload'}}
							</label>
							<input id="asset_upload" #asset_upload type="file" style="display:none" (change)="uploadFiles($event,'assets')"  required/>
							<div class="upload_status" *ngIf="Files_assets?.length > 0 && S3S.uploads_assets?.length==0"><!--&& !S3S.uploadStatus=='Success!'">-->
								<div *ngFor="let file of Files_assets">
									<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>
									
								<p class="form__error" *ngIf="up_inprogress && file.progressPercentage<100">Please wait until upload is complete.</p>
								</div>
<!-- upload errors-->
								<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
									We had trouble uploading the file. Please refresh the page and try again.
									<p>If the problem persists please supply us with the following details</p>

								</div>
								<div>{{Error}}</div>
						  </div>
						</div><!-- /.file-box -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<label for="textarea" class="form__label">Contains Files:</label>

						<div class="form__controls">
							<textarea name="contains" id="contains" class="field field--textarea" [(ngModel)]="asset.contains"  required></textarea>
						</div><!-- /.form__controls -->

					</div><!-- /.form__row -->

					<div class="form__row">
						<div class="file-box file-box-assets">
							<p>Thumbnail:<br>
								{{ asset.thumb && !Files_thumbs? 'Current':'New'}}
								 Thumb:
								<img *ngIf="asset.thumb && !Files_thumbs" src="{{asset.thumb}}" alt="">
								<span *ngFor="let uploadt of S3S.uploads_thumbs" > 
									<img src="{{uploadt.url}}" alt="">
								</span>
							</p>
							<p class="form__error" *ngIf="submitted && !Files_thumbs && !asset.thumb">Please upload the asset thumbnail/preview (jpg or png)</p>
							
							<label for="thumb_upload" class="btn">
								<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
									<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
								</svg>{{asset.thumb? 'Replace' : 'Upload'}}
							</label>
							<a (click)="useDefault()" class="btn">Use Default</a>
							<input id="thumb_upload" type="file" style="display:none" (change)="uploadFiles($event, 'thumbs')"  required/>
							<div class="upload_status" *ngIf="Files_thumbs?.length > 0" ><!--&& !S3S.uploadStatus=='Success!'">-->
								<div *ngFor="let file of Files_thumbs">
									<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>
									<p class="form__error" *ngIf="up_inprogress && file.progressPercentage<100">Please wait until upload is complete.</p>
								</div>
<!-- upload errors-->
								<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
									We had trouble uploading the file. Please refresh the page and try again.
									<p>If the problem persists please supply us with the following details</p>

								</div>
								<div>{{Error}}</div>
								
						  </div>
						</div><!-- /.file-box -->
					</div><!-- /.form__row -->

					<div class="form__row" *ngIf="asset.asset_type && asset.asset_type.includes('romo')">
						<div class="file-box file-box-assets">
							<p>Video Preview: 
								<span *ngIf="asset.video_preview && !Files_preview"  > 
									<a href="{{asset.video_preview}}" target="_blank">Current Preview</a>
									<video  width="100%" height="auto" controls="" >
										<source src="{{asset.video_preview}}" type="video/mp4"> 
									</video>
								</span>
								<span *ngFor="let uploadp of S3S.uploads_preview" ><br>New Preview: 
									<video  width="100%" height="auto" controls="" >
									<!--	<source src="https://ik.imagekit.io/hallmedia/{{upload.filename}}" type="video/mp4"> -->
									<source src="{{uploadp.url}}" type="video/mp4"> 
									</video>
									<a href="{{uploadp.url}}" target="_blank">{{uploadp.filename}}</a>
									<!--<a href="https://ik.imagekit.io/hallmedia/{{upload.filename}}" target="_blank">{{upload.filename}}</a>-->
									
								</span>
							</p>
							<p class="form__error" *ngIf="submitted && !Files_preview && !asset.video_preview">Please upload the preview video file.(mp4 or mov)</p>

						
							<label for="video_upload" class="btn">
								<svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="13.75" viewBox="0 0 11.333 13.75">
									<path data-name="Icon Style" d="M0,13.75V12.083H11.333V13.75Zm4.958-3.333V3.194L2.626,5.938l-1-1.179L5.67,0,9.715,4.76l-1,1.179L6.375,3.187v7.23Z" fill="#582c83"></path>
								</svg>{{asset.preview? 'Replace' : 'Upload'}}
							</label>
							<input id="video_upload" #video_upload type="file" style="display:none" (change)="uploadFiles($event, 'preview')"  required/>
							<div class="upload_status" *ngIf="Files_preview?.length > 0" ><!--&& !S3S.uploadStatus=='Success!'">-->
								<div *ngFor="let file of Files_preview">
									<div><i>Status: {{file.name}}  - {{file.progressPercentage}}% - {{S3S.uploadStatus}}</i></div>
									
								<p class="form__error" *ngIf="up_inprogress && files.progressPercentage<100">Please wait until upload is complete.</p>
								</div>
<!-- upload errors-->
								<div class="alert alert-danger" role="alert" *ngIf="Error?.length > 0">
									We had trouble uploading the file. Please refresh the page and try again.
									<p>If the problem persists please supply us with the following details</p>

								</div>
								<div>{{Error}}</div>
						  </div>
							
						</div><!-- /.file-box -->
					</div><!-- /.form__row -->

					<div class="form__row">
						<label class="form__label">{{other_tags? 'Add New Tag:' : 'Select Tag(s):'}}</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1 select--checks" *ngIf="!other_tags"
								 [ngClass]="{'is-open': select_open_tags, 'select_error': submitted && !asset.tags}"
								(click)="toggleSelectOpen(asset,'tags');" id="tags">
								<div class="select__head">
									<p>Tags:</p>
								</div><!-- /.select__head --><!-- [ngClass]="{'is-open': asset?.select_open_tactics}" -->

								<div class="select__list" (mouseleave)="toggleSelectOpen(asset,'tags');">
									<ul>
										<li (click)="select_option('tags', 'other');">New Tag</li>
										<li *ngFor="let tg of Tags">
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addElement(asset,tg,'tags');" [checked]="includedElement(asset,tg,'tags')" id="tg{{tg.tag}}">

												<label for="tg{{tg.tag}}">{{tg.tag}}</label>
											</div><!-- /.checkbox -->
										</li>
										
									</ul>
								</div><!-- /.select__list -->
								
							</div><!-- /.select -->
							<input *ngIf="other_tags" (change)="addElement(asset,0,'tags');" [(ngModel)]="asset.other_tags_fld" type="text" class="field" name="other_tags_fld" id="other_tags_fld">
							<div class="cols cols--alt form__cols--size-1">
								<div class="col col--1of3" *ngFor="let tg of asset.tags">
									<div class="checkbox checkbox--gray" >
										<input type="checkbox"  (click)="addElement(asset,tg,'tags');" [checked]="includedElement(asset,tg,'tags')"  id="tag{{tg.tag}}">
										<label for="tag{{tg.tag}}">{{tg.tag}}</label>
									</div><!-- /.checkbox -->
								</div><!-- /.col col-/-1of3 -->

							</div><!-- /.cols -->
							<p class="form__error" *ngIf="submitted && asset.tags?.length==0">Please select Tag(s).</p>
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->
					
					<div class="form__row">
						<label class="form__label">Specific Partner(s) Only:</label>

						<div class="form__controls">
							<div class="select select--gray select--size-1 select--checks"
								 [ngClass]="{'is-open': select_open_partners}"
								(click)="toggleSelectOpen(asset,'partners');" id="partners">
								<div class="select__head">
									<p>Partner(s):</p>
								</div><!-- /.select__head --><!-- [ngClass]="{'is-open': asset?.select_open_tactics}" -->

								<div class="select__list" (mouseleave)="toggleSelectOpen(asset,'partners');">
									<ul>
										<li *ngFor="let partner of Partners">
											<div class="checkbox checkbox--gray">
												<input type="checkbox" (click)="addElement(asset,partner,'partners');" [checked]="includedElement(asset,partner,'partners')" id="part{{partner.partner_id}}">

												<label for="part{{partner.partner_id}}">{{partner.partner_full}}</label>
											</div><!-- /.checkbox -->
										</li>
									</ul>
								</div><!-- /.select__list -->
							</div><!-- /.select -->
							<div class="cols cols--alt form__cols--size-1">
								<div class="col col--1of3" *ngFor="let prt of asset.partners">
									<div class="checkbox checkbox--gray" >
										<input type="checkbox"  (click)="addElement(asset,prt,'partners');" [checked]="includedElement(asset,prt,'partners')"  id="prt{{prt.partner_id}}">
										<label for="nt{{prt.partner_id}}">{{prt.partner_full}}</label>
									</div><!-- /.checkbox -->
								</div><!-- /.col col-/-1of3 -->

							</div><!-- /.cols -->
						</div><!-- /.form__controls -->
					</div><!-- /.form__row -->					


					
					
<!-- checkbox avail-->
					<div class="form__terms terms__3">
						<div class="checkbox">
							<input type="checkbox" id="checkbox_publish" [checked]="asset.active" [(ngModel)]="asset.active">

							<label for="checkbox_publish">Active</label>
						</div><!-- /.checkbox -->
					</div><!-- /.form__terms -->
					<div class="form__divider"></div><!-- /.form__divier -->
				</div><!-- /.form__body -->
				<div><p class="form__error form__error__alt" *ngIf="submitted && checkInProgress">Please wait until upload is complete
					
					</p></div>
				<div><p class="form__error form__error__alt" *ngIf="submitted && (assetForm.invalid || file_upload_error)">Please complete the fields above</p></div>

				<div class="form__actions">
					<a (click)="submitAsset(assetForm)" class="btn form__btn"  name="submit_asset">Submit</a>
				</div><!-- /.form__actions -->
				
			</form>
		</div><!-- /.form -->

		<a (click)="closeResults();" class="modal__btn">
			<img src="assets/images/svg/exit-button.svg" alt="">
		</a>
	</div><!-- /.modal__inner -->
</div><!-- /.modal -->
